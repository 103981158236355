/**
 * @author ming.leng
 */
import React from "react";
import { Modal, Button, Form, Input } from "antd";
import { useAuth } from "../../../../context/auth";
import { ICreateSitePayload } from "../../../../types/site.model";
import { PAGE_NAME } from "../../../../constants";

const tailFormItemLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export type PropsType = {
  visible: boolean;
  handleCancelModal: () => void;
  createSite: (token: string, payload: ICreateSitePayload) => Promise<any>;
};

function CreateSiteModal({
  visible,
  handleCancelModal,
  createSite,
}: PropsType) {
  const { accessToken } = useAuth();
  const [form] = Form.useForm();
  const onCancel = () => {
    form.resetFields();
    handleCancelModal();
  };

  const onFinish = (fieldsValue: any) => {
    const payload = {
      ...fieldsValue,
    };
    createSite(accessToken, payload);
    onCancel();
  };

  return (
    <Modal
      title={`Create ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1}`}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        name="create-site"
        onFinish={onFinish}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: `Please enter ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1} Name!` }]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Location"
          name="location"
          rules={[{ required: true, message: `Please enter ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1} Location!` }]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateSiteModal;
