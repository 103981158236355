/**
 * @author ming.leng
 */
import React, { useEffect } from "react";
import { Modal, Form, Button, Input } from "antd";
import { useAuth } from "../../../../context/auth";
import { IUnit, IUnitAdmin } from "../../../../types/unit.model";
import { PAGE_NAME } from "../../../../constants";
import { Select } from "antd";
const { Option } = Select;

interface PropsType {
  visible: boolean;
  handleCancelModal: () => void;
  updateUnit: Function;
  unit: IUnit;
  unitAmdin: Array<{ id: number; identity: string }>;
  currentAdminList: IUnitAdmin[];
}

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

function UpdateModal({
  visible,
  handleCancelModal,
  updateUnit,
  unit,
  unitAmdin,
  currentAdminList,
}: PropsType) {
  const { accessToken } = useAuth();
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible === true) {
      form.setFields([
        { name: "name", value: unit?.name },
        {
          name: "admin_ids",
          value: currentAdminList.map(
            (item: { id: number; identity: string }) => item?.id
          ),
        },
      ]);
    } else {
      form.resetFields();
    }
  }, [form, unit?.name, visible, currentAdminList]);

  const onFinish = (fieldValues: any) => {
    const payload = {
      ...fieldValues,
      type_id: 1,
    };
    updateUnit(accessToken, payload, unit?.id);
  };
  const onCancel = () => {
    handleCancelModal();
    form.resetFields();
  };

  return (
    <Modal
      title={`Update ${PAGE_NAME.UNIT_MANAGEMENT.LABEL_1}`}
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      getContainer={false}
      destroyOnClose
      footer={[
        <div style={{ textAlign: "center" }}>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
          >
            Submit
          </Button>
        </div>,
      ]}
    >
      <Form
        form={form}
        layout="horizontal"
        name="update_unit"
        {...formItemLayout}
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Name: "
          rules={[{ required: true, message: "Please enter Unit name!" }]}
        >
          <Input placeholder="Please enter Unit name" />
        </Form.Item>
        <Form.Item name="admin_ids" label="Unit Admin">
          <Select mode="multiple" placeholder="Please select Admin">
            {unitAmdin &&
              unitAmdin
                ?.concat(currentAdminList)
                ?.map((item: { id: number; identity: string }) => {
                  return (
                    <Option key={item?.id} value={item?.id}>
                      {item?.identity}
                    </Option>
                  );
                })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdateModal;
