import React from "react";
import { Layout } from "antd";

const { Footer } = Layout;

export default function FooterLayout() {
  return (
    <Footer style={{ textAlign: "center" }}>
      Made with{" "}
      <span role="img" aria-label="sheep">
        ❤️{" "}
      </span>
      by the SWiFT Office
    </Footer>
  );
}
