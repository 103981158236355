import React, { useContext, useState } from "react";
import { Modal, Button, Form, message } from "antd";
import moment from "moment";

// hook
import AppointmentsAPI from "../../../api/appointments";
import { useRequest } from "../../../swr";

import AssignedForm from "./assigned-form";
import { PMContext } from "../../../pages/PersonnelManagement/PersonnelManagementContext";

const tailFormItemLayout = {
  wrapperCol: { span: 24 },
};

type BulkModalProps = {
  token: string;
  visible: boolean;
  personnel: Array<string>;
  filter: any;
  vaccineBrandDefault: any;
  onClose: () => void;
  timeslot: any;
  personnelList: any[];
};
const AssignVaccineModal = ({
  token,
  visible,
  personnel,
  filter,
  // vaccineBrandDefault,
  onClose,
  timeslot,
  personnelList,
}: BulkModalProps) => {
  const [form] = Form.useForm();
  const context = useContext(PMContext);
  const [totalRow, setTotalRow] = useState<number[]>([])

  const { data: sitesData, error } = useRequest("GET", "/vaccination/sites");
  if (error?.message) {
    message.error(error?.message ?? "error");
  }

  const getSelectedTimeslot = () => {
    return timeslot?.[0]?.unit_timeslot?.filter(
      (item: any) => item?.id === filter?.unit_timeslot_id
    )?.[0];
  };

  const onCancel = () => {
    // setSelectedVaccine({});
    onClose();
    form.resetFields();
  };

  const bulkAppointment = async (payload: any) => {
    return await AppointmentsAPI.assignVaccine(token, payload)
      .then((req: any) => {
        if (req?.status === false) {
          if (req?.messageErrors && req?.messageErrors?.length > 0) {
            req?.messageErrors?.map((error: any) => {
              message.error(error ?? "Invalid data!", 8);
            });
          } else {
            message.error(req?.message ?? "Invalid data!", 5);
          }
        } else {
          message.success(req?.message ?? "Assign successfully!");
        }
        onClose();
        form.resetFields();
      })
      .catch((error) => {
        console.log("Error:", error);
        // message.success(error);
      });
  };

  const onFinish = () => {
    const listAssigned: { vaccine_id: number; order_no: number }[] = [];

    let personnel_ids = context
      .requestedPersonnel(personnelList, personnel)
      ?.filter((rp: any) => rp > 0);

    if (!personnel_ids.length) {
      return message.error(
        "No personnel selected or personnel don't have requested time-slot"
      );
    }

    totalRow?.forEach((t: number) => {
      if (
        form.getFieldValue(`vaccine-${t}`)?.[1] &&
        form.getFieldValue(`doses-${t}`)
      ) {
        listAssigned.push({
          vaccine_id: form.getFieldValue(`vaccine-${t}`)[1],
          order_no: form.getFieldValue(`doses-${t}`),
        });
      }
    });

    if (!listAssigned.length) {
      return message.error("No assigned vaccines");
    }

    const payload = {
      personnel_ids,
      site_id: filter?.site_id,
      vaccines: listAssigned,
    };
    bulkAppointment(payload);
  };

  return (
    <Modal
      width={1000}
      title="Assign Vaccine"
      visible={visible}
      footer={null}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
    >
      <Form
        // {...formItemLayout}
        form={form}
        layout="horizontal"
        name="bulk-update-appointment"
        onFinish={onFinish}
        initialValues={{
          site_id: filter?.site_id,
          date: moment(filter?.injection_date),
          time: `${getSelectedTimeslot()?.start_time}-${
            getSelectedTimeslot()?.end_time
          }`,
        }}
      >
        <Form.Item label="Total selected personnels" name="selected">
          <span>{personnel?.length}</span>
        </Form.Item>

        <AssignedForm
          form={form}
          getTotalRow={(total: number[]) => {
            setTotalRow(total);
          }}
        />

        <Form.Item style={{ textAlign: "center" }} {...tailFormItemLayout}>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignVaccineModal;
