/**
 * @author ming.leng
 */
import React from "react";
import { Card, Tabs, Row } from "antd";
import Title from "antd/lib/typography/Title";
import { useAuth } from "../../context/auth";

// component
import DashboardContainer from "../../layout/Dashboard";
import BulkRegistration from "./Tabs/BulkRegistration";
import SingleRegistration from "./Tabs/SingleRegistration";

const { TabPane } = Tabs;

function PersonnelRegistration() {
  const { roles: { data: { name: role_name }} } = useAuth();

  return (
    <DashboardContainer>
      <Card title={<Title level={3}>Personnel Registration</Title>}>
        {
          ["vc-admin"].includes(role_name) ?
            <Row justify="center" align="middle" style={{padding: "20px"}}>
              <SingleRegistration />
            </Row>
          :
            <Tabs defaultActiveKey="1">
              <TabPane
                tab={<div style={{padding: "0 20px"}}>Bulk</div>}
                key="1"
              >
                <BulkRegistration />
              </TabPane>
              <TabPane
                tab={<div style={{padding: "0 20px"}}>Single</div>}
                key="2"
              >
                <Row justify="center" align="middle" style={{padding: "20px"}}>
                  <SingleRegistration />
                </Row>
              </TabPane>
            </Tabs>
        }
      </Card>
    </DashboardContainer>
  );
}

export default PersonnelRegistration;
