/**
 * @author ming.leng
 */
import React, { useEffect, useState } from "react";
import { Card, Typography, Col, Row, message, Result } from "antd";
import DashboardContainer from "../../../layout/Dashboard";
import { useRequest } from "../../../swr";

// Component
import ScheduledTimeslotOverview from "../../../components/Tables/ScheduledTimeslotOverview";
import ChartOverall from "../../../components/Chart/ChartOverall";
import { IInjectionSum } from "../../../types/summary.model";
import VaccineInventory from "../../../components/Chart/VaccineInventory";
import { IUnit } from "../../../types/unit.model";
import { Space } from "antd";
import { DatePicker } from "antd";
import { Cascader } from "antd";
import { Select } from "antd";
import { getCookie, removeEmptyUrlParams } from "../../../utils/helper";
import useAuthRole from "../../../hook/useAuthRole";
import moment from "moment";
import { DATE_FORMAT } from "../../../constants/index";
import OverallInventory from "../../../components/OveralInventory";
import GridInput from "../../../components/GridInput";
import vaccine from "../../../api/vaccine";
import { useAuth } from "../../../context/auth";

const { Title } = Typography;

const DEFAULT_FILTER = {
  date: null,
  vaccine_id: null,
  site_id: null,
};

export default function IndexPage() {
  const { data: role } = useAuthRole(getCookie("idToken"));
  const [filter, setFilter] = useState<{ [key: string]: any }>(DEFAULT_FILTER);
  const [summariesData, setSummaryData] = useState<any>([])

  //function handleGetInjectionStatus(vaccine_id: string, site_id: string, date: string = "") {
    //vaccine.injectionStatus(accessToken, { vaccine_id, site_id, date })
      //.then(d => {
        //setSummaryData(d.data);
      //})
      //.catch(e =>{
        //message.error(e.message ?? "Get injection status failed!");
      //})
  //}

  const { data: sitesData, error: sitesError } = useRequest(
    "GET",
    "/vaccination/sites"
  );
  const sites: IUnit[] = sitesData?.data ?? [];
  if (sitesError) {
    message.error(sitesError?.message ?? "Can not get medical centre");
  }

  const { data: vaccineData, error: vaccineError } = useRequest(
    "GET",
    "/vaccination/vaccines/options"
  );
  const vaccineOption: any[] = vaccineData?.data?.map((item: any) => {
    return {
      label: item?.name,
      value: item?.name,
      children: item?.brands?.map((item: any) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      }),
    };
  });

  if (vaccineError) {
    message.error(vaccineError?.message ?? "Can not get vaccine");
  }

  //const handleDatePickerChange = (value: any) => {
   // setFilter({
      //...filter,
      //date: value ? moment(value)?.format(DATE_FORMAT) : null,
    //});
  //};

  //const handleVaccineChange=(value:any[]) => {
    //setFilter({...filter,  vaccine_id:value? value : null})
   //}

  //const handleMedicalCentreChange = (value: number) => {
    //setFilter({ ...filter, site_id: value ? value : null });
  //};

  //useEffect(() => {
    //setFilter({
      //...filter,
      //site_id: role?.data?.assignedSite ? role?.data?.assignedSite : null,
      //vaccine_id:[vaccineData?.data?.[0]?.name, vaccineData?.data?.[0]?.brands?.[0]?.id],
      //date: moment().format(DATE_FORMAT)
    //});
  //}, [role,vaccineData]);

  //useEffect(() => {
    //const {site_id, vaccine_id, date} = filter;
    //if (vaccine_id?.[1]) {
      //handleGetInjectionStatus(vaccine_id[1], site_id, date);
    //}
  //}, [filter])

  return (
    <DashboardContainer>
      <Title style={{ color: "#6b6b6b" }} level={2}>
        Dashboard
      </Title>
      <Row gutter={24}>
        <Col xs={24}>
          <OverallInventory />
        </Col>
        <Col xs={24} md={10} lg={8}>
          <VaccineInventory />
        </Col>
        <Col xs={24} md={14} lg={16}>
          <Card
            className="card-no-pd"
            title="Scheduled Time-slot Overview"
            style={{
              boxShadow: "1px 2px 6px rgba(0, 0, 0, 0.16)",
              height: "100%",
            }}
          >
            <ScheduledTimeslotOverview />
          </Card>
        </Col>
      </Row>
      <br />
      {
	  /*
      <GridInput
        title="Unit / Department Overview"
        children={[
          <DatePicker
            key="1"
			      onChange={handleDatePickerChange} defaultValue={filter?.date ? filter?.date : moment()} placeholder="Please select date" />,
          <Cascader style={{width:"100%"}} allowClear={false} options={vaccineOption} value={filter?.vaccine_id} onChange={handleVaccineChange} placeholder="Please select vaccine" />,
          <Select
            key="3"
            defaultValue={role?.data?.assignedSite || sitesData?.data?.[0]?.id}
            disabled={!!role?.data?.assignedSite}
            style={{ width: "100%" }}
            onChange={handleMedicalCentreChange}
            placeholder="Please select medical centre"
          >
            {sites.length &&
              sites.map((site) => {
                return (
                  <Select.Option key={site?.id} value={site?.id}>
                    {site?.name}
                  </Select.Option>
                );
              })}
          </Select>,
        ]}
      />

      <Row gutter={[15, 20]} style={{ marginTop: 5 }}>
        {summariesData?.length !== 0 ? (
          summariesData?.map(
            ({ unit_name, summary, overall, schedule }: IInjectionSum) => {
              return (
                <Col xs={24} md={8}>
                  <ChartOverall
                    title={unit_name}
                    overall={overall}
                    schedule={schedule}
                    data={summary}
                    vaccineSelected={filter?.vaccine_id}
                    vaccineList={vaccineData?.data}
                  />
                </Col>
              );
            }
          )
        ) : (<Col xs={24} sm={24} lg={24}>
          <Result
          status="warning"
          subTitle="No data"
        />
      </Col>
      )}
      </Row>
	*/
	}
    </DashboardContainer>
  );
}
