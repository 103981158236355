import React, { useState, useEffect } from "react";
import { Typography, Button, Input, Modal, Table, Card, message, Space } from "antd";
import { ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useRequest } from "../../swr";
import DashboardContainer from "../../layout/Dashboard";
import CreateUserAdminModal from "./CreateUserAdminModal";
import AdminsAPI from "../../api/admins";
import { useAuth } from "../../context/auth";

const { Search } = Input;
const { Title } = Typography;
const { confirm } = Modal;

enum ModalTag {
  CREATE,
  UPDATE,
  DELETE,
}

export interface IRole {
  role_name: string;
  role_id: number;
}

export interface IMedicalCentre {
  role_id?: number;
  role_name?: string;
  location?: string;
  [key: string]: any;
}

function UserManagement() {
  const [visible, setVisible] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<Array<any>>([]);
  const [keySearch, setKeySearch] = useState<string>("");
  // @ts-ignore
  const [selectedItem, setSelectedItem] = useState<UserAdmin | null>(null);
  const [modalTag, setModalTag] = useState<ModalTag | null>(null);

  const { accessToken } = useAuth();

  // Query
  const { data, error, mutate } = useRequest("GET", "/admins");
  const rowData = data?.data ?? [];
  if (error?.message) {
    message.error(error?.message ?? "error");
  }

  const { data: roles, error: getRolesErr } = useRequest("GET", "/roles");
  const { data: medicalCentre, error: getMedicalCentreErr } = useRequest(
    "GET",
    "/vaccination/sites"
  );

  const rolesList: IRole[] = roles?.data ?? [];

  if (getRolesErr?.message) {
    message.error(getRolesErr?.message ?? "error");
  }

  if (getMedicalCentreErr?.message) {
    message.error(getMedicalCentreErr?.message ?? "error");
  }

  useEffect(() => {
    handleSearch(keySearch);
    rowData.map((e: any, index: number) => {
      return (rowData[index] = Object.assign({}, e, {
        name: e.name === null ? "" : e.name,
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  const showDeleteConfirm = (id: any) => {
    confirm({
      title: "Are you sure delete this user?",
      icon: <ExclamationCircleOutlined />,
      // content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handelDeleteUser(id);
      },
    });
  };

  const handelDeleteUser = (id: any) => {
    AdminsAPI.delete(id, accessToken).then(res => {
      if (res?.status) {
        message.success("Delete user successfully");
      } else {
        message.error(res?.message);
      }
      mutate(AdminsAPI.list(accessToken));
    });
  };

  const formatRoleName = (value: string): string => {
    const words = value.split("-");
    return words
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
  };

  const columnDefs = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => <>{index + 1}</>,
    },
    {
      title: "Email Address",
      key: "identity",
      dataIndex: "identity",
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Role",
      key: "role_id",
      dataIndex: "role_name",
      render: (value: string) => formatRoleName(value),
    },
    {
      title: "Medical Centre",
      key: "site_name",
      dataIndex: "site_name",
	  render: (value: string, record: any) => (record.site_id === null ? "All Medical Centre" : value),
    },
    {
      title: "Action",
      width: 80,
      render: (index: number, record: any) => {
        if (record.role_id === 1) {
          return <></>;
        }
        return (
          <div key={index}>
            <Space size={5}>
              <Button
                type="primary"
                size={"middle"}
                onClick={() => {
                  onCreate();
                  setSelectedItem(record ?? null);
                  setModalTag(ModalTag.UPDATE);
                }}
				icon={<EditOutlined />}
              >
              </Button>
              <Button
                size={"middle"}
                danger
                onClick={() => {
                  setModalTag(ModalTag.DELETE);
                  showDeleteConfirm(record?.id ?? null);
                }}
				icon={<DeleteOutlined />}
              >
              </Button>
            </Space>
          </div>
        );
      },
    },
  ];

  const onCreate = () => {
    setSelectedItem(null);
    setModalTag(ModalTag.CREATE);
    setVisible(true);
  };

  // @ts-ignore
  const onModalCancel = () => {
    setSelectedItem(null);
    setModalTag(null);
    setVisible(false);
  };

  const onCreateAdmin = async (payload: any) => {
    const rep = await AdminsAPI.create(payload, accessToken);
    if (rep?.status === true) {
      mutate(AdminsAPI.list(accessToken));
      setSelectedItem(null);
      setVisible(false);
      setModalTag(null);
      message.success("Create user successfully");
    } else {
      message.error(
        rep?.message ?? "Something went wrong. Please try again later."
      );
      setVisible(false);
      setModalTag(null);
    }
  };

  const handelUPdateAdmin = async (id: number, payload: any) => {
    const rep = await AdminsAPI.update(id, payload, accessToken);
    if (rep?.status === true) {
      mutate(AdminsAPI.list(accessToken));
      setVisible(false);
      setSelectedItem(null);
      setModalTag(null);
      message.success("Update user successfully");
    } else {
      message.error(
        rep?.message ?? "Something went wrong. Please try again later."
      );
      setVisible(false);
      setModalTag(null);
    }
  };

  const handelOkayMoal = (payload: any) => {
    if (modalTag === 0) {
      onCreateAdmin(payload);
    }
    if (modalTag === 1 && selectedItem?.id) {
      handelUPdateAdmin(selectedItem?.id, payload);
    }
  };

  const handleSearch = (keySearch: string) => {
    if (keySearch === "") {
      setFilterData([]);
    } else {
      const tempFilterData = rowData.filter(
        (row: any) =>
          row.identity
            .toString()
            .toLowerCase()
            .includes(keySearch.toLowerCase()) ||
          row.name.toString().toLowerCase().includes(keySearch.toLowerCase())
      );
      setFilterData([...tempFilterData]);
    }
  };

  return (
    <DashboardContainer>
      <Card
        title={<Title level={3}>User Management</Title>}
        extra={
          <>
            <Search
              placeholder="Search"
              onChange={(e) => {
                const inputKey = e.target.value;
                setKeySearch(inputKey);
                handleSearch(inputKey);
              }}
              style={{ width: 200 }}
            />
            <Button
              className="btn-create btn-default"
              type="primary"
              onClick={onCreate}
              style={{
                marginLeft: "20px",
              }}
            >
              Create
            </Button>
          </>
        }
      >
        <Table
          className="table-custom"
          columns={columnDefs}
          dataSource={keySearch !== "" ? filterData : rowData}
          scroll={{ x: 800 }}
          pagination={false}
          rowClassName={(_record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
        />
      </Card>
      <CreateUserAdminModal
        userGroup={selectedItem}
        visible={visible}
        onOk={handelOkayMoal}
        onCancel={onModalCancel}
        rolesList={rolesList}
        medicalCentreList={medicalCentre?.data}
      />
    </DashboardContainer>
  );
}

export default UserManagement;
