/**
 * @author ming.leng
 */
import React, { useEffect } from "react";
import { Modal, Button, Form, Input } from "antd";
import { useAuth } from "../../../../context/auth";
import { ISite, IUpdateSitePayload } from "../../../../types/site.model";
import { PAGE_NAME } from "../../../../constants";

const tailFormItemLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export type PropsType = {
  visible: boolean;
  handleCancelModal: () => void;
  updateSite: (
    token: string,
    id: number,
    payload: IUpdateSitePayload
  ) => Promise<any>;
  selectedSite: ISite;
};

function UpdateSiteModal({
  visible,
  handleCancelModal,
  updateSite,
  selectedSite,
}: PropsType) {
  const { accessToken } = useAuth();
  const [form] = Form.useForm();
  const onCancel = () => {
    form.resetFields();
    handleCancelModal();
  };

  const onFinish = (fieldsValue: any) => {
    const payload = {
      ...fieldsValue,
    };
    updateSite(accessToken, selectedSite?.id, payload);
    onCancel();
  };

  useEffect(() => {
    if (visible) {
      form.setFields([
        { name: "name", value: selectedSite?.name ?? "" },
        { name: "location", value: selectedSite?.location ?? "" },
      ]);
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={`Update ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1}`}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        name="update-site"
        onFinish={onFinish}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: `Please enter ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1} Name!` }]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Location"
          name="location"
          rules={[{ required: true, message: `Please enter ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1} Location!` }]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdateSiteModal;
