/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IBaseResponse, PaginationQueryType } from "../types/base.model";
import {
  ICreateTimeslotsPayload,
  IGetTimeslotsResponse,
  ITimeSlot,
  PayloadGetTimeSlotsByDate,
} from "../types/timeslots.model";

const path = "/vaccination/timeslots";

export default {
  async list(token: string, payload: PayloadGetTimeSlotsByDate): Promise<any> {
    const queryParams = new URLSearchParams({});
    if (payload.unit_id) {
      queryParams.append("unit_id", payload.unit_id.toString());
    }
    if (payload.date) {
      queryParams.append("date", payload.date.toString());
    }
    if (payload.site_id) {
      queryParams.append("site_id", payload.site_id.toString());
    }

    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL + path + `?${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data, status, message }: IGetTimeslotsResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return data;
  },
  async listTimeSlotByUnit(
    token: string,
    payload: PayloadGetTimeSlotsByDate
  ): Promise<any> {
    const queryParams = new URLSearchParams({});
    if (payload.unit_id) {
      queryParams.append("unit_id", payload.unit_id.toString());
    }
    if (payload.date) {
      queryParams.append("date", payload.date);
    }
    if (payload.site_id) {
      queryParams.append("site_id", payload.site_id.toString());
    }

    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL +
        `/vaccination/units/timeslots?${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data, status, message }: IGetTimeslotsResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return data;
  },
  async createTimeSlots(
    token: string,
    payload: ICreateTimeslotsPayload
  ): Promise<boolean> {
    const res = await fetch(process.env.REACT_APP_SWIFTEST_API_URL + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return status;
  },
  async editTimeSlotById(
    token: string,
    timeSlotId: number,
    payload: any
  ): Promise<IBaseResponse> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL + path}/${timeSlotId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    return { status, message };
  },
  async createCommandDepartmentTimeSlots(
    token: string,
    payload: any
  ): Promise<boolean> {
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL + `/vaccination/timeslots/assign`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message || "Can Not Create Time Slots");
    }
    return status;
  },
  async editDepartmentTimeSlots(
    token: string,
    timeslotID: number,
    payload: any
  ): Promise<boolean> {
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL +
        `/vaccination/timeslots/${timeslotID}/assign`,
      // `/vaccination/units/timeslots/${timeslotID}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message || "Can Not Update Time Slots");
    }
    return status;
  },
  async deleteSingleTimeSlot(token: string, slot_id: number): Promise<any> {
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL +
        `/vaccination/timeslots/unit-timeslot/${slot_id}`,
      // `/vaccination/units/timeslots/${timeslotID}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message || "Can Not Delete Time Slots");
    }
    return { status, message };
  },

  async deleteAllTimeSlot(token: string, time_slot_id: number): Promise<any> {
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL +
        `/vaccination/timeslots/${time_slot_id}`,
      // `/vaccination/units/timeslots/${timeslotID}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message || "Can Not Delete Time Slots");
    }
    return { status, message };
  },
};
