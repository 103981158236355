import React, { createContext, memo, useMemo, useState } from "react";
import VaccineAPI from "../../api/vaccine";
import { useAuth } from "../../context/auth";
import { useRequest } from "../../swr";

export const PMContext = createContext<{
  vaccineOptions: any[];
  vaccines: any[];
  accessToken: string;
  medicalCenter: any[];
  requestedPersonnel: (a: any[], b: string[]) => string[];
  allergies: any[];
  onGetAllergies: Function;
}>({
  vaccineOptions: [],
  vaccines: [],
  medicalCenter: [],
  accessToken: "",
  requestedPersonnel: (a: any[], b: string[]) => [],
  allergies: [],
  onGetAllergies: () => {},
});

const getVaccineOptions = async (accessToken: string) => {
  return VaccineAPI.options(accessToken, { items_per_page: "", page: "" })
    .then((req) => {
      return req.data || [];
    })
    .catch((error) => {
      return [];
    });
};

const getCascadeVaccineOptions = (options: any[]) => {
  return options?.map((item: any) => {
    return {
      label: item?.name,
      value: item?.name,
      children: item?.brands?.map((item: any) => {
        return {
          value: item?.id,
          label: item?.name,
          disabled: false,
        };
      }),
    };
  });
};

const getVaccines = async (accessToken: string) => {
  return VaccineAPI.listVaccines(accessToken, { items_per_page: "", page: "" })
    .then((req) => {
      return (req && req?.vaccines) || [];
    })
    .catch((error) => {
      return [];
    });
};

function PersonnelManagementContext({ children }: any) {
  const { accessToken, roles } = useAuth();

  const [vaccineOptions, setVaccineOptions] = useState<any[]>([]);
  const [vaccines, setVaccines] = useState<any[]>([]);
  const [allergies, setAllergies] = useState<any[]>([]);

  const { data: medicalCenters, error: siteErr } = useRequest(
    "GET",
    `/vaccination/sites?assigned_site_id=${
      roles?.data?.assignedSite ? roles.data.assignedSite : 0
    }`
  );

  useMemo(async () => {
    if (!vaccineOptions.length) {
      let options = await getVaccineOptions(accessToken);
      setVaccineOptions(getCascadeVaccineOptions(options));
    }
    setVaccines(await getVaccines(accessToken));
  }, []);

  const requestedPersonnel = (personnelList: any[], selectedRowKeys: any[]) => {
    if (personnelList.length) {
      return selectedRowKeys.map((srk: any) => {
        const appointments = personnelList.filter(
          (pl: any) => pl.id === srk
        )?.[0]?.appointments;

        return (
          appointments?.filter(
            (appointment: any) =>
              appointment?.vaccines?.filter(
                (vaccine: any) => vaccine.status === "Requested"
              ).length
          )?.length && srk
        );
      });
    }
    return [];
  };

  const onGetAllergies = async () => {
    if (!allergies.length) {
      const res = await VaccineAPI.allergiesOptions(accessToken)
      setAllergies(res)
    }
  }

  const contextValue = useMemo(
    () => ({
      vaccineOptions,
      accessToken,
      vaccines,
      requestedPersonnel,
      medicalCenter: medicalCenters?.data,
      allergies,
      onGetAllergies,
    }),
    [vaccineOptions, accessToken, vaccines, medicalCenters?.data, allergies, onGetAllergies]
  );

  return (
    <PMContext.Provider value={contextValue}>{children}</PMContext.Provider>
  );
}

export default memo(PersonnelManagementContext);
