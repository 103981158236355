/**
 * @author ming.leng
 */
import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Input, Select } from "antd";
import { useAuth } from "../../../../context/auth";
import { IAdmin } from "../..";
import { IUnitAdmin, IUnit } from "../../../../types/unit.model";
import { PAGE_NAME } from "../../../../constants";

const tailFormItemLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

interface PropsType {
  visible: boolean;
  handleCancelModal: () => void;
  updateUnit: Function;
  unitAdminsList: IAdmin[];
  unit: IUnit;
  currentAdmins: IUnitAdmin[];
}

function UpdateModal({
  visible,
  handleCancelModal,
  updateUnit,
  unitAdminsList,
  unit,
  currentAdmins,
}: PropsType) {
  const [selectedItems, setSelectedItems] = useState([]);
  const { accessToken } = useAuth();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [visible, currentAdmins, unitAdminsList, form]);

  const onFinish = (fieldValues: any) => {
    updateUnit(accessToken, fieldValues, unit.id);
  };
  const onCancel = () => {
    handleCancelModal();
    form.resetFields();
  };

  return (
    <Modal
      title={`Update ${PAGE_NAME.DEPARTMENT.LABEL_1}`}
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      getContainer={false}
      destroyOnClose
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        name="admin-user-group"
        onFinish={onFinish}
        initialValues={{
          name: unit?.name,
          admin_ids: currentAdmins?.map((item) => item.id),
        }}
      >
        <Form.Item
          name="name"
          label="Name :"
          rules={[{ required: true, message: "Please enter name!" }]}
        >
          <Input placeholder="Please enter Department name" />
        </Form.Item>
        <Form.Item name="admin_ids" label="Admin">
          <Select
            mode="multiple"
            placeholder="Please select name"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={selectedItems}
            onChange={(selectedItems: never[]) =>
              setSelectedItems(selectedItems)
            }
            style={{ width: "100%" }}
          >
            {[...unitAdminsList, ...currentAdmins].map((admin: IAdmin) => {
              return (
                <Select.Option key={admin.id} value={admin.id}>
                  {admin.identity}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdateModal;
