/**
 * @author ming.leng
 */
import React, { useState } from "react";
import { useAuth } from "../../../../context/auth";
import { message, Tabs, Row, Col } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/es/upload/interface";
import Dragger from "antd/lib/upload/Dragger";

// component
import ApptRegistrationTable from "../../../../components/Tables/ApptRegistration";

// image
import DownloadIcon from "../../../../assets/icon/download-icon.png";

// type
import { IWaitlistItem } from "../../../../types/waitlist.model";

// APIs
import PersonnelAPI from "../../../../api/personnel";

const { TabPane } = Tabs;

function BulkRegistration() {
  const { accessToken } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);
  const [insertedData, setInsertedData] = useState<IWaitlistItem[]>(null!);
  const [invalidData, setInvalidData] = useState<IWaitlistItem[]>(null!);
  const [ignoredData, setIgnoredData] = useState<IWaitlistItem[]>(null!);
  const [updatedData, setUpdatedData] = useState<IWaitlistItem[]>(null!);

  /**
   *
   * @param options
   * @returns Promise
   */
  const uploadImage = (options: {
    // onSuccess: any;
    // onError: any;
    file: any;
    // onProgress: any;
  }): Promise<any> => {
    setLoading(true);

    const { file } = options;

    const myHeaders = new Headers();
    const fmData = new FormData();

    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    fmData.append("csvFile", file);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: fmData,
    };
    // try {
    return fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}/vaccination/personnels/upload`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.status && result?.message === "Upload successfully!") {
          message.success(result?.message ?? "Upload successfully!");

          setInsertedData(result?.inserted_data ?? []);
          setInvalidData(result?.invalid_data ?? []);
          setIgnoredData(result?.ignored_data ?? []);
          setUpdatedData(result?.updated_data ?? []);
        }

        if (!result?.status && result?.message) {
          message.error(result?.message ?? "");
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const optDrag = {
    name: "csvFile",
    multiple: false,
    customRequest: uploadImage,
    onChange(info: UploadChangeParam) {
      const { status } = info?.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
    },
  };
  const removeItemUploadFileList = (removeFile: UploadFile) => {
    const fileList = uploadFileList.filter(function (obj) {
      return obj.uid !== removeFile.uid;
    });
    setUploadFileList(fileList);
  };

  const downloadPersonnelRegistrationTemplate = (token: string) => {
    return PersonnelAPI.downloadTemplate(token)
      .then((data: any) => {
        const blob = new Blob([data as BlobPart], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.className = "downloader";
        a.download = `ST_Appointment_Registration_Template.csv`;
        a.click();
      })
      .catch((error: any) => {
        message.error(error.message ?? error);
        return false;
      });
  };

  return (
    <div>
      <Row justify="end" style={{ width: "100%" }}>
        <Col>
          <div
            onClick={() =>
              downloadPersonnelRegistrationTemplate(accessToken)
            }
            style={{
              color: "#000000",
              opacity: 0.65,
              padding: "10px",
              marginBottom: "10px",
              cursor: "pointer",
            }}
          >
            <img
              src={DownloadIcon}
              alt="download wailist template"
              style={{ height: 16, paddingRight: 6 }}
            />
            Download Template
          </div>
        </Col>
      </Row>
      <Dragger
        {...optDrag}
        showUploadList={true}
        fileList={uploadFileList}
        onRemove={(file: UploadFile) => removeItemUploadFileList(file)}
      >
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>
      <br />
      {(invalidData || ignoredData || ignoredData || updatedData) && (
        <Tabs defaultActiveKey="1">
          {invalidData && invalidData.length !== 0 && (
            <TabPane
              tab={
                <>
                  <div style={{ color: "red" }}>
                    Invalid Access/Data
                    <span style={{ color: "rgb(0 0 0 / 65%)" }}>
                      : {invalidData.length}
                    </span>
                  </div>
                </>
              }
              key="1"
            >
              <ApptRegistrationTable data={invalidData} loading={loading} />
            </TabPane>
          )}
          {ignoredData && ignoredData.length !== 0 && (
            <TabPane
              tab={
                <>
                  <div>
                    Duplicate Data
                    <span style={{ color: "rgb(0 0 0 / 65%)" }}>
                      : {ignoredData.length}
                    </span>
                  </div>
                </>
              }
              key="2"
            >
              <ApptRegistrationTable data={ignoredData} loading={loading} />
            </TabPane>
          )}
          {updatedData && updatedData.length !== 0 && (
            <TabPane
              tab={
                <>
                  <div>
                    Updated Data
                    <span style={{ color: "rgb(0 0 0 / 65%)" }}>
                      : {updatedData.length}
                    </span>
                  </div>
                </>
              }
              key="3"
            >
              <ApptRegistrationTable data={updatedData} loading={loading} />
            </TabPane>
          )}
          {insertedData && insertedData.length !== 0 && (
            <TabPane
              tab={
                <>
                  <div>
                    Inserted Data
                    <span style={{ color: "rgb(0 0 0 / 65%)" }}>
                      : {insertedData.length}
                    </span>
                  </div>
                </>
              }
              key="4"
            >
              <ApptRegistrationTable data={insertedData} loading={loading} />
            </TabPane>
          )}
        </Tabs>
      )}
    </div>
  );
}

export default BulkRegistration;
