import React, { PropsWithChildren, useEffect, useState } from "react";
import { Modal, Button, Form, Input, Row, Card, Space } from "antd";
import { useAuth } from "../../../../context/auth";
import {
  IUpdateVaccinePayload,
  IVaccineItem,
} from "../../../../types/vaccine.model";
import { APPEND_SUFFIX } from "../../../../constants";

const formItemLayout = {
  labelCol: {
    span: 8,
    flex: "180px",
  },
  wrapperCol: {
    span: 14,
  },
};

const tailFormItemLayout = {
  wrapperCol: { offset: 9, span: 16 },
};

export type PropsType = {
  visibleUpdateModal: boolean;
  handleCancelModal: () => void;
  updateVaccine: (
    token: string,
    payload: IUpdateVaccinePayload,
    id: number
  ) => void;
  vaccineItem: IVaccineItem;
};

export const DosesRendering = ({
  doses,
}: PropsWithChildren<{ doses: number }>) => {
  return new Array(doses === 1 ? +doses : doses - 1).fill("").map((x, i) => {
    if (doses === 1) return "";
    return (
      <Space key={i}>
        <Form.Item
          label={`${APPEND_SUFFIX(i + 1)}-${APPEND_SUFFIX(
            i + 2
          )} Dose Minimum Days`}
          name={"min_days_" + i}
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject();
                }
                if (+value < 0) {
                  return Promise.reject("Minimum doses can't be less than 0");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label={`${APPEND_SUFFIX(i + 1)}-${APPEND_SUFFIX(
            i + 2
          )} Dose: Maximum Days`}
          name={"max_days_" + i}
          rules={[
            { required: true, message: "Please enter Maximum Days!" }, 
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || +getFieldValue("min_days_" + i) <= +value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Maximum day must more than minimum day!'));
              },
            }),
          ]}
          dependencies={["min_days_" + i]}
        >
          <Input type="number" />
        </Form.Item>
      </Space>
    );
  });
};

export const getDurations = (fieldsValue: any, doses: number) => {
  const durations = +doses === 1 ? [{ min_days: 0, max_days: 0 }] : [];
  for (let i = 0; i < +doses - 1; i++) {
    durations.push({
      min_days: +fieldsValue["min_days_" + i],
      max_days: +fieldsValue["max_days_" + i],
    });
  }
  return durations;
};

function UpdateVaccineModal({
  visibleUpdateModal,
  handleCancelModal,
  updateVaccine,
  vaccineItem,
}: PropsType) {
  const [doses, setDoses] = useState(0);
  useEffect(() => {
    if (visibleUpdateModal) {
      setDoses(vaccineItem?.total_doses ?? 0);

      const durations = [];
      const minFields = vaccineItem?.durations?.map(
        (x: object) => Object.values(x)[0]
      );
      const maxFields = vaccineItem?.durations?.map(
        (x: object) => Object.values(x)[1]
      );
      for (let i = 0; i < +vaccineItem?.total_doses - 1; i++) {
        durations.push({
          min_days: +minFields[i],
          max_days: +maxFields[i],
        });
      }
      const MIN = durations.map((x, i) => ({
        name: `${Object.keys(x)[0]}_${i}`,
        value: Object.values(x)[0] || vaccineItem.min_days,
      }));
      const MAX = durations.map((x, i) => ({
        name: `${Object.keys(x)[1]}_${i}`,
        value: Object.values(x)[1] || vaccineItem.max_days,
      }));

      form.setFields([
        { name: "name", value: vaccineItem?.name ?? "" },
        { name: "brand", value: vaccineItem?.brand ?? "" },
        { name: "doses", value: vaccineItem?.total_doses ?? 0 },
        ...MAX,
        ...MIN,
      ]);
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleUpdateModal]);

  const { accessToken } = useAuth();
  const [form] = Form.useForm();
  const onCancel = () => {
    form.resetFields();
    handleCancelModal();
  };
  const onFinish = (fieldsValue: any) => {
    const payload = {
	  name: fieldsValue.name,
	  brand: fieldsValue.brand,
      total_doses: fieldsValue.doses,
      durations: getDurations(fieldsValue, fieldsValue.doses),
    };
    updateVaccine(accessToken, payload, vaccineItem.id);
  };

  return (
    <Modal
      title="Update Vaccine Type"
      visible={visibleUpdateModal}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Form
        {...formItemLayout}
        form={form}
        layout="horizontal"
        name="update-vaccnine"
        labelAlign="left"
        labelWrap
        colon={false}
        onFinish={onFinish}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please enter Vaccine Doses!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Brand"
          name="brand"
          rules={[{ required: true, message: "Please enter Vaccine Doses!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Total Doses Needed"
          name="doses"
          rules={[
            { required: true, message: "Please enter Vaccine Doses!" },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject();
                }
                if (isNaN(value)) {
                  return Promise.reject("Minimum doses has to be a number.");
                }
                if (+value < 1) {
                  return Promise.reject("Minimum doses can't be less than 1");
                }
                if (+value > 5) {
                  return Promise.reject("Minimum doses can't be more than 5");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            min={1}
            max={5}
            type="number"
            onChange={(e) => (+e.target.value >= 1 && +e.target.value <= 5) && setDoses(+e.target.value)}
          />
        </Form.Item>

        {doses ? <DosesRendering doses={+doses} /> : <div />}

        <Form.Item {...tailFormItemLayout}>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdateVaccineModal;
