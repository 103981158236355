/**
 * @author ming.leng
 */
import React, { useEffect, useState } from "react";
import { Card, Typography, message, Button, Modal } from "antd";
import DashboardContainer from "../../layout/Dashboard";
import { useAuth } from "../../context/auth";
import CommandManagementTable from "../../components/Tables/CommandManagment";
import CommandAPI from "../../api/command";
import { ICommand, ICreateCommandPayload } from "../../types/command.model";
import { TablePaginationType } from "../../types/table.model";
import { useRequest } from "../../swr";
import CreateCommand from "./Modal/CreateCommand";
import EditCommand from "./Modal/EditCommand";
import { IUnit } from "../../types/unit.model";
import { PAGE_NAME } from "../../constants";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;
const { Title } = Typography;

const initPagination: TablePaginationType = {
  pageSize: 10,
  current: 1,
  total: 0,
};

export interface ICommandAdmin {
  id: number;
  identity: string;
}

const CommandManagement = () => {
  const { accessToken } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [commandList, setCommandList] = useState<ICommand[]>(null!);
  const [pagination, setPagination] = useState<TablePaginationType>(
    initPagination
  );
  const [visibleCreateCommand, setVisibleCreateCommand] = useState<boolean>(
    false
  );
  const [visibleEditCommand, setVisibleEditCommand] = useState<boolean>(false);
  const [selectedCommand, setSelectedCommand] = useState<ICommand>(null!);

  const {
    data: commandAdmin,
    error: commandAdminErr,
    mutate: mutateCommandAdmin,
  } = useRequest("GET", "/vaccination/commands/admin");
  const commandAdminList: ICommandAdmin[] = commandAdmin?.commandAdmins ?? [];
  const availableCommandAdminList: ICommandAdmin[] =
    commandAdmin?.availableAdmins ?? [];
  if (commandAdminErr?.message) {
    message.error(
      commandAdminErr?.message ?? "Can not get all command admins!"
    );
  }

  // fetch units which can be assigned to command
  const { data: availableUnits, mutate: mutateAvailableUnits } = useRequest(
    "GET",
    "/vaccination/commands/unit/availables"
  );
  const availableUnitList = availableUnits?.data ?? [];

  // fetch all unit list
  const { data: unitsData, mutate: mutateAllUnits } = useRequest(
    "GET",
    "/vaccination/units?type_id=1"
  );
  const allUnits: IUnit[] = unitsData?.data ?? [];

  const handleSwitchPage = (pagination: TablePaginationType) => {
    fetchCommandList(accessToken, pagination);
  };

  const fetchCommandList = (token: string, pagination: TablePaginationType) => {
    setLoading(true);
    return CommandAPI.list(token, {
      items_per_page: pagination.pageSize,
      page: pagination.current,
    })
      .then(({ data, total }) => {
        setCommandList(data.map((item: any, index: number) => ({ ...item, no: index + 1 })));
        setPagination({ ...pagination, total });
        setLoading(false);
      })
      .catch((err) => {
        setCommandList(null!);
        setPagination(initPagination);
        message.error(err?.message ?? "Get command list failed!");
        setLoading(false);
      });
  };

  const createCommand = (token: string, payload: ICreateCommandPayload) => {
    return CommandAPI.create(token, payload)
      .then(() => {
        message.success("Create Command successfully");
        fetchCommandList(accessToken, pagination);
        mutateCommandAdmin();
        mutateAvailableUnits();
        setVisibleCreateCommand(false);
      })
      .catch((err) => {
        message.error(err?.message ?? "Create Command failed");
        setVisibleCreateCommand(false);
      });
  };

  const updateCommand = (
    token: string,
    payload: ICreateCommandPayload,
    id: number
  ) => {
    CommandAPI.update(token, payload, id)
      .then(() => {
        message.success("Update Command successfully");
        fetchCommandList(accessToken, pagination);
        setVisibleEditCommand(false);
        mutateAvailableUnits();
        mutateCommandAdmin();
        mutateAllUnits();
      })
      .catch((err) => {
        message.error(err?.message ?? "Update Command Failed!");
        setVisibleEditCommand(false);
      });
  };

  useEffect(() => {
    fetchCommandList(accessToken, pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditCommand = (command: ICommand) => {
    setSelectedCommand(command);
    setVisibleEditCommand(true);
  };

  const handelDelelte = (id: number) => {
    CommandAPI.delete(id, accessToken).then((res) => {
      if(res.status) {
        message.success(res.message);
      } else {
        message.error(res.message);
      }
      fetchCommandList(accessToken, pagination);
    });
  }

  const onDeleteCommand = (id: number) => {
    confirm({
      title: `Are you sure delete this ${PAGE_NAME.COMMAND.LABEL_1}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handelDelelte(id);
      },
    });
  }

  return (
    <DashboardContainer>
      <Card
        title={<Title level={3}>{PAGE_NAME.COMMAND.LABEL}</Title>}
        extra={
          <>
            <div></div>
            <Button
              className="btn-create btn-default"
              type="primary"
              onClick={() => {
                setVisibleCreateCommand(true);
              }}
              style={{
                marginLeft: "20px",
              }}
            >
              Create
            </Button>
          </>
        }
      >
        <CommandManagementTable
          data={commandList}
          loading={loading}
          pagination={pagination}
          switchPage={handleSwitchPage}
          onEditCommand={onEditCommand}
          onDeleteCommand={onDeleteCommand}
        />
        <CreateCommand
          visible={visibleCreateCommand}
          commandAdminsList={availableCommandAdminList}
          handleCancelModal={() => {
            setVisibleCreateCommand(false);
            mutateAvailableUnits();
          }}
          createCommand={createCommand}
          availableUnitList={availableUnitList}
        />
        <EditCommand
          visible={visibleEditCommand}
          handleCancelModal={() => {
            setVisibleEditCommand(false);
            setSelectedCommand(null!);
            mutateAllUnits();
            mutateAvailableUnits();
          }}
          availableCommandAdminList={availableCommandAdminList}
          commandAdminList={commandAdminList}
          selectedCommand={selectedCommand}
          updateCommand={updateCommand}
          allUnits={allUnits}
          availableUnitList={availableUnitList}
        />
      </Card>
    </DashboardContainer>
  );
};

export default CommandManagement;
