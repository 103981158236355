/**
 * @author ming.leng
 */
 import React, { useEffect } from "react";
 import { Modal, Button, Form, Input, DatePicker, Select } from "antd";
 import { useAuth } from "../../../../context/auth";
 import { ROLE_USER } from "../../../../constants/index";
 import {
   IUpdateVaccineInventoryPayload,
   IVaccine,
   IVaccineInventory,
 } from "../../../../types/vaccine.model";
 import moment from "moment";
 import { ISite } from "../../../../types/site.model";
 
 const formItemLayout = {
   labelCol: {
     span: 8,
   },
   wrapperCol: {
     span: 14,
   },
 };
 
 const tailFormItemLayout = {
   wrapperCol: { offset: 8, span: 16 },
 };
 
 export type PropsType = {
   visibleUpdateInvtModal: boolean;
   handleCancelModal: () => void;
   updateVaccineInventory: (
     token: string,
     payload: IUpdateVaccineInventoryPayload,
     id: number
   ) => void;
   vaccineList: IVaccine[];
   unit: IVaccineInventory;
   sitesList: ISite[];
 };
 
 function UpdateVaccineInventoryModal({
   visibleUpdateInvtModal,
   handleCancelModal,
   updateVaccineInventory,
   vaccineList,
   unit,
   sitesList,
 }: PropsType) {
   useEffect(() => {
     form.resetFields();
     if (visibleUpdateInvtModal) {
       form.setFields([
         { name: "site_id", value: unit?.site_id ?? "" },
         {
           name: "arrival_date",
           value: unit?.arrival_date ? moment(unit?.arrival_date) : moment(),
         },
         {
           name: "expiry_date",
           value: unit?.expiry_date ? moment(unit?.expiry_date) : moment(),
         },
         { name: "name", value: unit?.name ?? "" },
         { name: "vaccine_type_id", value: unit?.brand ?? "" },
         { name: "quantity", value: unit?.quantity ?? "" },
         { name: "cost", value: unit?.cost ?? "" },
         { name: "batch_number", value: unit?.batch_number ?? "" },
         { name: "lot_no", value: unit?.lot_no ?? "" },
       ]);
     } else {
       form.resetFields();
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [visibleUpdateInvtModal]);
 
   const { accessToken, roles } = useAuth();
   const [form] = Form.useForm();
   const onCancel = () => {
     form.resetFields();
     handleCancelModal();
   };
   const onFinish = (fieldsValue: any) => {
     const { quantity, batch_number, lot_no, cost } = fieldsValue;
     const payload = { quantity, batch_number, lot_no, cost };
     updateVaccineInventory(accessToken, payload, unit.id);
   };
 
   return (
     <Modal
       title="Update Vaccine Inventory"
       visible={visibleUpdateInvtModal}
       onCancel={onCancel}
       footer={null}
     >
       <Form
         {...formItemLayout}
         form={form}
         layout="horizontal"
         name="create-new-vaccine"
         onFinish={onFinish}
       >
         <Form.Item
           label="Medical Centre"
           name="site_id"
           rules={[{ required: true, message: "Please Select Medical Centre" }]}
         >
           <Select style={{ width: "100%" }} disabled>
             {sitesList?.map((site: ISite) => {
               return (
                 <Select.Option key={site.id} value={site.id}>
                   {site.name}
                 </Select.Option>
               );
             })}
           </Select>
         </Form.Item>
         <Form.Item
           label="Arrival Date"
           name="arrival_date"
           rules={[{ type: 'object' as const, required: true, message: 'Please select Vaccine Arrival Date!' }]}
         >
           <DatePicker disabled style={{ width: "100%" }} />
         </Form.Item>
         <Form.Item
           label="Expiry date"
           name="expiry_date"
           rules={[{ type: "object" as const, required: true, message: "Please select Vaccine Expiry Date!" }]}
         >
           <DatePicker disabled style={{ width: "100%" }} />
         </Form.Item>
         <Form.Item
           label="Vaccine Name"
           name="name"
           rules={[{ required: true, message: "Please enter Vaccine Name!" }]}
         >
           <Input type="text" disabled/>
         </Form.Item>
         <Form.Item
           name="vaccine_type_id"
           label="Vaccine Brand"
           rules={[
             { required: true, message: "Please enter Vaccine Brand!" },
           ]}
         >
           <Select style={{ width: "100%" }} disabled>
             {vaccineList?.map((vaccine: IVaccine, index: number) => {
               return (
                 <Select.Option key={vaccine.id} value={vaccine.id}>
                 {vaccine.brand}
               </Select.Option>
               );
             })}
           </Select>
         </Form.Item>
         <Form.Item
           label="Vaccine Quantity"
           name="quantity"
           rules={[{ required: true, message: "Please enter Vaccine Quantity!" }]}
         >
           <Input type="number" min={0} disabled={roles?.data?.role_id != ROLE_USER.SUPER_ADMIN_ID} />
         </Form.Item>
         <Form.Item
           label="Vaccine Cost"
           name="cost"
           rules={[{ required: true, message: "Please enter Vaccine Cost!" }]}
         >
           <Input type="number" min={0.00} step={0.01} />
         </Form.Item>
         <Form.Item
           label="Batch No."
           name="batch_number"
           rules={[
             { required: true, message: "Please enter Vaccine Batch No.!" },
           ]}
         >
           <Input type="text" />
         </Form.Item>
         <Form.Item
           label="Lot No"
           name="lot_no"
           rules={[
             { required: true, message: "Please enter Vaccine Lot No!" },
           ]}
         >
           <Input type="text" />
         </Form.Item>
         <Form.Item {...tailFormItemLayout}>
           <Button key="back" className="btn-default" onClick={onCancel}>
             Cancel
           </Button>
           <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
             Submit
           </Button>
         </Form.Item>
       </Form>
     </Modal>
   );
 }
 
 export default UpdateVaccineInventoryModal;
 