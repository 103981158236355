import React from "react";
import {
  // Progress,
  Space,
  Typography,
  Row,
  Col,
} from "antd";

// components
import Widget from "../../../../components/Widget";

// image
import RegistrationImage from "../../../../assets/registration.png";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// import { isArray } from "lodash";

// style
import "./style.less";

const { Text } = Typography;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#51d1c4" }} spin />
);

// const data = {
//   SG_DEG: 3,
//   SG_D2G: 3,
//   SG_D3G: 3,
//   SG_D4G: 3,
//   SG_D11G: 3,
//   SG_D9G: 3,
//   SG_D5G: 3,
//   SG_D6G: 3,
//   Total: 5,
// };

function Registration(props: any) {
  const { data = [], loading = true } = props;

  return (
    <Widget title={props?.title ?? ""} img={RegistrationImage}>
      <div
        className="widget__button"
        style={{ marginBottom: 10, marginLeft: 4 }}
      >
        Total: {props?.total ?? 3}
      </div>
      <br />
      {loading ? (
        <>
          <div
            style={{
              marginBottom: "10px",
              textAlign: "center",
              paddingTop: 30,
            }}
          >
            <Spin indicator={antIcon} />
          </div>
        </>
      ) : (
        <Row gutter={16}>
          {Object.keys(data).map((orgCode) => {
            if (orgCode === "Total") {
              return <></>;
            }
            return (
              <Col span={6}>
                <div className="mini-box" key={orgCode}>
                  <Space direction="vertical">
                    <Text style={{ fontSize: "11px" }}>{orgCode}</Text>
                    <Text strong>{data[orgCode]}</Text>
                  </Space>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
    </Widget>
  );
}

export default Registration;
