/**
 * @author Trung Tran
 */
import React from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";

import AuthRoute from "./AuthRoute";
import { useRootRouter } from "./RootRouter";
//pages
import Login from "../pages/Login";
import { ProtectedAuth } from "./ProvideAuth";
import { useAuth } from "../context/auth";
import FourZeroFour from "../pages/FourZeroFour";
import FourZeroThree from '../pages/FourZeroThere';
import { useCookies } from "react-cookie";

export default function RootRouter() {
  return (
      <Router>
        <ProtectedAuth>
        <Switch>
          <Route exact path="/login" component={Login} />
          <PrivateRoute>
          </PrivateRoute>
        </Switch>
        </ProtectedAuth>
      </Router>
  );
}

function PrivateRoute({ children, ...rest }: any) {
  const [cookies, setCookie] = useCookies(['idToken']);
  const history = useHistory();
  const { roles, isLoading, accessToken } = useAuth();
  let rootRouter = useRootRouter(roles?.data?.name);

  if (!accessToken && !cookies.idToken) {
    history.push('/login');
  }
  if (!isLoading && accessToken && roles?.message === 'Azure access token invalid') {
    return <Route exact path="*" component={FourZeroFour} />;
  }

  if (!isLoading && accessToken && roles?.message === 'User is not found, please contact your Administrator for support!') {
    return <Route exact path="*" component={FourZeroThree} />;
  }

  return (
    <Switch>
      {rootRouter.map((o, index) => (
        <AuthRoute
          path={o.path}
          exact={o.exact}
          component={o.page}
          key={index}
        />
      ))}
    </Switch>
  );
}