import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import "./style.less";


// model
import { IUnit } from "../../../types/unit.model";
import {
  InjectionStatusObject,
} from "../../../types/base.model";

// constants
import { DATE_FORMAT, ROLE_USER } from "../../../constants";
import { useAuth } from "../../../context/auth";
import { IVaccineBrand } from "../../../types/vaccinebrands.model";
import { ISite } from "../../../types/site.model";
import { PayloadGetTimeSlotsByDate } from "../../../types/timeslots.model";
import TimeslotsAPI from "../../../api/timeslots";
import moment from "moment";
import { Cascader } from "antd";
import { useRequest } from "../../../swr";

const { Option } = Select;

const injectionStatus: InjectionStatusObject[] = [
  { label: "Requested", code: 7, value: 7 },
  { label: "Scheduled", code: 2, value: 2 },
  { label: "Completed", code: 3, value: 3 },
  { label: "Rejected", code: 4, value: 4 },
  { label: "Outdated", code: 5, value: 5 },
  { label: "Ineligible", code: 6, value: 6 },
];


type FilterPersonalProps = {
  vacBrandsData: any;
  siteList: any;
  siteID: number;
  setSiteId: (site_id: number) => void;
  unitList: any;
  handelFilter: (filter: any) => void;
  getTimeslot: (timeslot: any) => void;
  fetchPersonnelInfo: Function;
  initPagination: any;
  getSelectedTime: (time: any) => void;
  filter: any;
  setAptRecords: (aptRecords: number) => void;
  setExpiredApptChecked: (expiredApptChecked: boolean) => void;
  setStatusId: (statusId: number) => void;
};
const FilterPersonal = ({
  vacBrandsData,
  siteList,
  setSiteId,
  siteID,
  unitList,
  initPagination,
  handelFilter,
  handleSearchPersonnel,
  getTimeslot,
  getSelectedTime,
  filter,
  setEligibleApptChecked,
  setExpiredApptChecked,
  setStatusId,
  setAptRecords,
}: FilterPersonalProps) => {
  const { roles, accessToken } = useAuth();
  const [form] = Form.useForm();
  const [timeSlot, setTimeSlot] = useState<any>([]);
  const [disabledTimeslot, setDisabledTimeslot] = useState(true);
  const [injectionDate, setInjectionDate] = useState(null);

  const handleAppointmentDateChange = (date: any) => {
    setInjectionDate(date)
    form.setFieldValue("time", undefined)
    getSelectedTime(form.getFieldValue("time"))
  }

  const hanleMedicalCentreChange = (site_id: any) => {
    setSiteId(site_id)
    form.setFieldValue("injection_date", undefined)
    //setInjectionDate(null)
  }

  const handleFilterAppointmentRecords = (aptRecords: any) => {
    setAptRecords(aptRecords);
  };

  const handleStatusId = (statusId: any) => {
    setStatusId(statusId);
  };

  const { data: vaccineData, error: vaccineError } = useRequest(
    "GET",
    "/vaccination/vaccines/options"
  );
  const vaccineOption: any[] = vaccineData?.data?.map((item: any) => {
    return {
      label: item?.name,
      value: item?.name,
      children: item?.brands?.map((item: any) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      }),
    };
  });


  useEffect(() => {
    if (siteID && injectionDate) {
      setDisabledTimeslot(false);
      const getTimeSlot = async (payload: PayloadGetTimeSlotsByDate) => {
        const res = await TimeslotsAPI.listTimeSlotByUnit(accessToken, payload);
        if (res && res.length) {
          setTimeSlot(res);
          getTimeslot(res)
        } else {
          setTimeSlot([]);
          getTimeslot([]);
        }
      };
      getTimeSlot({
        date: moment(injectionDate).format(DATE_FORMAT),
        site_id: siteID
      }).catch(err => {
        setTimeSlot([]);
        getTimeslot([]);
      })
    } else {
      setTimeSlot([]);
      getTimeslot([]);
      setDisabledTimeslot(true);
    }
  }, [siteID, injectionDate]);


  // Check role for select department
  const renderSelectDepartment = () => {
    switch (roles?.data?.name) {
      case "command-admin":
        return { label: "Unit", placeHolder: "{Please select unit" };
      case "department-admin":
        return { label: "Department", placeHolder: "Please select department" };
      default:
        return {
          label: "Unit/Department",
          placeHolder: "Please select unit/department",
        };
    }
  };

  const selectDepartment = renderSelectDepartment();

  const onEligibleApptChange = (e) => {
	  setEligibleApptChecked(e.target.checked);
  };

  const onExpiredApptChange = (e) => {
	  setExpiredApptChecked(e.target.checked);
  };

  return (
    <Card>
      <Form
        form={form}
        onFinish={handleSearchPersonnel}
        layout="vertical"
        className="personal-filter"
      >
        <Row gutter={24} wrap>
          <Col xs={24} md={6}>
            <Form.Item
              label={
                selectDepartment?.label
              }
              name="unit_id"
            >
              <Select
                placeholder={
                  selectDepartment?.placeHolder
                }
                allowClear={true}
              >
                {unitList &&
                  unitList.map((unit: IUnit) => (
                    <Option value={unit.id} key={unit.id}>
                      {unit.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item label="NRIC" name="nric">
              <Input type="text" placeholder="Please enter NRIC" onInput={e => e.target.value = e.target.value.toUpperCase()} />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item label="Name" name="name">
              <Input type="text" placeholder="Please enter name" />
            </Form.Item>
          </Col>
		  <Col xs={24} md={6}>
            <Form.Item
              label="Appointment Status"
            >
              <Select id="status_id" onChange={handleStatusId}
                placeholder="Please select vaccine status"
                allowClear={true}
              >
                {injectionStatus.map((obj: InjectionStatusObject) => (
                  <Option value={obj.value} key={obj.value}>
                    {obj.label}
                  </Option>
                ))}
              </Select>
              <Checkbox id="expiredappt" onChange={onExpiredApptChange}>
                  Expired Appointment
              </Checkbox>
			  </Form.Item>
          </Col>

        </Row>
        <Row gutter={24} wrap>
          <Col xs={24} md={6}>
            <Form.Item label="Medical Centre" name="sites" >
              <Select placeholder="Please select medical centre" allowClear={true}
				onChange={(value) => hanleMedicalCentreChange(value)}
				defaultValue={roles?.data?.assignedSite}>
                {siteList &&
                  siteList.map((site: ISite) => (
                    <Option value={site.id} key={site.id}>
                      {site.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              label={'Appointment Date'}
              name="injection_date"
            >
              <DatePicker placeholder="Please select appointment date" style={{ width: "100%" }} format={"YYYY-MM-DD"} onChange={handleAppointmentDateChange} />
            </Form.Item>
          </Col>
          {(roles?.data?.role_id === ROLE_USER.MED_ADMIN_ID) && <Col xs={24} md={6}>
            <Form.Item
              label="Appointment Time Slot:"
              name="time"
            >
              <Select style={{ width: "300px" }} placeholder="Please select time slot" disabled={disabledTimeslot}>
                {form.getFieldValue("injection_date") && form.getFieldValue("sites") &&
                  timeSlot &&
                  timeSlot?.[0]?.unit_timeslot?.map((e: any) => (
                    <Option value={e.id} key={e?.id}>
                      {`${e?.title ? `(${e.title})` : ""} ${e?.start_time ?? ""} - ${e?.end_time ?? ""} | ${e?.uses
                        } Taken`}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>}
          {(roles?.data?.role_id != ROLE_USER.MED_ADMIN_ID) && <Col xs={24} md={6}>
            <Form.Item
              label="Appointment Time Slot:"
              name="time"
            >
              <Select style={{ width: "300px" }} placeholder="Please select time slot" disabled={disabledTimeslot}>
                {form.getFieldValue("injection_date") && form.getFieldValue("sites") &&
                  timeSlot &&
                  timeSlot?.[0]?.unit_timeslot?.map((e: any) => (
                    <Option value={e.id} key={e?.id}>
                      {`${e?.title ? `(${e.title})` : ""} ${e?.start_time ?? ""} - ${e?.end_time ?? ""} | ${e?.slots - e?.uses
                        } Available`}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>}
		 </Row>
		 <Row gutter={24} wrap>
		 
		   <Col xs={24} md={6}>
            <Form.Item label="Vaccine" name="vaccine_brand">
			<Cascader style={{width:"100%"}} allowClear={true} options={vaccineOption}
			placeholder="Please select vaccine" />

            </Form.Item>
          </Col>
		  <Col xs={24} md={6}>
            <Form.Item label="Filter Appointment Record Period" name="filterappt">
              <Select defaultValue="6" placeholder="Please Select" onChange={(value) => handleFilterAppointmentRecords(value)}>
			  <Select.Option value="3">
				Up to 3 Months
			  </Select.Option>
			  <Select.Option value="6">
				Up to 6 Months
			  </Select.Option>
			  <Select.Option value="12">
				Up to 1 Year
			  </Select.Option>
			  <Select.Option value="24">
				Up to 2 Years
			  </Select.Option>
			  <Select.Option value="0">
				Up to Now
			  </Select.Option>
		  </Select>
            </Form.Item>
          </Col>
		 
          <Col
            xs={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                handelFilter({});
                getSelectedTime([]);
                getTimeslot([]);
              }}
            >
              Clear
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default FilterPersonal;
