/**
 * @author ming.leng
 */
import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Input, Select } from "antd";
import { useAuth } from "../../../../context/auth";
import { ICreateCommandPayload } from "../../../../types/command.model";
import { IUnit } from "../../../../types/unit.model";
import { PAGE_NAME } from "../../../../constants";

const tailFormItemLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export type PropsType = {
  visible: boolean;
  handleCancelModal: () => void;
  commandAdminsList: any[];
  createCommand: (
    token: string,
    payload: ICreateCommandPayload
  ) => Promise<any>;
  availableUnitList: IUnit[];
};

function CreateCommandModal({
  visible,
  handleCancelModal,
  createCommand,
  commandAdminsList,
  availableUnitList,
}: PropsType) {
  const { accessToken } = useAuth();
  const [selectedItems, setSelectedItems] = useState([]);
  const [form] = Form.useForm();
  const onCancel = () => {
    form.resetFields();
    handleCancelModal();
  };

  const onFinish = (fieldsValue: any) => {
    const payload = {
      name: fieldsValue["name"],
      admin_ids: fieldsValue["admin_ids"] ?? [],
      units: fieldsValue["units"] ?? [],
    };
    createCommand(accessToken, payload);
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
  }, [visible, commandAdminsList, form]);

  return (
    <Modal
      title={`Create ${PAGE_NAME.COMMAND.LABEL_1}`}
      visible={visible}
      maskClosable={false}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        name="create-command"
        onFinish={onFinish}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please enter Command Name!" }]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item name="admin_ids" label="Command Admin">
          <Select
            mode="multiple"
            placeholder="Please select Admin"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={selectedItems}
            onChange={(selectedItems: never[]) =>
              setSelectedItems(selectedItems)
            }
            style={{ width: "100%" }}
          >
            {commandAdminsList.map((admin: any) => {
              return (
                <Select.Option key={admin.id} value={admin.id}>
                  {admin.identity}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="units" label="Units">
          <Select
            mode="multiple"
            placeholder="Please select Units"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={selectedItems}
            onChange={(selectedItems: never[]) =>
              setSelectedItems(selectedItems)
            }
            style={{ width: "100%" }}
          >
            {availableUnitList &&
              availableUnitList?.map((unit: IUnit) => {
                return (
                  <Select.Option key={unit.id} value={unit.id}>
                    {unit.name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateCommandModal;
