import { AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../lib/msalConfig";

async function useGetAccessToken() {
  const { instance, accounts } = useMsal();

  const request = {
    ...loginRequest,
    account: accounts[0]
  };
  // Silently acquires an access token which is then attached to a request for Microsoft Graph data
  try {
    const response: AuthenticationResult = await instance.acquireTokenSilent(request);
    return response.accessToken;
  } catch (e) {
    const response: AuthenticationResult = await instance.acquireTokenPopup(request);
    return response.accessToken;
  }
}

export default async function Fetching(
  path: string,
  options: any
): Promise<{ message: string; status: boolean }> {
  // const access_token = await useGetAccessToken(); // lỗi nếu dùng hook ở đây
  
  return Promise.resolve(
    fetch(process.env.REACT_APP_SWIFTEST_API_URL + path,
      {
        ...options,
        // headers: {
        //   ...options.headers,
        //   Authorization: `Bearer ${'access_token'}`,
        // }
      }
    ))
    .then((res) => res.json())
    .then((d) => {
      let message = d?.message;
      if (d?.errors?.length) {
        message = `${d?.errors?.[0]?.msg}: ${d?.errors?.[0]?.value}`
      }
      return {
        status: d?.success ?? d?.status ?? false,
        message,
        data: d?.data
      }
    })
    .catch(() => {
      return { status: false, message: "Something went wrong. Please try again", data: null }
    });
}