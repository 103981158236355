import { VACCINATION_STATUS } from "../constants";

export const setCookie = (
  cookieName: string,
  cookieValue: string,
  date: Date,
): void => {
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
};

export const getCookie = (cookieName: string): string => {
  const match = document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));
  if (match) return match[2].replace(/[']+/g, '');
  return '';
};

export const deleteCookie = (cookieName: string): void => {
  if (getCookie(cookieName)) {
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  }
};

export const getStatusColor = (status: string) => {
  switch(status) {
    case VACCINATION_STATUS.NOT_SCHEDULED: 
      return '#5EFFFF';
	case VACCINATION_STATUS.REQUESTED: 
      return '#FFD800';
    case VACCINATION_STATUS.SCHEDULED:
      return '#E36C09';
    case VACCINATION_STATUS.COMPLETED:
      return '#00FF00';
    case VACCINATION_STATUS.REJECTED:
      return '#FF0000';
    case VACCINATION_STATUS.OUTDATED:
      return '#400000';
    case VACCINATION_STATUS.INELIGIBLE:
      return '#0000FF';
  }
}

export const removeEmptyUrlParams = (queryString: any) => {
  const params = new URLSearchParams(queryString);
  [...params?.entries()].forEach(([key, value]) => {
    if (value==="null" || value==="undefined") {
      params?.delete(key);
    }
  });
  return params
}
