/**
 * @author ming.leng
 */
import React, { useEffect } from "react";
import { Modal, Form, Button, Input } from "antd";
import { useAuth } from "../../../../context/auth";
import { IUpdateUnitPayload } from "../../../../types/unit.model";
import { Select } from "antd";
import { PAGE_NAME } from "../../../../constants";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};
interface PropsType {
  visible: boolean;
  handleCancelModal: () => void;
  createUnit: Function;
  unitAmdin: Array<{ id: number; identity: string }>;
}

function CreateModal({
  visible,
  handleCancelModal,
  createUnit,
  unitAmdin,
}: PropsType) {
  const { accessToken } = useAuth();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [visible, form]);

  const onFinish = (fieldValues: any) => {
    const payload: IUpdateUnitPayload = {
      ...fieldValues,
      admin_ids: fieldValues?.admin_ids ? fieldValues?.admin_ids : [],
      type_id: 1,
    };
    createUnit(accessToken, payload);
  };
  const onCancel = () => {
    handleCancelModal();
    form.resetFields();
  };

  return (
    <Modal
      title={`Create ${PAGE_NAME.UNIT_MANAGEMENT.LABEL_1}`}
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      getContainer={false}
      destroyOnClose
      footer={[
        <div style={{ textAlign: "center" }}>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
          >
            Submit
          </Button>
        </div>,
      ]}
    >
      <Form
        form={form}
        layout="horizontal"
        name="create_unit"
        {...formItemLayout}
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter name!" }]}
        >
          <Input placeholder="Please enter name" />
        </Form.Item>
        <Form.Item name="admin_ids" label="Unit Admin">
          <Select mode="multiple" placeholder="Please select Admin">
            {unitAmdin &&
              unitAmdin.map((item: { id: number; identity: string }) => {
                return <Option value={item?.id}>{item?.identity}</Option>;
              })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateModal;
