import React, { useEffect, useState } from "react";
import { Card } from "antd";

// Component
import RoundChart from "../RoundChart";
import LineChart from "../LineChart";
// import scheduler from "../../../api/scheduler";

/*
Props
  - title
  - status (ex: Not scheduled)
  - standard:  quantity of standard (Ex: data.summary.standard: 1)
  - reserve: quantity of reserve
  - firstInjectionCompleted: percent are based on the number of completed 1 injection
  - secondInjectionComplete: percent are based on the number of completed 2 injection
*/
type PropsType = {
  title: string;
  data: any;
  overall: any;
  schedule: any;
  vaccineSelected:any;
  vaccineList:any
};

export interface IShortInjectionData {
  total: number;
  completed: number;
  rejected: number;
  scheduled: number;
  // totalScheduled: number;
  completedPercentage: number;
  sumOfCompletedAndRejectedPercentage: number;
}
interface INotScheduled {
  standard: number;
  reserve: number;
}
function ChartOverall({ data, title, overall, schedule,vaccineSelected,vaccineList }: PropsType) {
  // const { data = [], loading = true } = props;
  const [notScheduled, setNotScheduled] = useState<INotScheduled>(null!);
  const [injections, setInjections] = useState<IShortInjectionData[]>(null!);

  useEffect(() => {
    data && formatData(data);
  }, [data]);

  const getMaximumDose=(listVaccine:any) => {
    let listBrands:any[]=[]
    listVaccine?.map((item:any) => {
      const newListBrands= listBrands?.concat(item?.brands)
      listBrands=newListBrands
    	
    })
    const maximumDose= listBrands?.filter((item:any)=>{
      return item?.id===vaccineSelected?.[1]
    })
    const injection=[]
    for (let index = 0; index < maximumDose?.[0]?.totalDoses; index++) {
      injection?.push({
        total: 0,
        completed: 0,
        rejected: 0,
        scheduled: 0,
        // totalScheduled: 0,
        completedPercentage: 0,
        sumOfCompletedAndRejectedPercentage: 0,
      })
    }

     return injection
  }

  const formatData = (data: any) => {
    const { not_scheduled, ...rest } = data;
    setNotScheduled(not_scheduled);
    const injectionData: IShortInjectionData[] = Object.keys(rest)
      .map((key, index) => {
        return rest[key];
      })
      .map(
        ({
          completed,
          rejected,
          scheduled,
          total,
          // sumOfCompletedAndRejectedPercentage,
        }) => {
          // const totalScheduled = completed + rejected + scheduled;
          return {
            completed,
            rejected,
            scheduled,
            total,
            sumOfCompletedAndRejectedPercentage:
              total !== 0
                ? Math.round(((completed + rejected) / total) * 100)
                : 0,
            completedPercentage:
              total !== 0 ? Math.round((completed / total) * 100) : 0,
          };
        }
      );
    injectionData?.length !== 0
      ? setInjections(injectionData)
      : setInjections(
          getMaximumDose(vaccineList)
        );
  };

  const formatOverall = (overall: any): number => {
    if (overall?.no_personnel_taken === 0 || overall?.no_personnels === 0) {
      return 0;
    } else {
      return Math.round((overall?.no_personnel_taken / overall?.no_personnel) * 100);
    }
  };

  const formatSchedule = (schedule: any): number => {
    if (schedule?.no_personnel_taken === 0 || schedule?.no_personnel_schedule === 0) {
      return 0;
    } else {
      return Math.round((schedule?.no_personnel_taken / schedule?.no_personnel_schedule) * 100);
    }
  };

  return (
    <Card
      className="chart-block"
      title={title}
      style={{
        boxShadow: "1px 2px 6px rgba(0, 0, 0, 0.5)",
        textAlign: "center",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <div style={{display:"flex",justifyContent:"space-around",flexWrap:"wrap"}}>
          <div>
            <RoundChart percent={formatSchedule(schedule)} status="(Scheduled)" />
          </div>
          <div>
            <RoundChart percent={formatOverall(overall)} status="(Overall)" />
          </div>
        </div>
        <LineChart injections={injections} />
      </div>
    </Card>
  );
}

export default ChartOverall;
