const path = "/admins";

const adminAPIs = {
  async list(token: string) {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data } = await res.json();

    return data?.data;
  },

  async create(payload: any, token: string) {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );

    const { status, data, message } = await res.json();

    return { status, data: data ?? [], message: message ?? "" };
  },

  async update(id: number, payload: any, token: string) {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );

    const { status, data, message } = await res.json();

    return { status, data: data ?? [], message: message ?? "" };
  },

  async delete(id: string, token: string): Promise<any> {
    const res = await fetch(`${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const { status, message }: any = await res.json();
    return { status, message };
  },
};

export default adminAPIs;
