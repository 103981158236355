import React from "react";
import moment from "moment";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table/interface";
import { IWaitlistItem } from "../../../types/waitlist.model";
import { DATE_FORMAT, DATE_FORMAT_VC_ADMIN } from "../../../constants";

export type PropsType = {
  data: IWaitlistItem[];
  loading: boolean;
};

function ApptRegistrationTable({ data, loading }: PropsType) {
  const columns: ColumnsType<IWaitlistItem> = [
    {
      title: "Row",
      dataIndex: "row",
      width: 70,
      fixed: "left",
      render: (value) => {
        return value ?? "-";
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 120,
      fixed: "left",
      ellipsis: true,
      render: (value) => {
        return value ?? "-";
      },
    },
    {
      title: "NRIC",
      dataIndex: "nric",
      fixed: "left",
      width: 120,
      render: (value) => {
        return value ?? "-";
      },
    },
    {
      title: "Date of Birth",
      width: 120,
      dataIndex: "dob",
      render: (value) => {
        return value === null ? "-" : moment(value, DATE_FORMAT).format(DATE_FORMAT_VC_ADMIN);
      },
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      width: 120,
      render: (value) => {
        return value ?? "-";
      },
    },
    {
      title: "Unit Name",
      dataIndex: "unit_name",
      width: 80,
      render: (value) => {
        return value ?? "-";
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: 120,
      ellipsis: true,
      render: (value) => {
        return value ?? "-";
      },
    },
  ];

  return (
    <Table
      rowKey={(record) => record.row}
      columns={columns}
      dataSource={data}
      loading={loading}
      scroll={{ x: 800 }}
    />
  );
}

export default ApptRegistrationTable;
