/**
 * @author ming.leng
 */
import React, { useState } from "react";
import { Card, Button, Typography, Table, message, Space } from "antd";
import { useRequest } from "../../swr";
import DashboardContainer from "../../layout/Dashboard";
import CreateModal from "./Modal/Create";
import UpdateModal from "./Modal/Update";
import UnitAPI from "../../api/unit";
import { ColumnsType } from "antd/es/table/interface";
import { IUnit, IUnitAdmin, IUpdateUnitPayload } from "../../types/unit.model";
import { Modal } from "antd";
import { ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAuth } from "../../context/auth";

const { Title } = Typography;
const { confirm } = Modal;
export interface IAdmin extends IUnitAdmin {
  phone_number: string;
  role_id: number;
  role_name: string;
}

function UnitManagement() {
  const [visibleCreateModal, setVisibleCreateModal] = useState<boolean>(false);
  const [visibleUpdateModal, setVisibleUpdateModal] = useState<boolean>(false);
  const [selectedUnit, setSelectedUnit] = useState<IUnit>(null!);
  const [currentAdminList, setCurrentAdminList] = useState<IUnitAdmin[]>([]);
  const { accessToken } = useAuth();

  // fetch all units
  const { data, error, mutate, isLoading } = useRequest(
    "GET",
    "/vaccination/units?type_id=1"
  );
  const {
    data: unitAmdin,
    error: unitAdminError,
    mutate: unitAdminMutate,
  } = useRequest("GET", "/vaccination/units/admins/availables/1");

  const getUnitAdminList = async (token: string, id: number) => {
    try {
      const unitAdminListRes = await UnitAPI.getOneUnit(token, id);
      setCurrentAdminList(unitAdminListRes);
    } catch (error) {
      message.error(error?.message ?? "Get unit's admins failed");
    }
  };

  const unitsList: IUnit[] = data?.data ?? [];

  if (error?.message) {
    message.error(error?.message ?? "error");
  }
  if (unitAdminError?.message) {
    message.error(unitAdminError?.message ?? "error");
  }

  const createUnit = (token: string, payload: IUpdateUnitPayload) => {
    return UnitAPI.create(token, payload)
      .then(({ message: resMessage }) => {
        message.success(resMessage ?? "Create Unit successfully");
        setVisibleCreateModal(false);
        mutate();
        unitAdminMutate();
      })
      .catch((error) => {
        message.error(error?.message ?? "Create Unit failed");
        setVisibleCreateModal(false);
      });
  };

  const updateUnit = (
    token: string,
    payload: IUpdateUnitPayload,
    id: number
  ) => {
    return UnitAPI.update(token, payload, id)
      .then(({ message: resMessage }) => {
        message.success(resMessage ?? "Update Unit successfully");
        setVisibleUpdateModal(false);
        mutate();
        unitAdminMutate();
      })
      .catch((error) => {
        message.error(error?.message ?? "Update Unit failed");
        setVisibleUpdateModal(false);
      });
  };

  const handelDeleteUnit = (id: any) => {
    UnitAPI.delete(id, accessToken).then(res => {
      if(res.status) {
        message.success(res?.message||"Delete unit successfully");
      } else {
        message.error(res?.message);
      }
      mutate();
    });
  };

  const columns: ColumnsType<IUnit> = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text, record, index) => <>{index + 1}</>,
      width: 50,
    },
    {
      title: "Unit Name",
      dataIndex: "name",
      width: 300,
    },
    {
      title: "Total Admins",
      dataIndex: "total_admins",
      width: 180,
    },
    {
      title: "Action",
      width: 80,
      render: (index, record) => (
        <div key={index}>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setSelectedUnit(record);
                getUnitAdminList(accessToken, record?.id);
                setVisibleUpdateModal(true);
              }}
			  icon={<EditOutlined />}
            >
              
            </Button>
            <Button
              danger
              onClick={() => {
                setSelectedUnit(record);
                confirm({
                  icon: <ExclamationCircleOutlined />,
                  title: "Are you sure delete this Unit?",
                  okText: "Yes",
                  okType: "danger",
                  cancelText: "No",
                  onOk() {
                    handelDeleteUnit(record?.id);
                  },
                });
              }}
			  icon={<DeleteOutlined />}
            >
              
            </Button>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <>
      <DashboardContainer>
        <Card
          title={<Title level={3}>Unit Management</Title>}
          extra={
            <>
              <div></div>
              <Button
                className="btn-create btn-default"
                type="primary"
                onClick={() => setVisibleCreateModal(true)}
                style={{
                  marginLeft: "20px",
                }}
              >
                Create
              </Button>
            </>
          }
        >
          <Table
            className="table-custom"
            columns={columns}
            dataSource={unitsList}
            loading={isLoading}
            pagination={false}
            scroll={{ x: 800 }}
          />
        </Card>
        <CreateModal
          visible={visibleCreateModal}
          handleCancelModal={() => setVisibleCreateModal(false)}
          createUnit={createUnit}
          unitAmdin={unitAmdin?.data}
        />

        <UpdateModal
          visible={visibleUpdateModal}
          handleCancelModal={() => {
            setVisibleUpdateModal(false);
          }}
          updateUnit={updateUnit}
          unit={selectedUnit}
          unitAmdin={unitAmdin?.data}
          currentAdminList={currentAdminList}
        />
      </DashboardContainer>
    </>
  );
}

export default UnitManagement;
