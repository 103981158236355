import React from "react";
// router
import RootRouter from "./routes";
// Style
import "./App.less";
import "./theme/customize.less";
import { CookiesProvider, withCookies } from "react-cookie";

function App() {
  return (
    <CookiesProvider>
      <div className="App">
        <RootRouter />
      </div>
    </CookiesProvider>
  );
}

export default withCookies(App);