/* eslint-disable import/no-anonymous-default-export */
import {
  IUnit,
  IGetUnitsResponse,
  IUnitAdmin,
  IGetOneUnitResponse,
  IUpdateUnitPayload,
  IAvailableUnitAdmins,
  IGetAvailableUnitAdmins,
} from "../types/unit.model";
import { IBaseResponse, PayloadGetDepartmentList } from "../types/base.model";
const path = "/vaccination/units";

const unitAPIs = {
  async list(
    token: string,
    payload: PayloadGetDepartmentList
  ): Promise<{ data: IUnit[]; status: boolean }> {
    const queryParams = new URLSearchParams({});
    if (payload.type) {
      queryParams.append("type_id", payload.type.toString());
    }
    if (payload.site_id) {
      queryParams.append("site_id", payload.site_id.toString());
    }
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}?${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data, status, message }: IGetUnitsResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    // const unitsList = data as IUnit[];

    if (data.length === 0) {
      throw new Error("Can not get list of units!");
    }
    return { data, status };
  },
  async getOneUnit(token: string, id: number): Promise<IUnitAdmin[]> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data, status, message }: IGetOneUnitResponse = await res.json();
    const admins = data?.admins as IUnitAdmin[];
    if (status === false) {
      throw new Error(message);
    }
    return admins;
  },
  async getAvailableUnitAdmin(token: string): Promise<IAvailableUnitAdmins[]> {
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL + path + "/admins/availables",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data, status, message }: IGetAvailableUnitAdmins = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return data;
  },
  async create(
    token: string,
    payload: IUpdateUnitPayload
  ): Promise<{ status: boolean; message?: string }> {
    const res = await fetch(process.env.REACT_APP_SWIFTEST_API_URL + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
  async update(
    token: string,
    payload: IUpdateUnitPayload,
    id: number
  ): Promise<{ status: boolean; message?: string }> {
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL + path + `/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
  async delete(id: number, token: string): Promise<IBaseResponse> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    return { status, message };
  },
};

export default unitAPIs;
