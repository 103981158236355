/* eslint-disable import/no-anonymous-default-export */
import {
  IVaccine,
  IVaccineInventory,
  IVaccinesSummary,
  ICreateVaccineInventoryPayload,
  ICreateVaccinePayload,
  IGetVaccineResponse,
  // IGetVaccineInventoryResponse,
  IGetVaccinesSummaryResponse,
  IUpdateVaccineInventoryPayload,
  IUpdateVaccinePayload,
} from "../types/vaccine.model";
import { PaginationQueryType, IBaseResponse } from "../types/base.model";
import { removeEmptyUrlParams } from "../utils/helper";
import Fetching from ".";
const path = "/vaccination";

export default {
  async injectionStatus(token: string, query: any): Promise<any> {
    const searchParams = new URLSearchParams({
      vaccine_id: query.vaccine_id?.toString(),
      site_id: query.site_id?.toString(),
      date: query.date,
    });

    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL +
        path +
        `/summaries/injection_status?${removeEmptyUrlParams(searchParams)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data, status, message }: any = await res.json();
    if (status === false) {
      throw new Error(message);
    }

    return { data };
  },
  async listVaccines(
    token: string,
    pagination: PaginationQueryType
  ): Promise<{ vaccines: IVaccine[]; total: number }> {
    const searchParams = new URLSearchParams({
      items_per_page: pagination.items_per_page.toString(),
      page: pagination.page.toString(),
    });

    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL +
        path +
        `/vaccine-types?${searchParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data, status, message, total }: IGetVaccineResponse =
      await res.json();
    if (status === false) {
      throw new Error(message);
    }
    const vaccines = data as IVaccine[];

    return { vaccines, total };
  },
  async listVaccineInventory(
    token: string,
    pagination: PaginationQueryType,
    query?: any
  ): Promise<{ inventory: IVaccineInventory[]; total: number }> {
    const searchParams = new URLSearchParams({
      items_per_page: pagination.items_per_page.toString(),
      page: pagination.page.toString(),
      ...query,
    });
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL +
        path +
        `/vaccines?${searchParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data, status, message }: any = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    const inventory = data && data?.data;
    const total = data && data?.total;
    return { inventory, total };
  },
  async getVaccineSummary(token: string): Promise<IVaccinesSummary> {
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL + path + "/vaccines/summary",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data, status }: IGetVaccinesSummaryResponse = await res.json();
    if (status === false) {
      throw new Error("Can not get vaccines summary");
    }
    const vaccineSummary = data as IVaccinesSummary;
    return vaccineSummary;
  },
  async createVaccineInventory(
    token: string,
    payload: ICreateVaccineInventoryPayload
  ): Promise<IBaseResponse> {
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL + path + "/vaccines",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
  async updateVaccineInventory(
    token: string,
    payload: IUpdateVaccineInventoryPayload,
    id: number
  ): Promise<IBaseResponse> {
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL + path + `/vaccines/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    return { status, message };
  },
  async createVaccine(
    token: string,
    payload: ICreateVaccinePayload
  ): Promise<IBaseResponse> {
    const { status, message } = await Fetching(path + "/vaccine-types", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
  async updateVaccine(
    token: string,
    payload: IUpdateVaccinePayload,
    id: number
  ): Promise<IBaseResponse> {
    const { status, message } = await Fetching(path + `/vaccine-types/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
  async options(token: string, pagination: PaginationQueryType): Promise<any> {
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL + path + `/vaccines/options`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data, status, message, total }: IGetVaccineResponse =
      await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { data };
  },

  async delete(id: number, token: string): Promise<IBaseResponse> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/vaccines/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    return { status, message };
  },

  async deleteVaccine(token: string, id: number): Promise<IBaseResponse> {
    const { status, message } = await Fetching(path + `/vaccine-types/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
  async allergiesOptions(token: string): Promise<any> {
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL +
        path +
        `/personnels/options/allergies`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.json() || [];
  },
};
