import React from "react";
import useCheckMobileScreen from "../../hook/useCheckMobileScreen";
import "./style.less";

function Widget(props: any) {
  const { children, title = "", img = "", number, classWrap = "" } = props;
  const isMobile = useCheckMobileScreen();
  
  return (
    <div className={`widget-wrap ${classWrap}`}>
      <div className="widget__body">
        {img && img !== ""&&!isMobile ? (
          <>
            <div className="widget__left">
              {img !== "" && <img alt="widget" src={img} />}
            </div>
            <div className="widget__right">
              <div className="widget__heade">
                <div className="widget__title">{title}</div>
                {number && (
                  <div className="widget__button">Total: {number}</div>
                )}
              </div>
              <div className="widget__right--body">{children}</div>
            </div>
          </>
        ) : (
          <>
            <div className="widget__right" style={{ width: "100%" }}>
              <div className="widget__heade">
                <div className="widget__title">{title}</div>
                {number && (
                  <div className="widget__button">Total: {number}</div>
                )}
              </div>
              <div className="widget__right--body">{children}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Widget;
