import React from "react";
import { Row, Col, Typography, Button } from "antd";
import { useHistory } from "react-router-dom";
// context
import { useAuth } from "../../context/auth";
// imgae
import brackgroud from "../../assets/login_bakcground.png";
import { useMsal } from "@azure/msal-react";
import { AuthenticationResult } from "@azure/msal-browser";
import { deleteCookie, setCookie } from "../../utils/helper";
import jwt_decode from "jwt-decode";

const { Title, Text } = Typography;

function Login() {
  const history = useHistory();
  const { instance } = useMsal();
  const { setAccessToken } = useAuth();

  const handleLogin = () => {
    deleteCookie('idToken');
    localStorage.clear();
	
	instance.loginPopup().then((res: AuthenticationResult) => {
	  const jwtdecoded: any = jwt_decode(res?.idToken);
	  setCookie("idToken", res?.idToken, new Date(jwtdecoded?.exp * 1000));
	  setAccessToken(res?.idToken);
	  history.replace({ pathname: "/" });
	})
  };

  const LoginButton = () => (
    <Button type="primary" onClick={handleLogin} style={{ width: "150px" }}>
      Login
    </Button>
  );

  return (
    <Row className="login-wrapper">
      <Col className="login-wrapper_left">
        <div className="login-wrapper_left_content">
          <div className="login-wrapper_left_content_logo">
            <img
              alt="nav-logo"
              src="/logo192.png"
              style={{
                width: "100%",
              }}
            />
          </div>
          <div className="login-wrapper_left_content_title">
            <Title level={1} className="login-wrapper_left_content_title-TITLE">
              WELCOME TO SWIFTVAX!
            </Title>
            <Text className="login-wrapper_left_content_title-TEXT">
              Made with ❤️ by the SWIFT Office
            </Text>
            <div className="login-wrapper_left_content_title-BUTTON">
              <LoginButton />
            </div>
          </div>
          <div className="login-wrapper_left_content_background">
            <img alt="backgroud" src={brackgroud} />
          </div>
        </div>
      </Col>
      <Col className="login-wrapper_right">
        <div className="login-wrapper_right_button">
          <LoginButton />
        </div>
      </Col>
    </Row>
  );
}

export default Login;
