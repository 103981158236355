/**
 * @author ming.leng
 */
import React, { useState } from "react";
import { Modal, Button, Form, Input } from "antd";
import { useAuth } from "../../../../context/auth";
import { ICreateVaccinePayload } from "../../../../types/vaccine.model";
import { DosesRendering, getDurations } from "../UpdateVaccine";

const tailFormItemLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export type PropsType = {
  visibleAddVaccineModal: boolean;
  handleCancelModal: () => void;
  createVaccine: (token: string, payload: ICreateVaccinePayload) => void;
};

function CreateVaccineModal({
  visibleAddVaccineModal,
  handleCancelModal,
  createVaccine,
}: PropsType) {
  const { accessToken } = useAuth();
  const [form] = Form.useForm();
  const [doses, setDoses] = useState(0);
  const onCancel = () => {
    form.resetFields();
    setDoses(0)
    handleCancelModal();
  };
  const onFinish = (fieldsValue: any) => {
    const durations = getDurations(fieldsValue, +fieldsValue?.total_doses)
    
    const payload = {
      durations,
      name: fieldsValue.vaccine_name,
      vaccine_brand: fieldsValue.vaccine_brand,
      total_doses: fieldsValue.total_doses,
    };
    createVaccine(accessToken, payload);
    form.resetFields();
  };

  return (
    <Modal
      title="Create Vaccine Type"
      visible={visibleAddVaccineModal}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Form
        form={form}
        layout="horizontal"
        name="create-vaccine-type"
        onFinish={onFinish}
        labelAlign="left"
        labelWrap
        colon={false}
        labelCol={{
          span: 8,
          flex: "180px",
        }}
      >
        <Form.Item
          label="Name"
          name="vaccine_name"
          rules={[
            { required: true, message: "Please enter name of the vaccine" },
            { 
              pattern: /^[\w\d\s]*$/g, 
              message: "Invalid value!",
              transform(value) {
                return value.trim();
              }, 
            }
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Brand"
          name="vaccine_brand"
          rules={[
            { required: true, message: "Please enter brand of the vaccine" },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Total dose(s) needed"
          name="total_doses"
          rules={[
            { required: true, message: "Please enter total dose(s) needed" },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject();
                }
                if (isNaN(value)) {
                  return Promise.reject("Minimum doses has to be a number.");
                }
                if (+value < 1) {
                  return Promise.reject("Minimum doses can't be less than 1");
                }
                if (+value > 5) {
                  return Promise.reject("Minimum doses can't be more than 5");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            type="number"
            min={1}
            max={5}
            onChange={(e) =>(+e.target.value >= 1 && +e.target.value <= 5) ? setDoses(+e.target.value) :setDoses(0)}
          />
        </Form.Item>

        {doses ? <DosesRendering doses={+doses} /> : <div />}
        <Form.Item {...tailFormItemLayout}>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateVaccineModal;
