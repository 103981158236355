import React from "react";
import { Button, Table, Space } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table/interface";
import { TablePaginationType } from "../../../types/table.model";
import "./style.less";
import { ICommand } from "../../../types/command.model";
import { ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

export type PropsType = {
  data: ICommand[];
  loading: boolean;
  pagination: TablePaginationType;
  switchPage: Function;
  onEditCommand: Function;
  onDeleteCommand: Function;
};

function CommandManagementTable({
  data,
  loading,
  pagination,
  switchPage,
  onEditCommand,
  onDeleteCommand
}: PropsType) {
  const columns: ColumnsType<any> = [
    { title: "ID", dataIndex: "no", width: 50 },
    { title: "Name", dataIndex: "command_name" },
    {
      title: "Total Admins",
      dataIndex: "command_admins",
      render: (value) => {
        const totalAdmins = value ? value?.length.toString() : "-";
        return `${totalAdmins}`;
      },
    },
    { title: "Total Units", dataIndex: "total_unit" },
    {
      title: "Action",
	  width: 100,
      render: (value) => {
        return (
          <>
		  <Space>
            <Button
              type="primary"
              onClick={() => {
                onEditCommand(value);
              }}
			  icon={<EditOutlined />}
            >

            </Button>
            <Button
              size={"middle"}
              danger
              onClick={() => {
                onDeleteCommand(value.command_id);
              }}
			  icon={<DeleteOutlined />}
            >

            </Button>
			</Space>
          </>
        );
      },
    },
  ];

  const onChangePage = (pagination: TablePaginationConfig): void => {
    switchPage(pagination);
  };

  return (
    <>
      <Table
        rowKey={(record) => record.command_id}
        className="personnel-table"
        columns={columns}
        dataSource={data}
        loading={loading}
        onChange={onChangePage}
        pagination={pagination}
        scroll={{ x: 800 }}
      />
    </>
  );
}

export default CommandManagementTable;
