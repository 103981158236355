import React, { useEffect, useState } from "react";
import { DatePicker, Cascader, message, Table, Row, Col, Select } from "antd";
import moment, { Moment } from "moment";
import { ColumnsType } from "antd/es/table/interface";
import { DATE_FORMAT, PAGE_NAME, TIME_FORMAT } from "../../../constants";
import { useAuth } from "../../../context/auth";
import SummaryAPI from "../../../api/summary";
import { useRequest } from "../../../swr";
import { IUnit } from "../../../types/unit.model";
import { ITimeslotInfo } from "../../../types/summary.model";
import GridInput from "../../GridInput";

function TimeSlotOveriew() {
  const { accessToken, roles } = useAuth();
  const role = useAuth()?.roles;
  const [slotData, setSlotData] = useState<ITimeslotInfo[]>(null!);
  const [loading, setLoading] = useState<boolean>(false);
  const { data: sitesData, error } = useRequest("GET", "/vaccination/sites");
  const sites: IUnit[] = sitesData?.data ?? [];
  const [siteId, setSiteId] = useState<number>(role?.data?.assignedSite || sitesData?.data?.[0]?.id)
  const DEFAULT_FILTER = {
    date: null,
    site_id: null,
  };

  useEffect(() => {
    setSiteId(role?.data?.assignedSite || sitesData?.data?.[0]?.id)
  }, [role, sitesData]);
  
  const [filter, setFilter] = useState<{ [key: string]: any }>(DEFAULT_FILTER);

  if (error?.message) {
    message.error(error?.message ?? "error");
  }

  const fetchTimeSlotOverview = (
    token: string,
    date: Moment | null,
    site_id: number | null
  ) => {
    const formatDate = date ? date : "";
    setLoading(true);
    return SummaryAPI.listScheduledTimeslots(
      token,
      formatDate,
      site_id
    )
      .then(({ data }) => {
        setSlotData(data[0]?.timeslots);
        setLoading(false);
      })
      .catch((err) => {
        //message.error("Can't get appointment list");
        setSlotData(null!);
        setLoading(false);
      });
  };

  const handleDatePickerChange = (value: any) => {
    setFilter({
      ...filter,
      date: value ? moment(value)?.format(DATE_FORMAT) : null,
    });
  };

  const handleMedicalCentreChange = (value: number) => {
    setFilter({ ...filter, site_id: value ? value : null });
  };

  useEffect(() => {
    setFilter({
      ...filter,
      site_id: role?.data?.assignedSite ? role?.data?.assignedSite : null,
      date: moment().format(DATE_FORMAT)
    });
  }, [role]);


	  useEffect(() => {
		const {site_id, date} = filter;
		if(date != null) {
			fetchTimeSlotOverview(accessToken, date, site_id);
		}
	  }, [filter])


  const columns: ColumnsType<ITimeslotInfo> = [
    {
      title: "Time",
	  width: "50px",
      render: (value) => {
        return `${moment(value?.start_time, TIME_FORMAT).format(TIME_FORMAT)} -
            ${moment(value?.end_time, TIME_FORMAT).format(TIME_FORMAT)}`;
      },
    },
	{
      title: "Title",
	  width: "50px",
      render: (value) => {
        return `${value?.title ? `(${value.title})` : ""}`;
      },
    },
    {
      title: "Total Time Slot",
	  width: "50px",
      render: (value) => {
        return `${value?.total?.slots ?? 0}`;
      },
    },
	{
      title: "Requested",
	  width: "30px",
      render: (value) => {
        return `${value?.total?.requested ?? 0}`;
      },
    },
    {
      title: "Scheduled",
	  width: "30px",
      render: (value) => {
        return `${value?.total?.scheduled ?? 0}`;
      },
    },
	{
      title: "Vaccinated",
	  width: "30px",
      render: (value) => {
        return `${value?.total?.completed ?? 0}`;
      },
    },
  ];

  return (
    <>
      <Row gutter={[10,10]}>
        <Col className="gutter-row" lg={{span: 8}} span={24}>
          <DatePicker style={{ width: '100%', marginRight: 15 }}
            allowClear={false} onChange={handleDatePickerChange} defaultValue={filter?.date ? filter?.date : moment()} placeholder="Please select date"
          />
        </Col>
        <Col className="gutter-row" lg={{span: 8}} span={24}>
          <Select
            value={siteId}
            disabled={!!role?.data?.assignedSite}
            style={{ width: "100%" }}
            onChange={handleMedicalCentreChange}
            placeholder="Please select medical centre"
          >
            {sites &&
              sites.map((site: any) => (
                <Select.Option key={site?.id} value={site?.id}>
                  {site?.name}
                </Select.Option>
              ))}
          </Select>
        </Col>
      </Row>
      <br />
      <Table
        rowKey={(value) => `${value.start_time} - ${value.end_time}`}
        columns={columns}
        dataSource={slotData}
        loading={loading}
        pagination={false}
        style={{ textAlign: "center" }}
        scroll={{ x: 800 }}
      />
    </>
  );
}

export default TimeSlotOveriew;
