
import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AAD_CLIENT_ID  || '0ab1f434-c192-400a-af3a-ca5b200e909b',
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AAD_TENANT_ID || '482e292f-548d-4e7d-b44c-1cdb1a86cb49'}`,
        redirectUri: process.env.REACT_APP_ADD_REDIRECT_URL || '/',
        postLogoutRedirectUri: '/',
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                console.log(message);
            },
        }
    },
    cache: {
        cacheLocation: 'localStorage',
		storeAuthStateInCookie: false
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ['profile', 'openid', 'email', 'Directory.Read.All', 'User.Read']
};
