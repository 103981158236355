import { Button, Cascader, Col, Form, Row, Select, Typography } from "antd";
import React, { memo, useState, Fragment, useMemo, useContext } from "react";
import { PMContext } from "../../../pages/PersonnelManagement/PersonnelManagementContext";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";

function AssignedForm({ form, getTotalRow }: any) {
  const [total, setTotal] = useState<number[]>([1]);
  const [dosesRow, setDosesRow] = useState<any>();
  const [selectedVaccinesRow, setSelectedVaccinesRow] = useState<any>({});
  const context = useContext(PMContext);

  const onAddRow = () => {
    if (total.length < 10) {
      const item = (total[total.length - 1] || 0) + 1;
      setTotal([...total, item]);
    }
  };

  const onRemoveRow = (row: number) => {
    const arr = total;
    const newSelectedVaccinesRow = selectedVaccinesRow;
    if (total.length === 1) return;
    const removed = total.indexOf(row);
    if (removed > -1) {
      arr.splice(removed, 1);
    }
    delete newSelectedVaccinesRow?.[row];
    setSelectedVaccinesRow({ ...newSelectedVaccinesRow });
    form.setFieldValue(`vaccine-${row}`, "");
    form.setFieldValue(`doses-${row}`, "");
    setTotal([...arr]);
  };

  const onVaccineOptionsChange = (
    value: [string, number],
    row: number
  ): void => {
    form.setFieldValue(`doses-${row}`, "");
    const vaccine = context.vaccines.filter((v: any) => v?.id === value[1]);
    setSelectedVaccinesRow({ ...selectedVaccinesRow, [row]: vaccine[0] });
    if (vaccine.length) {
      const doses = new Array(vaccine[0].total_doses)
        .fill("")
        .map((x, i) => ({ label: (i + 1).toString(), value: i + 1 }));
      setDosesRow({ ...dosesRow, [row]: doses });
    }
  };

  const selected = useMemo(() => {
    let newVaccineOptions = context.vaccineOptions;
    const arr = selectedVaccinesRow ? Object?.values(selectedVaccinesRow) : [];
    arr.forEach((a: any) => {
      newVaccineOptions = newVaccineOptions.map((nvo: any) =>
        nvo.label === a.name
          ? {
              ...nvo,
              children: nvo.children.map((nvoc: any) =>
                nvoc.label === a.brand
                  ? {
                      ...nvoc,
                      disabled: nvoc.label === a.brand,
                    }
                  : nvoc
              ),
            }
          : nvo
      );
    });
    return newVaccineOptions;
  }, [selectedVaccinesRow, context.vaccineOptions]);

  useMemo(() => {
    getTotalRow(total);
  }, [total]);

  return (
    <>
      <Row gutter={[5, 5]}>
        <Col sm={10}>
          <Typography.Text strong>Vaccine</Typography.Text>
        </Col>
        <Col sm={10}>
          <Typography.Text strong>Dose No.</Typography.Text>
        </Col>
        {total.map((t: number) => {
          return (
            <Fragment key={t}>
              <Col sm={10}>
                <Form.Item label="" name={`vaccine-${t}`}>
                  <Cascader
                    style={{ width: "100%" }}
                    allowClear={false}
                    options={selected}
                    onChange={(e: [string, number]) =>
                      onVaccineOptionsChange(e, t)
                    }
                    placeholder="Please select vaccine"
                  />
                </Form.Item>
              </Col>
              <Col sm={10}>
                <Form.Item label="" name={`doses-${t}`}>
                  <Select
                    disabled={!dosesRow?.[t]?.length}
                    options={dosesRow?.[t]}
                  />
                </Form.Item>
              </Col>
              <Col sm={4}>
                {t === total[total.length - 1] && (
                  <Fragment>
                    <Button
                      icon={<PlusCircleFilled />}
                      onClick={onAddRow}
                      size="large"
					  shape="circle"
                      type="link"
                    />

                    <Button disabled size="small" type="link">
                      /
                    </Button>
                  </Fragment>
                )}

                <Button
                  onClick={() => onRemoveRow(t)}
                  size="large"
				  shape="circle"
                  type="link"
                  danger
                  icon={<MinusCircleFilled />}
                />
              </Col>
            </Fragment>
          );
        })}
      </Row>
    </>
  );
}

export default memo(AssignedForm as any);
