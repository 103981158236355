import React, { useEffect } from "react";
import { Modal, Form, message, Button, Row, Col, Select } from "antd";
import AppointmentsAPI from "../../../api/appointments";
import { useAuth } from "../../../context/auth";
import { InjectionStatusType, PersonnelType } from "../../../types/base.model";
// import { IPersonnelItem } from "../../../types/personnel.model";

const { Option } = Select;

type IAppointmentStatus = {
  id: number;
  status: string;
};

type IPersonnel = {
  created_at: string;
  dob: string;
  id: number;
  name: string;
  personnel_type: PersonnelType;
  phone_number: string;
  remarks: string;
  status: InjectionStatusType;
  unit: string;
  updated_at: string;
};

type EditStatusModalProps = {
  visible: boolean;
  onClose: () => void;
  injectionItem: IPersonnel;
  statusList: IAppointmentStatus[];
  mutate: Function;
};

const EditStatusModal = ({
  visible,
  onClose,
  injectionItem,
  statusList,
  mutate,
}: EditStatusModalProps) => {
  const [form] = Form.useForm();
  const { accessToken } = useAuth();

  const onCancel = () => {
    onClose();
  };

  const editAppointmentStatus = (token: string, payload: any, id: any) => {
    AppointmentsAPI.updateAppointmentStatus(token, payload, id)
      .then(() => {
        mutate();
        message.success("Edit appointments status successfully!");
      })
      .catch((err) => {
        message.error(err?.message || "Edit appointments status failed!");
      });
  };

  const onFinish = (fieldsValue: any) => {
    const index = statusList && statusList.findIndex(status => status.id === fieldsValue.appointmentStatus);
    const payload = {
      status_id: statusList[index]?.id || -1
    };
    editAppointmentStatus(accessToken, payload, injectionItem.id);
    onCancel();
  };

  //useEffect(() => {
    //form.setFieldValue("appointmentStatus", injectionItem?.status)
  //}, [injectionItem?.status, form, visible])

  return (
    <Modal
      title="Edit appointment status"
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        name="editStatus"
      >
        <Row gutter={24} style={{ alignItems: "center" }}>
          <Col span={24} style={{ paddingTop: "10px" }}>
            <Form.Item name="appointmentStatus" label="Status">
              <Select
                placeholder="Please select status"
              >
                {statusList &&
                  // eslint-disable-next-line array-callback-return
                  statusList.map((value: IAppointmentStatus) => {
                    if (!["Not Scheduled", "Completed", "Requested", "Scheduled"].includes(value.status)) {
                      return (
                        <Option value={value.id} key={value.id}>
                          {value.status}
                        </Option>
                      );
                    }
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ margin: "0 8px" }}
            >
              Submit
            </Button>
            <Button key="back" className="btn-default" onClick={onCancel}>
              Cancel
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditStatusModal;
