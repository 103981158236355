/**
 * @author ming.leng
 */
import React, { useState } from "react";
import { Card, Button, Typography, Table, message, Modal, Space } from "antd";
import { useRequest } from "../../swr";
import DashboardContainer from "../../layout/Dashboard";
import CreateModal from "./Modal/Create";
import UpdateModal from "./Modal/Update";
import UnitAPI from "../../api/unit";
import { ColumnsType } from "antd/es/table/interface";
import { useAuth } from "../../context/auth";
import {
  IUnit,
  IUnitAdmin,
  IUpdateUnitPayload,
} from "../../types/unit.model";
import { ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { PAGE_NAME } from "../../constants";
const { confirm } = Modal;
const { Title } = Typography;

export interface IAdmin extends IUnitAdmin {
  phone_number: string;
  role_id: number;
  role_name: string;
}

function DepartmentManagement() {
  const [visibleCreateModal, setVisibleCreateModal] = useState<boolean>(false);
  const [visibleUpdateModal, setVisibleUpdateModal] = useState<boolean>(false);
  const [selectedUnit, setSelectedUnit] = useState<IUnit>(null!);
  const [currentAdminList, setCurrentAdminList] = useState<IUnitAdmin[]>([]);
  const { accessToken } = useAuth();

  // fetch all units
  const { data, error, mutate, isLoading } = useRequest(
    "GET",
    "/vaccination/units?type_id=2"
  );
  const unitsList: IUnit[] = data?.data?.map((item: any, index: number) => ({ ...item, no: index + 1 })) ?? [];
  if (error?.message) {
    message.error(error?.message ?? "error");
  }

  // fetch all available Unit Admin
  const {
    data: availableUnitAdminList,
    error: fetchAvailableUnitAdminErr,
    mutate: mutateAvailableUnitAdminList,
  } = useRequest("GET", "/vaccination/units/admins/availables/2");

  const unitAdminsList: IAdmin[] = availableUnitAdminList?.data ?? [];
  if (fetchAvailableUnitAdminErr?.message) {
    message.error(fetchAvailableUnitAdminErr?.message ?? "error");
  }

  const getOneUnitAdminList = (token: string, id: number) => {
    UnitAPI.getOneUnit(token, id)
      .then((data) => {
        setCurrentAdminList(data);
        return true;
      })
      .catch((error) => {
        message.error(error.message ?? "Get unit's admins failed");
        return false;
      });
  };

  const createUnit = (token: string, payload: IUpdateUnitPayload) => {
    // Department should have type_id as 2
    return UnitAPI.create(token, { ...payload, type_id: 2 })
      .then(({ message: resMessage }) => {
        message.success(resMessage ?? "Create Department successfully");
        mutateAvailableUnitAdminList();
        setVisibleCreateModal(false);
        mutate();
      })
      .catch((error) => {
        message.error(error?.message ?? "Create Department failed");
        setVisibleCreateModal(false);
      });
  };

  const updateUnit = (
    token: string,
    payload: IUpdateUnitPayload,
    id: number
  ) => {
    return UnitAPI.update(token, { ...payload, type_id: 2 }, id)
      .then(({ message: resMessage }) => {
        message.success(resMessage ?? "Update Department successfully");
        mutateAvailableUnitAdminList();
        setVisibleUpdateModal(false);
        mutate();
      })
      .catch((error) => {
        message.error(error?.message ?? "Update Department failed");
        setVisibleUpdateModal(false);
      });
  };

  const handelDelelte = (id: number) => {
    UnitAPI.delete(id, accessToken).then((res) => {
      if(res.status) {
        message.success(res.message||"Delete department successfully");
      } else {
        message.error(res.message||"Delete department unsuccessfully");
      }
      mutate();
    });
  }

  const showDeleteConfirm = (id: number) => {
    confirm({
      title: `Are you sure delete this ${PAGE_NAME.DEPARTMENT.LABEL_1}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handelDelelte(id);
      },
    });
  };

  const columns: ColumnsType<IUnit> = [
    {
      title: "ID",
      dataIndex: "no",
      width: 80,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Total Admins",
      dataIndex: "total_admins",
    },
    {
      title: "Action",
      width: 100,
      render: (index, record, value) => (
        <>
		<Space>
          <Button
            type="primary"
            onClick={() => {
              getOneUnitAdminList(accessToken, record.id);
              setSelectedUnit(record);
              setVisibleUpdateModal(true);
            }}
			icon={<EditOutlined />}
          >
          </Button>
          <Button
            size={"middle"}
            danger
            onClick={() => {
              showDeleteConfirm(record?.id ?? null);
            }}
			icon={<DeleteOutlined />}
          >
          </Button>
		  </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <DashboardContainer>
        <Card
          title={<Title level={3}>{PAGE_NAME.DEPARTMENT.LABEL}</Title>}
          extra={
            <>
              <div></div>
              <Button
                className="btn-create btn-default"
                type="primary"
                onClick={() => setVisibleCreateModal(true)}
                style={{
                  marginLeft: "20px",
                }}
              >
                Create
              </Button>
            </>
          }
        >
          <Table
            className="table-custom"
            columns={columns}
            dataSource={unitsList}
            loading={isLoading}
            pagination={false}
            scroll={{ x: 800 }}
          />
        </Card>
        <CreateModal
          visible={visibleCreateModal}
          handleCancelModal={() => setVisibleCreateModal(false)}
          createUnit={createUnit}
          unitAdminsList={unitAdminsList}
        />
        <UpdateModal
          visible={visibleUpdateModal}
          handleCancelModal={() => {
            setVisibleUpdateModal(false);
            setCurrentAdminList([]);
          }}
          updateUnit={updateUnit}
          unitAdminsList={unitAdminsList}
          unit={selectedUnit}
          currentAdmins={currentAdminList}
        />
      </DashboardContainer>
    </>
  );
}

export default DepartmentManagement;
