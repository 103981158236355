import { IPublicClientApplication } from "@azure/msal-browser";
import { createContext, useContext } from "react";

type AuthContextProps = {
    instance: IPublicClientApplication,
    accessToken: string;
    roles: any;
    isLoading: boolean;
    setAccessToken: (access_token: string) => void
}

export const authContext = createContext({} as AuthContextProps);

export function useAuth() {
    return useContext(authContext);
}