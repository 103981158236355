import React from "react";
import { Table, Button, Space } from "antd";
import moment from "moment";
import { ColumnsType, TablePaginationConfig } from "antd/es/table/interface";
import { DATE_FORMAT_VC_ADMIN } from "../../../constants";

import { TablePaginationType } from "../../../types/table.model";
import { IVaccineInventory } from "../../../types/vaccine.model";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

export type PropsType = {
  data: IVaccineInventory[];
  loading: boolean;
  pagination: TablePaginationType;
  switchPage: Function;
  onUpdateInventory: (detail: IVaccineInventory) => void;
  onDeleteInventory: (detail: IVaccineInventory) => void;
};

function VaccineInventory({
  data,
  loading,
  pagination,
  switchPage,
  onUpdateInventory,
  onDeleteInventory
}: PropsType) {
  const onChangePage = (pagination: TablePaginationConfig): void => {
    switchPage(pagination);
  };

  const columns: ColumnsType<IVaccineInventory> = [
    {
      dataIndex: "no",
      title: "ID",
      width: 50,
      fixed: "left",
      render: (text, record, index) => (index + 1) + (pagination?.pageSize * (pagination.current - 1)),
    },
    {
      dataIndex: "site_name",
      title: "Medical Centre",
      width: 150,
    },
    {
      dataIndex: "arrival_date",
      title: "Arrival Date",
      width: 120,
      render: (value) => {
        return moment(value).format(DATE_FORMAT_VC_ADMIN);
      },
    },
    {
      dataIndex: "expiry_date",
      title: "Expiry Date",
      width: 120,
      render: (value) => {
        return moment(value).format(DATE_FORMAT_VC_ADMIN);
      },
    },
    {
      dataIndex: "name",
      title: "Vaccine Name",
      width: 150,
    },
    {
      dataIndex: "brand",
      title: "Vaccine Brand",
      width: 150,
    },
    {
      dataIndex: "cost",
      title: "Vaccine Cost",
      width: 100,
    },
    {
      dataIndex: "batch_number",
      title: "Batch No.",
      width: 100,
    },
    {
      dataIndex: "lot_no",
      title: "Lot No.",
      width: 100,
    },
    {
      dataIndex: "quantity",
      title: "Total Doses",
      width: 100,
    },
    {
      dataIndex: "remain",
      title: "Available Doses",
      width: 100,
    },
    {
      dataIndex: "book",
      title: "Used Doses",
      width: 100,
    },
    {
      dataIndex: "expired",
      title: "Expired Doses",
      width: 100,
    },
    {
      title: "Action",
      width: 100,
      render: (index, record, value) => (
        <>
		 <Space>
          <Button
            type="primary"
            onClick={() => onUpdateInventory(record)}
			icon={<EditOutlined />}
          >
            
          </Button>
          <Button
              size={"middle"}
              danger
              onClick={() => onDeleteInventory(record)}
			  icon={<DeleteOutlined />}
            >
              
            </Button>
		</Space>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey={(record) => record?.id}
        loading={loading}
        columns={columns}
        pagination={pagination}
        onChange={onChangePage}
        dataSource={data}
        scroll={{ x: 1100 }}
      />
    </>
  );
}

export default VaccineInventory;
