/**
 * @author ming.leng
 */
import React, { useState,useEffect } from "react";
import { Modal, Button, Form, Input, DatePicker, Select } from "antd";
import { useAuth } from "../../../../context/auth";
import {
  ICreateVaccineInventoryPayload,
  IVaccine,
  IVaccineBrand,
  IVaccineOption,
} from "../../../../types/vaccine.model";
import { ISite } from "../../../../types/site.model";
import moment from 'moment';
const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 14,
  },
};

const tailFormItemLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export type PropsType = {
  visibleAddInvtModal: boolean;
  handleCancelModal: () => void;
  createVaccineInventory: (
    token: string,
    payload: ICreateVaccineInventoryPayload
  ) => void;
  vaccineList: IVaccineOption[];
  sitesList: ISite[];
  defaultSelectedSite:number|null
};

function CreateVaccineInventoryModal({
  visibleAddInvtModal,
  handleCancelModal,
  createVaccineInventory,
  vaccineList,
  sitesList,
  defaultSelectedSite
}: PropsType) {
  const { accessToken, roles } = useAuth();
  const [form] = Form.useForm();
  const onCancel = () => {
    form.resetFields();
    setVaccineBrandList([]);
    handleCancelModal();
  };

  const [vaccineBrandList, setVaccineBrandList] = useState<IVaccineBrand[]>([]);

  const onFinish = (fieldsValue: any) => {
    const payload = {
      ...fieldsValue,
      expiry_date: fieldsValue["expiry_date"].format("YYYY-MM-DD"),
      arrival_date: fieldsValue["arrival_date"].format("YYYY-MM-DD"),
    };
    createVaccineInventory(accessToken, payload);
    form.resetFields();
  };

  const handleChangeVaccineName = (selectedVaccineName: string) => {
    const selectedVaccine = vaccineList.find((v: IVaccineOption) => v.name.toLowerCase().trim() === selectedVaccineName.toLowerCase().trim()) || null;
    if (selectedVaccine) {
      setVaccineBrandList(selectedVaccine.brands);
    } else {
      setVaccineBrandList([]);
    }
    form.setFieldValue('vaccine_type_id', [])
  }

  useEffect(() => {
    if(defaultSelectedSite){
      form.setFieldValue('site_id', defaultSelectedSite)
    }
  }, [defaultSelectedSite,visibleAddInvtModal])

  return (
    <Modal
      title="Create Vaccine Inventory"
      visible={visibleAddInvtModal}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        {...formItemLayout}
        form={form}
        layout="horizontal"
        name="create-new-vaccine"
        onFinish={onFinish}
      >
        <Form.Item
          label="Medical Centre"
          name="site_id"
          rules={[
            { required: true, message: "Please select medical centre" }
          ]}
        >
          <Select
            style={{ width: "100%" }}
            defaultValue={roles.data.assignedSite}
            disabled={!!roles.data.assignedSite}
          >
            {sitesList?.map((site: ISite) => {
              return (
                <Select.Option key={site.id} value={site.id}>
                  {site.name}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Arrival Date"
          name="arrival_date"
          rules={[{ type: 'object' as const, required: true, message: 'Please select arrival date' }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Expiry Date"
          name="expiry_date"
          rules={[{ type: "object" as const, required: true, message: "Please select expiry date" }]}
        >
          <DatePicker disabledDate={(current) => current.isBefore(moment())} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="name"
          label="Vaccine Name"
          rules={[
            { required: true, message: "Please select vaccine name" },
          ]}
        >
          <Select style={{ width: "100%" }} onChange={handleChangeVaccineName}>
            {vaccineList?.map((vaccine: IVaccineOption, index: number) => {
              return (
                <Select.Option key={index} value={vaccine.name}>
                  {vaccine.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="vaccine_type_id"
          label="Vaccine Brand"
          rules={[
            { required: true, message: "Please select vaccine brand" },
          ]}
        >
          <Select style={{ width: "100%" }}>
            {vaccineBrandList?.map((vaccine: IVaccineBrand, index: number) => {
              return (
                <Select.Option key={index} value={vaccine.id}>
                  {vaccine.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Vaccine Quantity"
          name="quantity"
          rules={[{ required: true, message: "Please enter vaccine quantity" }]}
        >
          <Input type="number" min={0} />
        </Form.Item>
        <Form.Item
          label="Vaccine Cost"
          name="cost"
          rules={[{ required: true, message: "Please enter vaccine cost" }]}
        >
          <Input type="number" min={0.00} step={0.01} />
        </Form.Item>
        <Form.Item
          label="Batch No."
          name="batch_number"
          rules={[
            { required: true, message: "Please enter Vaccine Batch No.!" },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Lot No."
          name="lot_no"
          rules={[
            { required: true, message: "Please enter vaccine lot no." },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateVaccineInventoryModal;
