import React from "react";
import { Drawer, Layout, Menu, Space } from "antd";
import { Link, useHistory } from "react-router-dom";
import useCheckMobileScreen from "../../../hook/useCheckMobileScreen";

const { Sider } = Layout;

export default function SiderLayout({ navigation = [{}],isOpenSideBar,setIsOpenSideBar }) {
  const history = useHistory();
  const isMobile = useCheckMobileScreen();

  const onSelect = (e: any) => {
    history.push(e.key);
  };
  const onCloseSideBar = () => {
    setIsOpenSideBar(false);
  };

  return (
    !isMobile?<Sider width={275} className="site-layout-background">
      <Menu
        mode="inline"
        style={{
          minHeight: "100vh",
          borderRight: 0,
          height: "100%",
        }}
        onSelect={onSelect}
        defaultSelectedKeys={[history.location.pathname]}
      >
        {navigation?.map(({ icon: Icon, ...nav }: any, index: number) => {
          if(nav?.is404) {return}
          return(
          <Menu.Item key={nav.path}>
            <Space align={"center"} size={9}>
              <Icon />
              <div style={{marginBottom: "10px"}}>
                {nav.text}
                <Link to={nav.path} />
              </div>
            </Space>
          </Menu.Item>
        )})}
      </Menu>
    </Sider>:
    <Drawer width={450} title="" placement="left" closable={true} onClose={onCloseSideBar} open={isOpenSideBar} key="left">
       <Menu
        mode="inline"
        style={{
          borderRight: 0,
        }}
        onSelect={onSelect}
        defaultSelectedKeys={[history.location.pathname]}
      >
      {navigation?.map(({ icon: Icon, ...nav }: any, index: number) => {
          if(nav?.is404) {return}
          return(
          <Menu.Item key={nav.path}>
            <Space align={"center"} size={9}>
              <Icon />
              <div style={{marginBottom: "10px"}}>
                {nav.text}
                <Link to={nav.path} />
              </div>
            </Space>
          </Menu.Item>
        )})}
         </Menu>
    </Drawer>
  );
}
