import { IBaseResponse } from "../types/base.model";

/* eslint-disable import/no-anonymous-default-export */
const path = "/vaccination/appointments";

export interface IAppointment {
  phone_number: string;
  name: string;
  nric: string;
  first_time: string;
  first_complete: boolean;
  second_time: string;
  second_complete: boolean;
  unit: string;
}
export interface IGetAppointmentsRespone {
  status: boolean;
  data: IAppointment[];
  message?: string;
  total: number;
}

export interface IConfirmAppointment {
  waitlist_id: number;
  first_date: string;
  first_status: string;
  first_vaccine: any;
  second_date: string;
  second_status: string;
  created_at: string;
  updated_at: string;
}

interface IGetSuggestionAppointmentData {
  suggestions_appointments: ISuggestionsAppointment[];
  suggestions_vaccine: number[];
}
export interface ISuggestionsAppointment {
  waitlist: IWaitlist;
  first_date: string;
  first_status: string;
  second_date: string;
  second_status: string;
}
export interface IWaitlist {
  id: number;
  phone_number: string;
  nric: string;
  unit: string;
}
export interface ICreateAppointmentRequest {
  expected_injections: number;
  operation_hours: string[];
}
export interface ICreateAppointmentResponse {
  status: boolean;
  data: IGetSuggestionAppointmentData;
  message?: string;
}

export type PaginationQueryType = {
  items_per_page: number;
  page: number;
};

export type DateTimeQueryType = {
  items_per_page: number;
  page: number;
};

interface IAppointmentPayload {
  status_id: number;
}

export type ApptStatus = "scheduled" | "all" | "waiting";

const appointmentAPIs = {
  async list(
    token: string,
    payload: any
  ): Promise<{ items: IAppointment[]; total: number }> {
    const searchParams = new URLSearchParams({
      items_per_page: payload?.pagination?.items_per_page.toString() ?? 10,
      page: payload?.pagination?.page.toString() ?? 1,
      ...payload,
    });

    if (payload.filter) {
      searchParams.append("appointment_status", payload.filter);
    }

    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL + path + `?${searchParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data, status, message, total }: IGetAppointmentsRespone =
      await res.json();
    if (status === false) {
      throw new Error(message);
    }
    const results = data as IAppointment[];

    if (total) {
      return { items: results, total };
    } else {
      throw new Error("Don't have any appointment!");
    }
  },
  async getAppointmentPreview(
    token: string,
    payload: ICreateAppointmentRequest
  ): Promise<{ data: ISuggestionsAppointment[]; suggestionVaccine: number[] }> {
    // const queryParams = new URLSearchParams({
    //   expected_injections: payload.expected_injections.toString(),
    //   page: payload.operation_hours.toString(),
    // });
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL +
        path +
        `/suggestions?expected_injections=${payload.expected_injections}&start_time=2021/03/25 08:30:00&end_time=2021/03/25 16:30:00`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data, status, message }: ICreateAppointmentResponse =
      await res.json();
    if (status === false) {
      throw new Error(message);
    }
    const suggestionAppointment =
      data.suggestions_appointments as ISuggestionsAppointment[];
    const suggestionVaccine = data.suggestions_vaccine as number[];
    return { data: suggestionAppointment, suggestionVaccine };
  },
  async createAppointment(
    token: string,
    payload: IGetSuggestionAppointmentData
  ): Promise<{ status: boolean; message?: string }> {
    const res = await fetch(process.env.REACT_APP_SWIFTEST_API_URL + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    const { status, message } = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
  async exportCSV(token: string, filter?: ApptStatus): Promise<any> {
    const queryParams = new URLSearchParams({
      all_records: true.toString(),
    });
    if (filter) {
      queryParams.append("appointment_status", filter);
    }
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL + path + `/reports?${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.blob();
    const csvFile = new Blob([data], { type: "text/csv" });

    const template = document.createElement("a");
    template.href = window.URL.createObjectURL(csvFile);
    template.download = "appointment-reports";
    template.click();
    template.remove();
  },
  async bulkAppointment(
    token: string,
    payload: any
  ): Promise<{ status: boolean; message?: string }> {
    //     [PUT] {{st-host}}/api/v1/vaccination/appointments

    // Authorization: schedule-admin, unit-admin, department-admin
    // Input:
    // {
    //     "unit_id": 3,
    //     "personnel_ids": [3, 4],
    //     "date": "2021-03-20",
    //     "start_time": "08:00",
    //     "end_time": "08:15",
    //     "order_no": 1
    // }
    // Output:
    // {
    //     "status": true,
    //     "message": "Update successfully!"
    // }

    const res = await fetch(process.env.REACT_APP_SWIFTEST_API_URL + path, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    const { status, message } = await res.json();
    return { status, message };
  },

  async assignVaccine(
    token: string,
    payload: any
  ): Promise<{ status: boolean; messageErrors?: any[string] ,message?:string}> {
    const res = await fetch(process.env.REACT_APP_SWIFTEST_API_URL + path +'/vaccine/assign', {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    const { status, messageErrors,message } = await res.json();
    return { status, messageErrors,message };
  },
  
  async swapAppointment(token: string, payload: any): Promise<IBaseResponse> {
    const { update_personnel, swap_personnel, order_no, swap_order_no } =
      payload;
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL +
        path +
        `/swap/${update_personnel}?swap_personnel=${swap_personnel}&order_no=${order_no}&swap_order_no=${swap_order_no}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw Error(message || "Swap appointment failed!");
    }
    return { status, message };
  },
  async transferAppointment(
    token: string,
    payload: any
  ): Promise<IBaseResponse> {
    const { appointmentId, targetPersonnel } = payload;
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL +
        path +
        `/transfer/${appointmentId}?target_personnel=${targetPersonnel}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw Error(message || "Transfer appointment failed!");
    }
    return { status, message };
  },
  async rescheduleAppointment(
    token: string,
    payload: any
  ): Promise<IBaseResponse> {
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL + path + `/reschedule`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw Error(message || "Reschedule appointment failed!");
    }
    return { status, message };
  },
  async updateAppointmentStatus(
    token: string,
    payload: IAppointmentPayload,
    id: number
  ): Promise<IBaseResponse> {
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL + path + `/${id}/update-status`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
  async deleteAppointment(token: string, id: number): Promise<IBaseResponse> {
    const res = await fetch(
      process.env.REACT_APP_SWIFTEST_API_URL + path + `/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
};

export default appointmentAPIs;
