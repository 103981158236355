import React from "react";
import { Route, Redirect } from "react-router-dom";
import {  useIsAuthenticated } from "@azure/msal-react";

const AuthRoute = ({
  component: Component,
  path,
  exact,
}: any) => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <Route
      exact={exact}
      path={path}
      render={(props: any) =>
        isAuthenticated ? (
            <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AuthRoute;
