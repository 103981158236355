import { IBaseResponse } from "../types/base.model";
import {
  ICreateSitePayload,
  IGetSiteListResponse,
  ISite,
  IUpdateSitePayload,
} from "../types/site.model";
const path = "/vaccination/sites";
const siteAPIs = {
  async list(token: string): Promise<{ data: ISite[] }> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data, status, message }: IGetSiteListResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { data };
  },
  async create(
    token: string,
    payload: ICreateSitePayload
  ): Promise<IBaseResponse> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
  async update(
    token: string,
    id: number,
    payload: IUpdateSitePayload
  ): Promise<IBaseResponse> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
  async delete(id: string, token: string): Promise<IBaseResponse> {
    const res = await fetch(`${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { status, message }
  },
};

export default siteAPIs;