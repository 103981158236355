import React, { useEffect, useState } from 'react';
import Widget from '../Widget';
import RegistrationImage from "../../assets/registration.png";
import { Col, Row, Select, Table, Typography } from 'antd';
import { ColumnsType } from "antd/es/table/interface";
import { PAGE_NAME } from '../../constants';
import { useRequest } from '../../swr';
import { useAuth } from '../../context/auth';
import "./style.less";

function OverallTable({ site_id, page, setPage, setSiteId }: any) {
  const limit = 5;
  const queryStr = `?page=${page}&order=asc&items_per_page=${limit}`
  const { data: overallData } = useRequest("GET", `/vaccination/vaccines/summary/${site_id}` + queryStr);
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(!overallData)
  }, [overallData])

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      width: 150,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      width: 150,
    },
    {
      title: "Supply",
      dataIndex: "supply",
      width: 120,
    },
    {
      title: "Demand (Next 2 Weeks)",
      dataIndex: "forecast",
      width: 120,
    },
    {
      title: "Expiring (Next 2 Weeks)",
      dataIndex: "expiring",
      width: 120,
    },
  ];

  const getRowClassName = (record: any) => {
    const supplyDemandRatio = record.supply - record.forecast;
    if (supplyDemandRatio < record.supply * 0.3) {
      return "row-lowstock-background";
    }
    return "";
  };

  return (
    <Table
      rowClassName={getRowClassName}
      pagination={{
        pageSize: limit,
        total: overallData?.data?.total,
        current: page,
        onChange: e => {
          setPage(e);
          setSiteId(site_id);
        }
      }}
      loading={loading}
      style={{ margin: "10px 0" }}
      columns={columns}
      dataSource={overallData?.data?.result}
      rowKey={x => x.vaccine_type_id}
      scroll={{ x: 720 }}
    />
  )
}

function OverallInventory() {
  const { data: sitesData, error } = useRequest("GET", "/vaccination/sites");
  const role = useAuth()?.roles;
  const [siteId, setSiteId] = useState<number>(role?.data?.assignedSite || sitesData?.data?.[0]?.id)
  const [page, setPage] = useState(1)

  useEffect(() => {
    setSiteId(role?.data?.assignedSite || sitesData?.data?.[0]?.id)
  }, [role, sitesData]);

  return (
    <Widget title={""} img={RegistrationImage}>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={3}>Overall Vaccine Inventory</Typography.Title>
        </Col>
        <Col>
          <Select
              disabled={role?.data?.assignedSite}
              style={{width:"200px"}}
              onChange={e => {
                setSiteId(e)
                setPage(1)
              }}
              value={siteId}
              placeholder={`Please Select ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1}`}
            >
              {sitesData?.data?.map((site: any) => (<Select.Option key={site.id} value={site.id}>{site.name}</Select.Option>))}
            </Select>
        </Col>
      </Row>
      {siteId > 0 && <OverallTable
        site_id={role?.data?.assignedSite || siteId}
        page={page}
        setPage={setPage}
        setSiteId={setSiteId}
      />}
    </Widget>
  )
}

export default OverallInventory;
