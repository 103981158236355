import SuperAdmin from "./SuperAdmin";
import TestCenterAdmin from "./TestCenterAdmin";
import UnitAdmin from "./UnitAdmin";
import MedAdmin from "./MedAdmin";
import ScheduleAdmin from "./ScheduleAdmin";
import CommandAdmin from "./CommandAdmin";

import "./style.less";

export const SuperAdminDashboard = SuperAdmin;
export const TestCenterAdminDashboard = TestCenterAdmin;
export const UnitAdminDashboard = UnitAdmin;
export const MedAdminDashboard = MedAdmin;
export const ScheduleAdminDashboard = ScheduleAdmin;
export const CommandAdminDashboard = CommandAdmin;

// export default SuperAdmin;
// export default TestCenterAdmin;
