import React, { useState } from "react";
import {
  Button,
  message,
  Table,
  Modal,
  Tooltip,
  Row,
  Typography,
  Space,
} from "antd";
import {
  ExclamationCircleOutlined,
  AlertOutlined,
  EditOutlined,
  DeleteOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
  ColumnsType,
  TablePaginationConfig,
  TableRowSelection,
  // SortOrder,
} from "antd/es/table/interface";
import { TablePaginationType } from "../../../types/table.model";
import "./style.less";

// fetch API
import { useRequest } from "../../../swr";
import SwapModal from "../../Modal/Swap";
// import TransferModal from "../../Modal/Transfer";
import RescheduleModal from "../../Modal/Reschedule";
import {
  DATE_FORMAT_VC_ADMIN,
  ORDER_TYPE_ARRAY_BE,
  ORDER_TYPE_ARRAY_FE,
  VACCINATION_STATUS,
} from "../../../constants";
import EditPersonnel from "../../EditPersonnel";
import Notification from "../../Notification";
import { useAuth } from "../../../context/auth";
import EditStatusModal from "../../Modal/Status";
import AppointmentAPI from "../../../api/appointments";
import { ROLE_USER } from "../../../constants/index";
import { getStatusColor } from "../../../utils/helper";
import personnelAPIs from "../../../api/personnel";

export const appointmentsColumnWidth = {
  mc: 150,
  time: 100,
  date: 100,
  vaccine: 230,
  dose_no: 50,
  status: 100,
  remarks: 150,
  notification: 30,
  action: 120,
};

const VaccineColumn = ({ children, key, date, status, type = "" }: any) => {
  const className =
    moment(date).isBefore(moment()) &&
    [VACCINATION_STATUS.SCHEDULED, VACCINATION_STATUS.REQUESTED].includes(
      status
    )
      ? "row-grey-background"
      : "";
  return (
    <div
      className={className}
      key={key}
      style={{
        height: "60px",
        verticalAlign: "middle",
        margin: "5px 0",
      }}
    >
      <div
        style={{
          padding: "15px 5px",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export interface IReSchedule {
  site_id?: number;
  unit_id?: number;
  date?: string;
  start_time?: string;
  end_time?: string;
  unit_timeslot_id?: number;
  personnel_id?: number;
}

const ExpandedRowRender = (props: any) => {
  const { accessToken, roles } = useAuth();

  const [visibleEditStatus, setVisibleEditStatus] = useState<boolean>(false);
  const [visibleReschedule, setVisibleReschedule] = useState<boolean>(false);
  const [appointment, setAppointment] = useState<any>(null);
  const [injectionItem, setInjectionItem] = useState<any>(null);
  const [visibleNotification, setVisibleNotification] =
    useState<boolean>(false);
  const [injectionId, setInjectionId] = useState<string>(null!!);
  const [reSchedulePayload, setReSchedulePayload] = useState<IReSchedule>({
    site_id: 1,
    date: "",
    start_time: "",
    end_time: "",
    unit_timeslot_id: 1,
    personnel_id: 1,
  });

  const haveRightEditDeleteAppointment = () => {
    return (
      roles?.data?.role_id === ROLE_USER.SUPER_ADMIN_ID ||
      roles?.data?.role_id === ROLE_USER.MED_ADMIN_ID
    );
  };

  const onSetReSchedulePayload = (state: IReSchedule) => {
    setReSchedulePayload({
      ...reSchedulePayload,
      ...state,
    });
  };

  let { record, mutate } = props;

  // fetch all appointment statuses
  const { data: appointmentStatusList, error: errorappointmentStatusList } =
    useRequest("GET", `/vaccination/appointments/statuses`);

  if (errorappointmentStatusList?.message) {
    message.error(errorappointmentStatusList?.message ?? "Error");
  }

  const handleCheckReSchedule = (vaccines = []) => {
    const len = vaccines.length;
    const valid_status = [
      VACCINATION_STATUS.SCHEDULED,
      VACCINATION_STATUS.REQUESTED,
    ];

    const { role_id } = roles?.data;
    const valid_role = [
      ROLE_USER.SUPER_ADMIN_ID,  
      ROLE_USER.UNIT_ADMIN_ID,
      ROLE_USER.DEPARTMENT_ADMIN_ID,
      ROLE_USER.COMMAND_ADMIN_ID,
    ].includes(role_id);

    let counter_status = 0;
    let counter_vaccine = 0;

    vaccines.forEach((v: any) => {
      if (valid_status.includes(v.status)) counter_status++;
      if (v?.vaccine_name && v?.brand) counter_vaccine++;
    });
    return valid_role && ((counter_status === len && counter_vaccine === len) || counter_vaccine === 0);
  };

  const _columns: ColumnsType<any> = [
    {
      title: "",
      key: "notification",
      dataIndex: "appointment_id",
      width: appointmentsColumnWidth.notification,
      render: (appointment_id, b) => {
        return (
          <Space>
            <Tooltip title="Appointment Notification">
              <Button
                type="primary"
                onClick={() => {
                  setInjectionId(appointment_id);
                  setVisibleNotification(true);
                }}
                icon={<AlertOutlined />}
              ></Button>
            </Tooltip>
            {
              handleCheckReSchedule(b?.vaccines) &&
            <Tooltip title="Reschedule Appointment">
              <Button
                disabled={!handleCheckReSchedule(b?.vaccines)}
                type="primary"
                style={{
                  marginRight: "5px",
                }}
                onClick={() => {
                  onSetReSchedulePayload({
                    unit_timeslot_id: b?.vaccines?.[0]?.unit_timeslot_id,
                    site_id: b.site_id,
                    unit_id: record.unit_id,
                    personnel_id: record.id,
                  });
                  setAppointment(record);
                  setVisibleReschedule(true);
                }}
                icon={<ScheduleOutlined />}
              ></Button>
            </Tooltip>
          }
          </Space>
        );
      },
    },
    {
      title: "Medical Centre",
      dataIndex: "site_name",
      width: appointmentsColumnWidth.mc,
    },
    {
      title: "Time",
      width: appointmentsColumnWidth.time,
      render: (value) => `${value.start_time} - ${value.end_time}`,
    },
    {
      title: "Date",
      dataIndex: "date",
      width: appointmentsColumnWidth.date,
      render: (value) => {
        return value === null
          ? "-"
          : moment(value).format(DATE_FORMAT_VC_ADMIN);
      },
    },

    {
      title: "Vaccine",
      onCell: () => ({
        style: { padding: 0 },
      }),
      key: "vaccine",
      dataIndex: "vaccines",
      width: appointmentsColumnWidth.vaccine,
      render: (vaccines, b) => {
        return vaccines.map((vaccine: any) => (
          <VaccineColumn key={vaccine.id} {...vaccine} date={b.date}>
            <span>
              {vaccine.vaccine_name == null
                ? ""
                : `${vaccine.vaccine_name}`}
            </span>
			<br></br>
			<span>
              {vaccine.brand == null
                ? ""
                : `(${vaccine.brand})`}
            </span>

          </VaccineColumn>
        ));
      },
    },
    {
      title: "Dose No",
      onCell: () => ({
        style: { padding: 0 },
      }),
      width: appointmentsColumnWidth.dose_no,
      key: "dose_no",
      dataIndex: "vaccines",
      align: "center",
      render: (vaccines, b) => {
        return vaccines.map((vaccine: any) => (
          <VaccineColumn key={vaccine.id} {...vaccine} date={b.date}>
            <span>{vaccine.order_no < 1 ? "" : vaccine.order_no}</span>
          </VaccineColumn>
        ));
      },
    },
    {
      title: "Status",
      onCell: () => ({
        style: { padding: 0 },
      }),
      width: appointmentsColumnWidth.status,
      key: "status",
      dataIndex: "vaccines",
      render: (vaccines, b) => {
        return vaccines.map((vaccine: any) => (
          <VaccineColumn key={vaccine.id} {...vaccine} date={b.date}>
            <span
              style={{
                background: getStatusColor(vaccine.status),
                color: "#fff",
                fontWeight: "bold",
                padding: "2px 5px",
              }}
            >
              {vaccine.status}
            </span>
          </VaccineColumn>
        ));
      },
    },
    {
      title: "Remarks",
      width: appointmentsColumnWidth.remarks,
      key: "remarks",
      dataIndex: "vaccines",
      onCell: () => ({
        style: { padding: 0, width: 100, overflow: "hidden" },
      }),
      render: (vaccines, b) => {
        return vaccines.map((vaccine: any) => (
          <VaccineColumn key={vaccine.id} {...vaccine} date={b.date}>
            <div>
              <Tooltip placement="bottom" title={vaccine.remarks} color="cyan">
                <Typography.Paragraph
                  ellipsis={{
                    rows: 2,
                  }}
                >
                  {vaccine.remarks}
                </Typography.Paragraph>
              </Tooltip>
            </div>
          </VaccineColumn>
        ));
      },
    },
    haveRightEditDeleteAppointment() ?
    {
      title: "Action",
      key: "action",
      dataIndex: "vaccines",
      width: appointmentsColumnWidth.action,
      onCell: () => ({
        style: { padding: 0 },
      }),
      render: (vaccines, b) => {
        return vaccines.map((record: any) => (
          <VaccineColumn
            key={record.id}
            {...record}
            date={b.date}
            type="button"
          >
            <Space>
              <Tooltip title="Edit Appointment Status">
                <Button
                  disabled={
                    !(
                      (roles?.data?.role_id === ROLE_USER.SUPER_ADMIN_ID || roles?.data?.role_id === ROLE_USER.MED_ADMIN_ID) &&
                      [
                        VACCINATION_STATUS.SCHEDULED,
                        VACCINATION_STATUS.REQUESTED,
                      ].includes(record?.status)
                    )
                  }
                  type="primary"
                  style={{
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    setVisibleEditStatus(true);
                    setInjectionItem(record);
                  }}
                  icon={<EditOutlined />}
                ></Button>
              </Tooltip>

              {
                roles?.data?.role_id === ROLE_USER.SUPER_ADMIN_ID &&
              <Tooltip title="Delete Appointment">
                <Button

                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: "Are you sure to delete this appointment?",
                      content: "Can't restore appointment after delete!",
                      icon: <ExclamationCircleOutlined />,
                      okText: "Delete",
                      okType: "danger",
                      onOk() {
                        AppointmentAPI.deleteAppointment(
                          accessToken,
                          record?.id
                        )
                          .then((res) => {
                            if (res.status) {
                              message.success(
                                res?.message ??
                                  "Delete appointment successfully"
                              );
                              mutate();
                            } else {
                              message.error(
                                res?.message ?? "Delete appointment failed"
                              );
                            }
                          })
                          .catch((error) => {
                            message.error(error?.message ?? error);
                          });
                      },
                    });
                  }}
                  icon={<DeleteOutlined />}
                ></Button>
              </Tooltip>
            }
            </Space>
          </VaccineColumn>
        ));
      },
    }
    :
    {
      width: 0,
    },
  ];

  return (
    <div>
      {visibleEditStatus && (
        <EditStatusModal
          visible={visibleEditStatus}
          onClose={() => {
            setVisibleEditStatus(false);
          }}
          injectionItem={injectionItem}
          statusList={appointmentStatusList?.data}
          mutate={mutate}
        />
      )}
      {visibleReschedule && (
        <RescheduleModal
          onSetReSchedulePayload={onSetReSchedulePayload}
          reSchedulePayload={reSchedulePayload}
          listAppointments={record.appointments}
          appointment={appointment}
          visible={visibleReschedule}
          onClose={() => {
            setVisibleReschedule(false);
            setAppointment(null);
          }}
          mutate={mutate}
        />
      )}
      <Table
        rowKey={(value) => value.id}
        rowClassName={(value) => {
          return moment(value.date).isBefore(moment()) &&
            [
              VACCINATION_STATUS.SCHEDULED,
              VACCINATION_STATUS.REQUESTED,
            ].includes(value.status)
            ? "row-grey-background"
            : "";
        }}
        columns={_columns}
        dataSource={record?.appointments}
        pagination={false}
      />
      <Modal
        title={"Notification Status"}
        width={900}
        visible={visibleNotification}
        onCancel={() => {
          setVisibleNotification(false);
        }}
        footer={
          <Button
            onClick={() => {
              setVisibleNotification(false);
            }}
          >
            Close
          </Button>
        }
      >
        <Notification order={injectionId && injectionId} />
      </Modal>
    </div>
  );
};

export type PropsType = {
  token: string;
  data: any[];
  loading: boolean;
  pagination: TablePaginationType;
  switchPage: Function;
  reloadPage: Function;
  selectedRowKeys: string[];
  setSelectedRowKeys: (rowKeys: string[]) => void;
};

function PersonnelManagementTable({
  token,
  data,
  loading,
  pagination,
  switchPage,
  reloadPage,
  setSelectedRowKeys,
  selectedRowKeys,
  unitList
}: PropsType) {
  const { accessToken } = useAuth();
  const [personnelId, setPersonnelId] = useState<number>(null!);
  const [personnelInfo, setPersonnelInfo] = useState<any>({});
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
  const { roles } = useAuth();

  const haveRightEditDeletePersonnel = () => {
    return (
      roles?.data?.role_id === ROLE_USER.SUPER_ADMIN_ID ||
      roles?.data?.role_id === ROLE_USER.DEPARTMENT_ADMIN_ID ||
      roles?.data?.role_id === ROLE_USER.UNIT_ADMIN_ID
    );
  };



  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "personnel.name",
      sorter: !!data?.length ?? false,
      render: (_, record) => record.name,
    },
    {
      title: "Unit/Department",
      dataIndex: "unit.name",
      width: 150,
      sorter: !!data?.length ?? false,
      render: (_, record) => record.unit,
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
      sorter: !!data?.length ?? false,
      render: (value) => {
        return value === null
          ? "-"
          : moment(value).format(DATE_FORMAT_VC_ADMIN);
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: !!data?.length ?? false,
      render: (_, record) => record.gender?.name || "",
    },
    {
      title: "Phone Number",
      sorter: !!data?.length ?? false,
      dataIndex: "phone_number",
      render: (value) => {
        return value.lastIndexOf("+65") == 0
          ? value?.substring(3, value.length)
          : value;
      },
    },
    {
      title: "Allergies",
      dataIndex: "allergy_name",
      render: (value) => {
        return (
          value &&
          value
            .split(",")
            .filter((item: string) => item)
            .map((item: string) => {
              return (
                <p style={{ textTransform: "capitalize", color: "#ff0000" }}>
                  <strong>{item}</strong>
                </p>
              );
            })
        );
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      ellipsis: true,
      render: (value) => {
        return (
          <Tooltip placement="bottom" title={value} color="cyan">
            {value}
          </Tooltip>
        );
      },
    },
    {
      title: haveRightEditDeletePersonnel() && "Action",
      width: haveRightEditDeletePersonnel() ? 120 : 1,
      render: (record) => {
        return (
          haveRightEditDeletePersonnel() && (
            <>
			<Space>
              <Tooltip title="Edit Personnel">
                <Button
                  type="primary"
                  onClick={() => {
                    setPersonnelInfo(record);
                    setPersonnelId(record.id);
                    setVisibleEdit(true);
                  }}
                  icon={<EditOutlined />}
                  style={{ marginRight: "10px" }}
                ></Button>
              </Tooltip>
              <Tooltip title="Delete Personnel">
                <Button
                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: "Are you sure to delete this personnel?",
                      icon: <ExclamationCircleOutlined />,
                      okText: "Delete",
                      okType: "danger",
                      onOk() {
                        personnelAPIs
                          .delete(accessToken, record?.id)
                          .then((res) => {
                            if (res.status) {
                              message.success(
                                res?.message ?? "Delete personnel successfully"
                              );
                              reloadPage();
                            } else {
                              message.error(
                                res?.message ?? "Delete personnel failed!"
                              );
                            }
                          })
                          .catch((error) => {
                            message.error(error?.message ?? error);
                          });
                      },
                    });
                  }}
                  icon={<DeleteOutlined />}
                ></Button>
              </Tooltip>
			  </Space>
            </>
          )
        );
      },
    },
  ];

  const rowSelection: TableRowSelection<any> = {
    type: "checkbox",
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys as string[]);
    },
    selectedRowKeys,
  };

  const onChangePage = (
    pagination: TablePaginationConfig,
    filter?: any,
    sorter?: any
  ): void => {
    if (sorter) {
      switchPage({
        ...pagination,
        orderBy: sorter.field,
        orderType:
          ORDER_TYPE_ARRAY_BE[ORDER_TYPE_ARRAY_FE.indexOf(sorter.order)],
      });
    } else {
      switchPage(pagination);
    }
  };

  const { data: allergiesList, error: allergiesLisError } = useRequest(
    "GET",
    `/vaccination/personnels/options/allergies`
  );

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        className="personnel-table"
        columns={columns}
        dataSource={data}
        loading={loading}
        onChange={onChangePage}
        pagination={pagination}
        rowSelection={rowSelection}
        expandedRowRender={(record, index, indent, expanded) => {
          return (
            expanded === true && (
              <ExpandedRowRender record={record} mutate={reloadPage} />
            )
          );
        }}
        scroll={{ x: 800 }}
      />
      <Modal
	    width="700px"
        visible={visibleEdit}
        onCancel={() => {
          setVisibleEdit(false);
          onChangePage(pagination);
        }}
        footer={
          <Button
            type="primary"
            onClick={() => {
              setVisibleEdit(false);
              onChangePage(pagination);
            }}
          >
            Close
          </Button>
        }
        title={"Edit Personnel"}
      >
        <EditPersonnel
          id={personnelId}
          nric=""
          handleFetchInfoWithIdOrNRIC={async () => {
            const items = await reloadPage();
            const info = items?.filter(
              (item: any) => item.id === personnelId
            )?.[0];
            if (info) {
              setPersonnelInfo(info);
            } else {
              setVisibleEdit(false);
            }
          }}
          personnelInfo={personnelInfo}
          unitList={unitList}
          allergiesList={allergiesList}
		  canEdit={true}
        />
      </Modal>
    </>
  );
}

export default PersonnelManagementTable;
