/**
 * @author ming.leng
 */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  message,
  Row,
  Space,
  Typography,
  Form,
  Select,
  Spin,
  Col,
  Modal,
} from "antd";
import { DatePicker } from "antd";
import DashboardContainer from "../../layout/Dashboard";
import TimeslotAPI from "../../api/timeslots";
import { useAuth } from "../../context/auth";
import { DATE_FORMAT, PAGE_NAME } from "../../constants";
import moment from "moment";
import CreateTimeSlotModal from "./Modal/Create";
import EditIndividualTimeslot from "./Modal/EditIndividualTimeslot";
import DefinedSlots from "./DefinedSlots";
import Overview from "./Overview";
import {
  ICreateTimeslotsPayload,
  ISlot,
  PayloadGetTimeSlotsByDate,
} from "../../types/timeslots.model";

import { ISite } from "../../types/site.model";
import { useRequest } from "../../swr";
const { Title } = Typography;

// const DEPARMENT_TYPE_ID = 2;

export interface IUnitTimeSlot {
  timeslot_id: number;
  title: string;
  start_time: string;
  end_time: string;
  unit_id: number;
  slots: number;
  uses: number;
}

export default function TimeSlot() {
  const { accessToken, roles } = useAuth();

  // get slot by date states
  const [medicalCenterDisabled, setMedicalCenterDisabled] =
    useState<boolean>(false);
  const [date, setDate] = useState<string>(moment().format(DATE_FORMAT));
  const [slots, setSlots] = useState<ISlot[]>([]);
  const [totalSlots, setTotalSlots] = useState<number>(null!);

  const [totalUsed, setTotalUsed] = useState<number>(null!);
  const [siteId, setSiteID] = useState<number>(null!);
  const [timeSlotId, setTimeSlotId] = useState<number>(null!);

  // click a slot state
  const [selectedSlot, setSelectedSlot] = useState<ISlot>(null!);

  // visible modals
  const [visibleCreateTimeSlotModal, setVisibleCreateTimeSlotModal] =
    useState<boolean>(false);
  const [visibleUpdateIndividualTimeSlot, setVisibleUpdateIndividualTimeSlot] =
    useState<boolean>(false);

  const [deleteTimeSlotLoading, setDeleteTimeSlotLoading] =
    useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFields([
      {
        name: "site_id",
        value: roles.data.assignedSite,
      },
    ]);
    if (roles.data.assignedSite) {
      setSiteID(roles.data.assignedSite);
      fetchExistingTimeSlotMedAdmin();
    }
  }, []);

  const fetchExistingTimeSlotMedAdmin = () => {
    //if (roles.data.name === "medical-admin") {
      const _date = moment(date).format(DATE_FORMAT);
      const siteId = roles.data.assignedSite || form.getFieldValue("site_id");
      getTimeSlotsByDate(accessToken, { date: _date, site_id: siteId });
    //}
  };

  const createTimeSlots = (token: string, payload: ICreateTimeslotsPayload) => {
    return TimeslotAPI.createTimeSlots(token, payload)
      .then(() => {
        message.success("Create time slot successfully");
        getTimeSlotsByDate(accessToken, { date: date, site_id: siteId });
      })
      .catch((err) => {
        message.success(err?.message ?? "Define time slot Failed!");
      });
  };

  const updateTimeSlot = (token: string, payload: any, timeslot_id: number) => {
    return TimeslotAPI.editTimeSlotById(token, timeslot_id, payload)
      .then((res) => {
        if (res.status) {
          message.success(res.message);
        } else {
          message.error(res.message);
        }
        getTimeSlotsByDate(accessToken, { date: date, site_id: siteId });
      })
      .catch((err) => {
        message.success(err?.message ?? "Update time slot failed");
      });
  };

  const handleTimeSlotClick = (selectedSlot: ISlot) => {
    setSelectedSlot(selectedSlot);
    setVisibleUpdateIndividualTimeSlot(true);
  };

  const reset = () => {
    setSelectedSlot(null!);
  };

  const { data: sitesData, error } = useRequest("GET", "/vaccination/sites");

  const sites: ISite[] = sitesData?.data ?? [];
  if (error?.message) {
    message.error(error?.message ?? "error");
  }

  const getTimeSlotsByDate = (
    token: string,
    payload: PayloadGetTimeSlotsByDate
  ) => {
    return TimeslotAPI.list(token, payload)
      .then((data) => {
        if (data?.timeslots && data?.timeslots.length !== 0) {
          setTotalSlots(data?.total_slots);
          setTimeSlotId(data.id);
          setSlots(
            data?.timeslots?.map((ts: any) => ({
              ...ts,
              slots: ts.id ? ts.slots : 0,
            }))
          );
          setTotalUsed(data?.total_uses);
        } else {
          //message.warning("Don't have any time slots!");
          setTotalSlots(0);
          setSlots([]);
          setTotalUsed(0);
        }
      })
      .catch((err) => {
        message.error(err?.message ?? "Don't have any time slots");
        setTotalSlots(0);
        setSlots([]);
        setTotalUsed(0);
      });
  };

  const onDeleteTimeSlot = (index: number) => {
    const slot = slots[index];
    if (slot.uses > 0) {
      return message.error("This time slot is used");
    }
	Modal.confirm({
      title: "Delete time slot",
      content: "Are you sure you want to the selected time slot?",
      onOk: () => {
		setDeleteTimeSlotLoading(true);
		TimeslotAPI.deleteSingleTimeSlot(accessToken, slot.id)
		  .then((d: any) => {
			fetchExistingTimeSlotMedAdmin();
			setDeleteTimeSlotLoading(false);
			return message.success(d.message);
		  })
		  .catch((e) => {
			setDeleteTimeSlotLoading(false);
			return message.success(e.message);
		  });
		},
    });
  };

  const onDeleteAllTimeSlot = () => {
    Modal.confirm({
      title: "Delete All time slot",
      content: "Are you sure you want to delete all time slot?",
      onOk: () => {
        TimeslotAPI.deleteAllTimeSlot(accessToken, timeSlotId)
          .then(() => {
            message.success("Delete time slot successfully");
            getTimeSlotsByDate(accessToken, { date: date, site_id: siteId });
          })
          .catch((err) => {
            message.success(err?.message ?? "Delete time slot Failed!");
          });
      },
    });
  };

  const canTimeSlotReCreate = () => {
    return (
      slots.reduce(
        (accumulator: number, slot: ISlot) => accumulator + slot.uses || 0,
        0
      ) === 0
    );
  };

  return (
    <DashboardContainer>
      <Card>
        <div>
          <Space direction="vertical">
            <Title level={3} style={{ margin: 0 }}>
              Time-Slot Management
            </Title>
          </Space>
        </div>
      </Card>
      <br />
      <Card>
        <Form
          form={form}
          layout="inline"
          initialValues={{
            date: moment(date),
          }}
          onValuesChange={(e, el) => {
            if (e.date)
              setMedicalCenterDisabled(
                moment(e.date).startOf("day").isBefore(moment().startOf("day"))
              );
            setDate(el?.date?.format(DATE_FORMAT));
            setSiteID(el?.site_id);

            if (el.date && el.site_id) {
              getTimeSlotsByDate(accessToken, {
                date: el?.date?.format(DATE_FORMAT),
                site_id: el.site_id,
              });
              reset();
            }
          }}
        >
          <Space wrap align="start">
            <div>
              <Form.Item
                name="date"
                label="Date"
                rules={[{ required: true, message: "Please select a date" }]}
              >
                <DatePicker allowClear={false} style={{ width: "100%" }} />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label={PAGE_NAME.MEDICAL_CENTRE.LABEL_1}
                name="site_id"
                rules={[
                  {
                    required: true,
                    message: `Please select a ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1}!`,
                  },
                ]}
              >
                <Select style={{ width: "100%" }}
                  disabled={!!roles.data.assignedSite}
                  placeholder={`Select ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1}`}
                >
                  {sites?.map((site: ISite) => {
                    return (
                      <Select.Option key={site.id} value={site.id}>
                        {site.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Space>
        </Form>

        <Divider orientation="left">
          <div
            style={{
              fontSize: 22,
              fontWeight: 600,
              color: "#434547",
            }}
          >
            Time Slot
          </div>
        </Divider>
        <Row
          gutter={[5, 5]}
          justify="end"
          align="middle"
        >
          <Col>
            <Button
              type="primary"
              onClick={() => setVisibleCreateTimeSlotModal(true)}
              disabled={
                medicalCenterDisabled ||
                !form.getFieldValue("site_id") ||
                !canTimeSlotReCreate()
              }
            >
              {!slots || slots?.length == 0
                ? "Create"
                : "Recreate"}
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => onDeleteAllTimeSlot()}
              danger
              disabled={
                medicalCenterDisabled ||
                !form.getFieldValue("site_id") ||
                !canTimeSlotReCreate() ||
				slots?.length <= 0
              }
            >
              Delete All
            </Button>
          </Col>
        </Row>

        <Space style={{ margin: "20px 0" }} align="start" className="custom-timeslots">
          <Overview allocated={totalUsed} total={totalSlots} />
          <Spin spinning={deleteTimeSlotLoading}>
            <DefinedSlots
              slots={slots}
              handleTimeSlotClick={handleTimeSlotClick}
              medicalCenterDisabled={medicalCenterDisabled}
              onDeleteTimeSlot={onDeleteTimeSlot}
            />
          </Spin>
        </Space>
      </Card>

      <CreateTimeSlotModal
        visible={visibleCreateTimeSlotModal}
        handleCancelModal={() => setVisibleCreateTimeSlotModal(false)}
        createTimeSlots={createTimeSlots}
        date={date}
        site_id={siteId}
      />
      <EditIndividualTimeslot
        visible={visibleUpdateIndividualTimeSlot}
        handleCancelModal={() => setVisibleUpdateIndividualTimeSlot(false)}
        updateTimeSlot={updateTimeSlot}
        selectedSlot={selectedSlot}
      />
    </DashboardContainer>
  );
}
