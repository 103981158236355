import React from "react";
import { Layout, Avatar, Dropdown, Menu, Typography, Space } from "antd";
import { UserOutlined, LogoutOutlined, DownOutlined, MenuFoldOutlined } from "@ant-design/icons";
// context
import { useAuth } from "../../../context/auth";
// Image
import logoWhite from "../../../assets/images/logo_white.png";
import { useMsal } from "@azure/msal-react";
import { deleteCookie } from '../../../utils/helper';
import useCheckMobileScreen from "../../../hook/useCheckMobileScreen";
import { useHistory } from "react-router-dom";
const { Header } = Layout;
const { Title } = Typography;

type HeaderLayoutProps={
  isOpenSideBar:boolean,
  setIsOpenSideBar:(isOpenSideBar:boolean)=>void
}

export default function HeaderLayout({isOpenSideBar,setIsOpenSideBar}:HeaderLayoutProps) {
  const { instance } = useMsal();
  const isMobile = useCheckMobileScreen();
  const logout = () => {
    instance.logoutRedirect({postLogoutRedirectUri: "/",});
    localStorage.clear();
  };

  const menu = (
    <Menu style={{ width: "100%" }}>
      <Menu.Item
        onClick={() => logout()}
        key="1"
      >
        <LogoutOutlined />
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="header" style={{ display: "flex", paddingLeft: "15px" }}>
      <div className="logo">
        <Space>
          <img alt="nav-logo" src={logoWhite} width="35" height="35" />
          <Title
            level={3}
            style={{ lineHeight: "revert", color: "#fff", margin: 0 }}
          >
            SwiftVAX
          </Title>
        </Space>
      </div>
      <div style={{ flex: "1 1 0%" }} />
      <div style={{ float: "right", color: "#fff", cursor: "pointer" }}>
        <Dropdown overlay={menu}>
          <Space>
          {isMobile&&<div style={{display:"flex",alignItems:"center"}} onClick={()=>{setIsOpenSideBar(!isOpenSideBar)}}><MenuFoldOutlined style={{fontSize:24}} /></div> }
            <Avatar icon={<UserOutlined  />} />
            <DownOutlined />
          </Space>
        </Dropdown>
      </div>
    </Header>
  );
}
