import React from "react";
import { Button, Space, Statistic } from "antd";
import { ISlot } from "../../../types/timeslots.model";
import "./style.less";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
type PropsType = {
  slots: ISlot[];
  handleTimeSlotClick: Function;
  onDeleteTimeSlot: Function;
  medicalCenterDisabled: boolean;
};

export default function DefinedSlots({
  slots,
  handleTimeSlotClick,
  onDeleteTimeSlot,
  medicalCenterDisabled,
}: PropsType) {
  const onEditTimeSlot = (index: number) => {
    const selectedSlot = slots && slots[index];
    const { start_time, end_time, id, title } = selectedSlot;
    handleTimeSlotClick({
      start_time,
      end_time,
      id,
	  title,
      slots: selectedSlot.slots,
    });
  };

  const deleteTimeSlotBtnDisabled = ({ uses }: any) => {
    return uses > 0;
  };

  return (
    <>
      {slots &&
        slots?.map(
          (
            { title, start_time, end_time, /*units, commands,*/ slots, uses },
            index
          ) => (
            <Space key={start_time} className="time-slot-item-container">
              <Button
                onClick={() => onEditTimeSlot(index)}
                className="time-slot-edit-button"
                icon={<EditOutlined />}
                disabled={medicalCenterDisabled}
              />
              <Button
                onClick={() => onDeleteTimeSlot(index)}
                className="time-slot-delete-button"
                icon={<DeleteOutlined />}
                disabled={
                  medicalCenterDisabled || deleteTimeSlotBtnDisabled({ uses })
                }
              />
              <Statistic
                value={` `}
                prefix={
                  <div>
				  {title}
				  <br />
                    {start_time} - {end_time}
                    <br />
                    Total: {slots}
                    <br />
                    Taken: {uses}
                  </div>
                }
                className={`time-slot-item active `}
              />
            </Space>
          )
        )}
    </>
  );
}
