/**
 * @author ming.leng
 */
import React, { useEffect } from "react";
import { Modal, Button, TimePicker, Form, Input } from "antd";
import { useAuth } from "../../../../context/auth";
import { ISlot } from "../../../../types/timeslots.model";
import { TIME_FORMAT } from "../../../../constants";
import moment from "moment";

const { RangePicker } = TimePicker;
export type PropsType = {
  visible: boolean;
  handleCancelModal: () => void;
  updateTimeSlot: Function;
  selectedSlot: ISlot
};

const timeRangePickerConfig = {
  rules: [
    { type: "array" as const, required: true, message: "Please select time!" },
  ],
};

function range(start: number, end: number): number[] {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

const disabledHours = (): number[] => {
  return [...range(0, 7), ...range(18, 24)];
};

const disabledMinutes = (selectedHour: number): number[] => {
  if (selectedHour === 17) return range(1, 60);
  return [];
};

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};

const tailFormItemLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function EditIndividualTimeslot({
  visible,
  handleCancelModal,
  updateTimeSlot,
  selectedSlot
}: PropsType) {
  const { accessToken } = useAuth();
  const [form] = Form.useForm();
  const onCancel = () => {
    handleCancelModal();
    form.resetFields();
  };
  const onFinish = (fieldsValue: any) => {
    const payload = {
      total_slots: fieldsValue["total_slots"],
	  title: fieldsValue["title"],
    };
    updateTimeSlot(accessToken, payload, selectedSlot?.id);
    onCancel();
  };

  useEffect(() => {
    if(visible) {
      form.setFields([
        {
          name: "title",
          value: selectedSlot?.title,
        },
		{
          name: "total_slots",
          value: selectedSlot?.slots ?? 0,
        },
        {
          name: "working_hours",
          value: [
            moment(selectedSlot?.start_time, TIME_FORMAT),
            moment(selectedSlot?.end_time, TIME_FORMAT),
          ],
        },
      ]);
    } else {
      form.resetFields();
    }
  }, [visible]);

  return (
    <Modal
      title="Edit Individual Timeslot"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        {...formItemLayout}
        form={form}
        layout="horizontal"
        name="edit_allocated"
        onFinish={onFinish}
      >
	    <Form.Item
          label="Title:"
          name="title"
          rules={[{ required: false, message: "Please enter title" }]}
        >
          <Input maxLength={10} />
        </Form.Item>
        <Form.Item
          label="Time Range"
          name="working_hours"
          {...timeRangePickerConfig}
        >
          <RangePicker
            use12Hours
            disabled={true}
            format={TIME_FORMAT}
            disabledHours={disabledHours}
            disabledMinutes={(selectedHour) => disabledMinutes(selectedHour)}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="No. of Slot:"
          name="total_slots"
          rules={[{ required: true, message: "Please enter No. of Slot" }]}
        >
          <Input type="number" min={0} />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditIndividualTimeslot;
