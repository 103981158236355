import React, { useState, useEffect} from "react";

import {
  Form,
  DatePicker,
  Select,
  Cascader,
  Input,
  Row,
  Col,
  Radio,
  RadioChangeEvent,
  Button,
  message
} from "antd";
import { IUnit } from "../../../../types/unit.model";
import { ICommand, ICommandUnits } from "../../../../types/command.model";
import { useAuth } from "../../../../context/auth";
import { useRequest } from "../../../../swr";
import PersonnelAPI from "../../../../api/personnel";
import moment from 'moment';

enum UnitEnum {
  COMMAND = "Command",
  DEPARTMENT = "Department",
  UNIT = "Unit",
  MED = "Med"
}

interface PropsType {
  callback: Function
}

function SingleRegistration(props: PropsType) {
  const {
    callback
  } = props;

  const checkTypeUnitId = (roleName : string)=>{
    switch (roleName) {
      case "department-admin":
        return UnitEnum.DEPARTMENT;
      case "unit-admin":
        return UnitEnum.UNIT;
      case "command-admin":
        return UnitEnum.COMMAND;
      default:
        return UnitEnum.UNIT;
    }
  }

  const {
    accessToken,
    roles: {
      data : {
        name: role_name
      }
    }
  } = useAuth();


  const [ form ] = Form.useForm();
  const [ unitType, setUnitType ] = useState<any>()

  const { data: getDepartmentList, error: getDepartmentListErr } = useRequest(
    "GET", "/vaccination/units?type_id=2"
  );
  const departmentList: IUnit[] = getDepartmentList?.data ?? [];

    if (getDepartmentListErr?.message) {
      message.error(getDepartmentListErr?.message ?? getDepartmentListErr);
    }

  const { data: getUnitList, error: getUnitListErr } = useRequest(
    "GET", "/vaccination/units?type_id=1"
  );
  const unitList: IUnit[] = getUnitList?.data ?? [];
  if (getUnitListErr?.message) {
    message.error(getDepartmentListErr?.message ?? getDepartmentListErr);
  }

  const { data: getCommandList, error: getCommandListErr } = useRequest(
    "GET", "/vaccination/commands"
  );
  const commandList: ICommand[] = getCommandList?.data ?? [];

  if (getCommandListErr?.message) {
    message.error(getCommandListErr?.message ?? getCommandListErr);
  }

  const { data: gendersOption, error: gendersOptionError } = useRequest(
    "GET", "/vaccination/personnels/options/genders"
    );
  if (gendersOptionError?.message) {
    message.error(gendersOptionError?.message || 'Get gender list failed');
  }


  const { data: allergiesOption, error: allergiesOptionError } = useRequest(
    "GET", "/vaccination/personnels/options/allergies"
  );
  if (allergiesOptionError?.message) {
    message.error(allergiesOptionError?.message || 'Get allergies failed');
  }

  const formItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const [value, setValue] = useState('');

  const handlePhoneNumberChange = event => {
    const result = event.target.value.replace(/\D/g, '');
    setValue(result);
  };

  const onFinish = (values: any) => {
    const {
      nric,
      name,
      phone_number,
      dob,
      // personnel_type,
      unit_id,
      remarks,
      gender,
      allergy_ids
    } = values;


    if (
      !dob ||
      !name ||
      !nric ||
      // !personnel_type ||
      !phone_number ||
      !unit_id||
      !gender||
      !allergy_ids
    ) {
      message.error("Invalid data!")
    }
    else {
      const payload = {
        dob:moment(dob).format("YYYY-MM-DD"),
        name,
        nric,
        personnel_type:1,
        phone_number,
        unit_id: unit_id.constructor === Array ? unit_id[unit_id.length - 1] : unit_id,
        remarks,
        gender,
        allergy_ids
      };

      PersonnelAPI.createPersonnel(accessToken, payload)
        .then(response => {
          message.success(response?.message ?? "Create personnel successfully!")
          form.resetFields();
          if (callback) {
            callback();
          }
        })
        .catch(error => {
          message.error(error?.message ? error?.message: "Create personnel failed")
        })
    }
  };

  useEffect(() => {
    if(unitType === UnitEnum.UNIT  && unitList?.length === 1){
		unitList.map((unit: IUnit) => (
			form.setFieldValue("unit_id",unit.id)
		))
    }
  }, [unitList])

  useEffect(() => {
    if(unitType === UnitEnum.DEPARTMENT  && departmentList?.length === 1){
		departmentList.map((unit: IUnit) => (
			form.setFieldValue("unit_id",unit.id)
		))
    }
  }, [departmentList])

  useEffect(() => {
    setUnitType(checkTypeUnitId(role_name))
  }, [role_name])

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      style={{width: "500px", marginBottom: "56px"}}
      labelAlign="left"
    >
	{
        ["medical-admin","super-admin"].includes(role_name) &&
        <Row style={{marginBottom: 12}}>
          <Col xs={24} span={16} offset={8}>
            <Radio.Group
              options={[UnitEnum.UNIT,UnitEnum.DEPARTMENT]}
              onChange={(e: RadioChangeEvent) => {
                form.setFieldsValue({ unit_id: null })
                setUnitType(e.target.value)
              }}
              value={unitType}
              optionType="button"
            />
          </Col>
        </Row>
      }
    {
      unitType === UnitEnum.COMMAND  && (
          <Form.Item
            label="Unit"
            name="unit_id"
            rules={[
              {
                required: true,
                message: "Please select unit",
              },
            ]}
          >
            <Cascader
              options={
                commandList
                  .filter((command: ICommand) => command.command_units.length)
                  .map((command: ICommand) => {
                    const {command_id, command_name, command_units} = command;
                    return {
                      value: command_id,
                      label: command_name,
                      children: command_units.map((unit: ICommandUnits) => {
                        const {id, name} = unit;
                        return {
                          value: id,
                          label: name,
                        }
                      })
                    }
                  })
              }
              placeholder="Please select unit" />
          </Form.Item>
        )}
        {
        unitType === UnitEnum.DEPARTMENT  &&  (
          <Form.Item
            label="Department"
            name="unit_id"
            rules={[
              {
                required: true,
                message: "Please select department",
              },
            ]}
          >
            <Select
              placeholder="Please select department"
            >
              {departmentList &&
                departmentList.map((unit: IUnit) => (
                  <Select.Option value={unit.id} key={unit.name}>
                    {unit.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )
       }
      {
        unitType === UnitEnum.UNIT  &&  (
          <Form.Item
            label="Unit"
            name="unit_id"
            rules={[
              {
                required: true,
                message: "Please select unit",
              },
            ]}
          >
            <Select
              placeholder="Please select unit"
            >
              {unitList &&
                unitList?.map((unit: IUnit) => (
                  <Select.Option value={unit.id} key={unit.name}>
                    {unit.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )
       }
      <Form.Item
        label="NRIC"
        name="nric"
        rules={[
          {
            required: true,
            message: "Please enter NRIC",
          },
        ]}
      >
        <Input maxLength={9} placeholder="Please enter NRIC" onInput={e => e.target.value = e.target.value.toUpperCase()}/>
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please enter name",
          },
        ]}
      >
        <Input placeholder="Please enter name"/>
      </Form.Item>
      <Form.Item
        label="Phone Number"
        name="phone_number"
        rules={[
          {
            required: true,
            message: "Please enter phone number",
          },
        ]}
      >
        <Input maxLength={8} placeholder="Please enter phone number" value={value} onChange={handlePhoneNumberChange} />
      </Form.Item>
      <Form.Item
        label="Date of Birth"
        name="dob"
        rules={[
          {
            required: true,
            message: "Please enter date of birth",
          },
        ]}
      >
        <DatePicker placeholder="Please enter date of birth" disabledDate={(current) => current.isAfter(moment())} style={{width: "100%"}} format={"YYYY-MM-DD"}/>
      </Form.Item>
      <Form.Item
        label="Gender"
        name="gender"
        rules={[
          {
            required: true,
            message: "Please select gender",
          },
        ]}
      >
        <Select placeholder="Please select gender">
          {gendersOption?.map((item:{
            id:number,name:string
          })=> <Select.Option value={item.id} key={item.name}>
                      {item.name}
          </Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item
        label="Allergies"
        name="allergy_ids"
        rules={[
          {
            required: true,
            message: "Please select allergies",
          },
        ]}

      >
        <Select mode="multiple" placeholder="Please select allergies">
        {allergiesOption &&
            allergiesOption?.map((allergy: {id:number,name:string}) => (
          <Select.Option value={allergy.id} key={allergy.id}>
              {allergy.name}
          </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="Remarks" name="remarks">
        <Input.TextArea placeholder="Please enter remarks" />
      </Form.Item>
      <Form.Item style={{float: "right"}}>
        <Button type="primary"  htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default SingleRegistration;
