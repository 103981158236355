/**
 * @author ming.leng
 */
import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Input, Select } from "antd";
import { useAuth } from "../../../../context/auth";
import { ICommandAdmin } from "../..";
import { IUnit } from "../../../../types/unit.model";
import { ICommand } from "../../../../types/command.model";
import { PAGE_NAME } from "../../../../constants";

const tailFormItemLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

interface PropsType {
  visible: boolean;
  handleCancelModal: () => void;
  commandAdminList: ICommandAdmin[];
  selectedCommand: ICommand;
  availableCommandAdminList: ICommandAdmin[];
  updateCommand: Function;
  allUnits: IUnit[];
  availableUnitList: IUnit[];
}

function UpdateModal({
  visible,
  handleCancelModal,
  commandAdminList,
  selectedCommand,
  availableCommandAdminList,
  updateCommand,
  allUnits,
  availableUnitList,
}: PropsType) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const { accessToken } = useAuth();
  const [form] = Form.useForm();
  const [selection, setSelection] = useState<ICommandAdmin[]>(null!);
  const [unitOptions, setUnitOptions] = useState<IUnit[]>(null!);

  const combineListUnit = () => {
    const unitOptions = allUnits &&
      allUnits?.filter((item: IUnit) => selectedCommand?.command_units?.map(e => e.id).indexOf(item?.id) !== -1).concat(availableUnitList);

    setUnitOptions(unitOptions);
  };
  const combineListAdmin = () => {
    const combinationList = commandAdminList &&
      commandAdminList?.filter((item) => selectedCommand?.command_admins?.indexOf(item?.id) !== -1).concat(availableCommandAdminList);

    setSelection(combinationList);
  };

  useEffect(() => {
    if (visible === false) {
      form.resetFields();
      setSelection(null!);
      setUnitOptions(null!);
    } else {
      combineListAdmin();
      combineListUnit();
      form.setFields([
        { name: "name", value: selectedCommand?.command_name },
        {
          name: "admin_ids",
          value: selectedCommand?.command_admins?.map((id: number) => id),
        },
        {
          name: "units",
          value: selectedCommand?.command_units?.map(e => e.id),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, selectedCommand, commandAdminList, form]);

  const onFinsh = (fieldsValue: any) => {
    const payload = {
      ...fieldsValue,
      admin_ids: fieldsValue["admin_ids"] ?? [],
    };
    const id = selectedCommand?.command_id;
    updateCommand(accessToken, payload, id);
  };
  const onCancel = () => {
    handleCancelModal();
    form.resetFields();
    setSelection(null!);
    setUnitOptions(null!);
  };

  return (
    <Modal
      title={`Update ${PAGE_NAME.COMMAND.LABEL_1}`}
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      getContainer={false}
      destroyOnClose
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        name="admin-user-group"
        onFinish={onFinsh}
      >
        <Form.Item
          name="name"
          label="Name :"
          rules={[{ required: true, message: "Please enter name!" }]}
        >
          <Input placeholder="Please enter Department name" />
        </Form.Item>
        <Form.Item name="admin_ids" label="Admin">
          <Select
            mode="multiple"
            placeholder="Please select name"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={selectedItems}
            onChange={(selectedItems: never[]) =>
              setSelectedItems(selectedItems)
            }
            style={{ width: "100%" }}
          >
            {selection &&
              selection.map((admin: ICommandAdmin) => {
                return (
                  <Select.Option key={admin.id} value={admin.id}>
                    {admin.identity}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item name="units" label="Units">
          <Select
            mode="multiple"
            placeholder="Please select Units"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={selectedUnits}
            onChange={(selectedUnits: never[]) =>
              setSelectedUnits(selectedUnits)
            }
            style={{ width: "100%" }}
          >
            {unitOptions &&
              unitOptions?.map((unit: IUnit) => {
                return (
                  <Select.Option key={unit.id} value={unit.id}>
                    {unit.name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdateModal;
