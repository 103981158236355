export interface Role {
  id: string;
  name: string;
}

const authAPIs = {
  async getRole(token: string): Promise<any> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}/auth/role`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const rep = await res.json();
    return rep;
  },
};

export default authAPIs;