import React from "react";
import ReactDOM from "react-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { msalConfig } from "./lib/msalConfig";
import dotenv from "dotenv";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
dotenv.config();

const msalInstance = new PublicClientApplication(msalConfig);
//Sentry.init({
  //dsn: process.env.REACT_APP_SENTRY_KEY|| "https://80e549cfee8f4f4b94f5ad88add66b94@o4503969644085248.ingest.sentry.io/4504326113787904",
  //integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  //tracesSampleRate: 1.0,
//});

if (window.location.hash !== ''){
  //console.log("hash found" + window.location.hash);
}
else {
	// Component
	const AppProvider = () => (
	  <React.StrictMode>
		<MsalProvider instance={msalInstance}>
		  <App />
		</MsalProvider>
	  </React.StrictMode>
	);

	ReactDOM.render(<AppProvider />, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
