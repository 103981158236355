import React, { useEffect, useState } from "react";
import { DatePicker, message, Table, Row, Col, Select, Divider } from "antd";
import moment, { Moment } from "moment";
import { DATE_FORMAT, TIME_FORMAT } from "../../../constants";
import { useAuth } from "../../../context/auth";
import SummaryAPI from "../../../api/summary";
import {
  ITimeslotInfo,
  ITotalSlots,
  IUnit,
  ICommand,
  IDepartment,
} from "../../../types/summary.model";
import { useRequest } from "../../../swr";
import { ROLE_USER } from '../../../constants/index';

const { Column, ColumnGroup } = Table;

const initTotal: ITotalSlots = {
  completed: 0,
  scheduled: 0,
};

function ScheduledAdminAppointmentTimeSlotOverview() {
  const { accessToken, roles } = useAuth();
  const [date, setDate] = useState<Moment | null>(moment());
  const [total, setTotal] = useState<ITotalSlots>(initTotal);
  const [slotData, setSlotData] = useState<ITimeslotInfo[]>([]);
  const [commands, setCommands] = useState<ICommand[]>([]);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [siteId, setSiteId] = useState<number>(null!);
  const { data: sitesData, error } = useRequest("GET", "/vaccination/sites");

  const sites: IUnit[] = sitesData?.data ?? [];
  if (error?.message) {
    message.error(error?.message ?? "error");
  }

  const fetchTimeSlotOverview = (
    token: string,
    date: Moment | null,
    site_id: number | null
  ) => {
    const formatDate = date ? date?.format(DATE_FORMAT) : "";
    setLoading(true);
    SummaryAPI.listAppointmentTimeslots(token, formatDate, site_id)
      .then(({ data }) => {
        setTotal(data[0]?.total ?? initTotal);
        setSlotData(data[0]?.timeslots ?? []);
        setCommands(data[0]?.commands ?? []);
        setDepartments(data[0]?.departments ?? []);
        setLoading(false);
      })
      .catch((err) => {
        message.error(
          err.message ?? "Get data for Appointment Time-slot Overview failed"
        );
        setTotal(initTotal);
        setSlotData([]);
        setCommands([]);
        setDepartments([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTimeSlotOverview(accessToken, date, siteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, siteId]);

  return (
    <>
      <Row>
        <Col xs={8}>
          <DatePicker
            allowClear={false}
            defaultValue={date ? date : moment()}
            onChange={(value: Moment | null) => {
              setDate(value);
            }}
            style={{ width: 300, marginBottom: 20 }}
          />
        </Col>
        <Col span={8}></Col>
        <Col span={8}>
          <Select
            allowClear={false}
            placeholder="Please Select Medical Centre"
            value={siteId}
            style={{ width: "100%" }}
            onChange={(value: number) => {
              setSiteId(value);
              fetchTimeSlotOverview(accessToken, date, value);
            }}
          >
            {sites &&
              sites.map((site: any) => (
                <Select.Option key={site?.id} value={site?.id}>
                  {site?.name}
                </Select.Option>
              ))}
          </Select>
        </Col>
      </Row>
      <Table
        bordered
        rowKey={(value) => `${value.start_time} - ${value.end_time}`}
        dataSource={slotData}
        loading={loading}
        pagination={false}
        className="summaries-table"
        style={{ overflow: "auto" }}
      >
        <Column
          title="Time"
          align="center"
          render={(value) => {
            return `${moment(value?.start_time, TIME_FORMAT).format(
              TIME_FORMAT
            )} - ${moment(value?.end_time, TIME_FORMAT).format(TIME_FORMAT)}`;
          }}
        />
        {slotData && slotData.length !== 0 && (
          <Column
            title={
              <span>
                <Row style={{ height: 98 }} justify="center" align="middle">
                  Total
                </Row>
                <Divider style={{ margin: "16px 0" }} />
                <span
                  style={{
                    color:
                      (total?.completed ?? 0) < (total?.scheduled ?? 0)
                        ? "red"
                        : "",
                    whiteSpace: "nowrap",
                    fontWeight: 400,
                  }}
                >
                  {total?.scheduled
                    ? `${total?.completed ?? 0}/${total?.scheduled ?? 0}`
                    : "-"}
                </span>
              </span>
            }
            align="center"
            render={(value) => {
              return (
                <span
                  style={{
                    color:
                      (value?.total?.completed ?? 0) <
                      (value?.total?.scheduled ?? 0)
                        ? "red"
                        : "",
                  }}
                >
                  {value?.total?.scheduled
                    ? `${value?.total?.completed ?? 0}/${
                        value?.total?.scheduled ?? 0
                      }`
                    : "-"}
                </span>
              );
            }}
          />
        )}
        {commands?.length > 0 &&
          commands.map((command: any) => {
            return (
              <ColumnGroup
                className="command-header"
                title={() => (
                  <span style={{ textTransform: "capitalize" }}>
                    {command.name}
                  </span>
                )}
                align="center"
              >
                {command.units.map((unit: any, index: number) => {
                  return (
                    <Column
                      className={
                        index < command.units.length - 1 ? "not-last-unit" : ""
                      }
                      title={
                        <span>
                          <Row
                            style={{ height: 43 }}
                            justify="center"
                            align="middle"
                          >
                            {unit.name}
                          </Row>
                          <Divider style={{ margin: "16px 0" }} />
                          <span
                            style={{
                              color:
                                (unit?.total?.completed ?? 0) <
                                (unit?.total?.scheduled ?? 0)
                                  ? "red"
                                  : "",
                              whiteSpace: "nowrap",
                              fontWeight: 400,
                            }}
                          >
                            {unit?.total?.scheduled
                              ? `${unit?.total?.completed ?? 0}/${
                                  unit?.total?.scheduled ?? 0
                                }`
                              : "-"}
                          </span>
                        </span>
                      }
                      align="center"
                      render={(value) => {
                        const data = value?.summary.filter((element: any) => {
                          return element.unit_id === unit.id;
                        });
                        return data && data.length ? (
                          <span
                            style={{
                              color:
                                (data[0]?.completed ?? 0) <
                                (data[0]?.scheduled ?? 0)
                                  ? "red"
                                  : "",
                            }}
                          >
                            {data[0]?.scheduled
                              ? `${data[0]?.completed ?? 0}/${
                                  data[0]?.scheduled ?? 0
                                }`
                              : "-"}
                          </span>
                        ) : (
                          "-"
                        );
                      }}
                    />
                  );
                })}
              </ColumnGroup>
            );
          })}
        {departments?.length > 0 && (
          <>
            {departments.map((department, index) => {
              return (
                <Column
                  className={
                    index === departments.length - 1 ? "last-department" : ""
                  }
                  title={
                    <span>
                      <Row
                        style={{ height: 98 }}
                        justify="center"
                        align="middle"
                      >
                        {department.name}
                      </Row>
                      <Divider style={{ margin: "16px 0" }} />
                      <span
                        style={{
                          color:
                            (department?.total?.completed ?? 0) <
                            (department?.total?.scheduled ?? 0)
                              ? "red"
                              : "",
                          whiteSpace: "nowrap",
                          fontWeight: 400,
                        }}
                      >
                        {department?.total?.scheduled
                          ? `${department?.total?.completed ?? 0}/${
                              department?.total?.scheduled ?? 0
                            }`
                          : "-"}
                      </span>
                    </span>
                  }
                  align="center"
                  render={(value) => {
                    const data = value?.summary.filter((element: any) => {
                      return element.department_id === department.id;
                    });
                    return data && data.length ? (
                      <span
                        style={{
                          color:
                            (data[0]?.completed ?? 0) <
                            (data[0]?.scheduled ?? 0)
                              ? "red"
                              : "",
                        }}
                      >
                        {data[0]?.scheduled
                          ? `${data[0]?.completed ?? 0}/${
                              data[0]?.scheduled ?? 0
                            }`
                          : "-"}
                      </span>
                    ) : (
                      "-"
                    );
                  }}
                />
              );
            })}
          </>
        )}
        {!slotData.length && (
          <Column
            title={
              <span>
                <Row style={{ height: 77 }} justify="center" align="middle">
                  Total
                </Row>
                <Divider style={{ margin: "16px 0" }} />
                <span style={{ fontWeight: 400 }}>-</span>
              </span>
            }
            align="center"
          />
        )}
        {!commands.length && !departments.length && (
          <>
            <ColumnGroup
              className="command-header"
              title={"Command"}
              align="center"
            >
              <Column
                title={
                  <span>
                    Unit
                    <Divider style={{ margin: "16px 0" }} />
                    <span style={{ fontWeight: 400 }}>-</span>
                  </span>
                }
                align="center"
                render={() => "-"}
              />
              <Column
                title={
                  <span>
                    Unit
                    <Divider style={{ margin: "16px 0" }} />
                    <span style={{ fontWeight: 400 }}>-</span>
                  </span>
                }
                align="center"
                render={() => "-"}
              />
            </ColumnGroup>
            {roles?.data?.role_id !== ROLE_USER.COMMAND_ADMIN_ID && (
              <Column
                className="last-department"
                title={
                  <span>
                    <Row style={{ height: 77 }} justify="center" align="middle">
                      Department
                    </Row>
                    <Divider style={{ margin: "16px 0" }} />
                    <span style={{ fontWeight: 400 }}>-</span>
                  </span>
                }
                align="center"
                render={() => "-"}
              />
            )}
          </>
        )}
      </Table>
    </>
  );
}

export default ScheduledAdminAppointmentTimeSlotOverview;
