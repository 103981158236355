import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
// context
import { useAuth } from "../../context/auth";

export default function ResultPage() {
  const { instance } = useAuth();
  const logout = async () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/"
    });
  };

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary" onClick={logout}>
          <Link to="/">Back Home</Link>
        </Button>
      }
    />
  );
}
