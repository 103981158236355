import { Statistic } from "antd";
import React from "react";
import "./style.less";

type PropsType = {
  allocated: number;
  total: number;
};
export default function Overview({ allocated, total }: PropsType) {

  return (
    <Statistic
      className="statistic-custom"
      title="Allocated"
      value={(total === 0 || total === null) ? `_` : `${total}`}
    />
  );
}
