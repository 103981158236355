import {
  Col,
  DatePicker,
  Form,
  message,
  Select,
  Row,
  Typography,
  Input,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/auth";
import { useRequest } from "../../swr";
import { IUnit } from "../../types/unit.model";
import PersonnelAPI from "../../api/personnel";
import { DATE_FORMAT, DATE_FORMAT_VC_ADMIN, ROLE_USER } from "../../constants";
import { EditOutlined } from "@ant-design/icons";
import { RangePickerProps } from "antd/es/date-picker";
import "./style.less";
import _ from "lodash";

const { Paragraph } = Typography;
const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
type PropsType = {
  nric?: string;
  id?: number;
  personnelInfo: any;
  handleFetchInfoWithIdOrNRIC: any;
  unitList: any[];
  allergiesList: any[];
  canEdit: boolean;
};

export default function EditPersonnel({
  nric,
  id,
  personnelInfo,
  handleFetchInfoWithIdOrNRIC,
  unitList,
  allergiesList,
  canEdit,
}: PropsType) {
  const { accessToken, roles } = useAuth();
  const [form] = Form.useForm();
  const [disabledDateOfBirth, setDisabledDateOfBirth] = useState<boolean>(true);
  const [disabledGender, setDisabledGender] = useState<boolean>(true);
  const [editItem, setEditItem] = useState<any>(null!);
  const { data: gendersOption, error: gendersOptionError } = useRequest(
    "GET",
    "/vaccination/personnels/options/genders"
  );
  if (gendersOptionError?.message) {
    message.error(gendersOptionError?.message || "Get gender list failed");
  }

  useEffect(() => {
    form.resetFields();
  }, [personnelInfo]);

  useEffect(() => {
    handleFetchInfoWithIdOrNRIC(accessToken, id, nric);
    setDisabledDateOfBirth(true);
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nric, id]);

  const updatePersonnel = (token: string, payload: any) => {
    const personnelId = id ?? personnelInfo?.id;
    PersonnelAPI.updatePersonnel(token, personnelId, payload)
      .then((res) => {
        handleFetchInfoWithIdOrNRIC(accessToken, id, nric);
        message.success(res?.message ?? "Update personnel successfully!");
        setDisabledDateOfBirth(true);
        setDisabledGender(true);
      })
      .catch((err) => {
        setDisabledDateOfBirth(true);
        setDisabledGender(true);
        message.error(err?.message ?? "Update personnel failed!");
      });
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > moment().endOf("day");
  };

  useEffect(() => {
    if (editItem) {
      const payload = {
        name: personnelInfo?.name || "name",
        dob: moment(personnelInfo?.dob).format(DATE_FORMAT),
        unit_id: personnelInfo?.unit_id,
        phone_number: personnelInfo?.phone_number || "+6512345678",
        personnel_type: personnelInfo?.personnel_id,
        remarks: personnelInfo?.remarks,
        allergy_ids: personnelInfo?.allergy_id?.split(",")?.map((x) => +x),
        gender: personnelInfo?.gender?.id,
        ...editItem,
      };
      updatePersonnel(accessToken, payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem]);

  const fields = [
    {
      label: "Name",
      name: "name",
      type: <Input style={{ width: "70%"}}  />,
      require: true,
    },
    {
      label: "Phone Number",
      name: "phone_number",
      type: <Input style={{ width: "70%"}}  />,
      require: true,
    },
    {
      label: "Date of Birth",
      name: "dob",
      type: (
        <DatePicker
		  disabled={!canEdit}
          className="custom-datapicker"
          format={DATE_FORMAT_VC_ADMIN}
          style={{ width: "70%" }}
          disabledDate={disabledDate}
        />
      ),
      require: true,
    },
    {
      label: "Gender",
      name: "gender",
      type: (
        <Select
          className="custom-disabledColor"
          style={{ width: "70%" }}
          options={gendersOption?.map((gender: any) => ({
            label: gender.name,
            value: gender.id,
          }))}
        />
      ),
      require: false,
    },
    {
      label: "Remarks",
      name: "remarks",
      type: <Input style={{ width: "70%"}}  />,
      require: false,
    },
    {
      label: "Unit / Department",
      name: "unit_id",
      require: false,
      type: (
        <Select
          className="custom-disabledColor"
          style={{ width: "70%" }}
          options={unitList?.map((unit: any) => ({
            label: unit.name,
            value: unit.id,
          }))}
        />
      ),
    },
    {
      label: "Allergies",
      name: "allergy_ids",
      require: true,
      type: (
        <Select
          className="custom-disabledColor"
          mode="multiple"
          style={{ width: "70%"}}
          options={allergiesList?.map((allergy: any) => ({
            label: allergy.name,
            value: Number(allergy.id),
          }))}
        />
      ),
    },
  ];

  return personnelInfo ? (
    <Form
      form={form}
      disabled={!canEdit}
      colon={false}
      requiredMark={false}
      {...formItemLayout}
      onFieldsChange={_.debounce((field: any, allField: any) => {
        if (field[0]?.errors?.length) return
        const key = field[0].name[0];
        let value = field[0].value;
        if (key === "dob") value = moment(value).format(DATE_FORMAT);
        setEditItem({ [key]: value });
      }, 500)}
      initialValues={{
        name: personnelInfo.name,
        phone_number: personnelInfo?.phone_number.lastIndexOf("+65") == 0 ? personnelInfo?.phone_number?.substring(3, personnelInfo?.phone_number.length) : personnelInfo?.phone_number,
        dob: personnelInfo.dob ? moment(personnelInfo.dob) : undefined,
        gender: personnelInfo.gender.id,
        remarks: personnelInfo.remarks,
        allergy_ids: personnelInfo.allergy_id
          ?.split(",")
          ?.map((x) => Number(x)),
        unit_id: personnelInfo.unit_id,
      }}
    >
      <Row justify={"space-between"}>
        {fields.map(
          (f: { name: string; label: string; type: any; require: boolean }) => {
            return (
              <Col key={f.name} xs={24} md={12}>
                <Form.Item
                  label={<strong>{f.label}</strong>}
                  name={f.name}
                  rules={[
                    { required: f.require, message: `${f.label} is required` },
                  ]}
				  labelAlign="left"
                >
                  {f.type}
                </Form.Item>
              </Col>
            );
          }
        )}
      </Row>
    </Form>
  ) : (
    <></>
  );
}
