/**
 * @author ming.leng
 */
import React, { useState } from "react";
import { Modal, Button, Form, Radio, Input } from "antd";
import { useAuth } from "../../../../context/auth";
import "./style.less";

const { TextArea } = Input;

export type PropsType = {
  visible: boolean;
  handleCancelModal: () => void;
  reject: Function;
};

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailFormItemLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function RejectModal({ visible, handleCancelModal, reject }: PropsType) {
  const { accessToken } = useAuth();
  const [value, setValue] = useState<any>(null!);
  const [form] = Form.useForm();
  const onCancel = () => {
    handleCancelModal();
    setValue(null!)
    form.resetFields();
  };
  const onFinish = (fieldsValue: any) => {
    const payload: any =
      fieldsValue["remarks"] === "Other reasons"
        ? {
            remarks: fieldsValue["other"],
            action: "reject",
          }
        : (fieldsValue["remarks"] === "Ineligible for vaccination" ?
          {
            remarks: `${fieldsValue["ineligible_reason"]}. Certified by ${fieldsValue["ineligible_for_vaccination"]}`,
            action: "ineligible"
          }
        : { action: "reject", remarks: fieldsValue["remarks"] });
    reject(accessToken, payload);
    onCancel();
  };

  return (
    <Modal title="Reject" visible={visible} onCancel={onCancel} footer={null}>
      <Form
        {...formItemLayout}
        form={form}
        layout="horizontal"
        name="reject"
        onFinish={onFinish}
        className="reject-form"
      >
        <p>
          You need to select a reason before continue rejecting this personnel
        </p>
        <Form.Item
          name="remarks"
          rules={[{ required: true, message: "Please Select A Reason!" }]}
          style={{ marginLeft: 40 }}
        >
          <Radio.Group
            onChange={(event) => setValue(event.target.value)}
            value={value}
          >
            <Radio value={"Unable to turn up due to scheduling"}>
              Unable to turn up due to scheduling
            </Radio>
            <br />
            <Radio value={"Ineligible for vaccination"}>
              Ineligible for vaccination.
              { value === "Ineligible for vaccination" &&
                <>
                  <Form.Item
                    label="Certified by"
                    name="ineligible_for_vaccination"
                    style={{ margin: "8px 0 8px 0" }}
                    rules={[
                      {
                        required: value === "Ineligible for vaccination",
                        message: "Please enter the name of Medical Staff!",
                      }
                    ]}
                  >
                    <Input disabled={value !== "Ineligible for vaccination"}/>
                  </Form.Item>
                  <Form.Item
                    label="Reason"
                    name="ineligible_reason"
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        required: value === "Ineligible for vaccination",
                        message: "Please enter the reason!",
                      }
                    ]}
                  >
                    <Input disabled={value !== "Ineligible for vaccination"}/>
                  </Form.Item>
                </>
              }
            </Radio>
            <br />
            <Radio value={"Sick/on MC"}>Sick/on MC</Radio>
            <br />
            <Radio value={"Other reasons"}>Other reasons</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="other"
          style={{ marginLeft: 40 }}
          rules={[
            {
              required: value === "Other reasons",
              message: "Please enter A Reason!",
            },
          ]}
        >
          <TextArea disabled={value !== "Other reasons"}></TextArea>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default RejectModal;
