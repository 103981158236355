import React, { useState } from "react";
import { Layout } from "antd";

// components
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import Sider from "../../components/layout/Sider";

// navigation
import { useRootRouter } from "../../routes/RootRouter";

import { useAuth } from "../../context/auth";
import useAuthRole from "../../hook/useAuthRole";

const { Content } = Layout;

export default function DashboardContainer(props: any) {
  const { children } = props;
  const { accessToken } = useAuth();
  const { data } = useAuthRole(accessToken);
  const [isOpenSideBar, setIsOpenSideBar] = useState(false);
  const rootRouter = useRootRouter(data?.data?.name);

  return (
    <Layout>
      <Header isOpenSideBar={isOpenSideBar} setIsOpenSideBar={setIsOpenSideBar}></Header>
      <Layout>
        <Sider navigation={rootRouter} isOpenSideBar={isOpenSideBar} setIsOpenSideBar={setIsOpenSideBar}/>
        <Layout>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: "calc(100vh - 100px)",
            }}
          >
            {children}
          </Content>
          <Footer></Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}
