/**
 * @author ming.leng
 */
import React, { useEffect, useState } from "react";
import { Card, Typography, message, Button, Modal, Space } from "antd";
import { ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DashboardContainer from "../../layout/Dashboard";
import { useAuth } from "../../context/auth";
import Table, { ColumnsType } from "antd/lib/table";
import SiteAPI from "../../api/site";
import {
  ICreateSitePayload,
  ISite,
  IUpdateSitePayload,
} from "../../types/site.model";
import CreateModal from "./Modal/Create";
import UpdateModal from "./Modal/Update";
import { PAGE_NAME } from "../../constants";
const { Title } = Typography;
const { confirm } = Modal;

function SiteManagement() {
  const { accessToken } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [siteList, setSiteList] = useState<ISite[]>(null!);
  const [selectedSite, setSelectedSite] = useState<ISite>(null!);

  const [visibleCreate, setVisibleCreate] = useState<boolean>(false);
  const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);

  const showDeleteConfirm = (id: any) => {
    confirm({
      title: `Are you sure delete this ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handelDelelteSite(id);
      },
    });
  };

  const handelDelelteSite = (id: any) => {
    SiteAPI.delete(id, accessToken)
      .then(({ message: msg }) => {
        message.success(msg);
        fetchSiteList(accessToken);
      })
      .catch((err) => {
        message.error(err?.message ?? `Delete ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1} failed`);
      });
  };

  const fetchSiteList = (token: string) => {
    setLoading(true);
    return SiteAPI.list(token)
      .then(({ data }) => {
        const total = data?.length;
        if (total === 0) {
          //message.warning(`No records found`);
        }
        setLoading(false);
        setSiteList(data?.map((item: ISite, index: number) => { return { ...item, no: index + 1 } }));
      })
      .catch((err) => {
        setLoading(false);
        setSiteList(null!);
        message.error(err?.message ?? `Can not get ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1} information`);
      });
  };

  const createSite = (token: string, payload: ICreateSitePayload) => {
    return SiteAPI.create(token, payload)
      .then(() => {
        fetchSiteList(accessToken);
        message.success(`Create ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1} successfully`);
      })
      .catch((err) => {
        message.error(err?.message ?? `Create ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1} failed`);
      });
  };
  const updateSite = (
    token: string,
    id: number,
    payload: IUpdateSitePayload
  ) => {
    return SiteAPI.update(token, id, payload)
      .then(() => {
        fetchSiteList(accessToken);
        message.success(`Update ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1} successfully`);
      })
      .catch((err) => {
        message.error(err?.message ?? `Update ${PAGE_NAME.MEDICAL_CENTRE.LABEL_1} failed`);
      });
  };

  useEffect(() => {
    fetchSiteList(accessToken);
  }, []);

  const columns: ColumnsType<ISite> = [
    {
      title: "ID",
      dataIndex: "no",
      width: 100,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: "Action",
      width: 80,
      render: (index: number, record: ISite, value: number) => (
        <div key={index}>
          <Space size={5}>
            <Button
              type="primary"
              onClick={() => {
                setSelectedSite(record);
                setVisibleUpdate(true);
              }}
			  icon={<EditOutlined />}
            >
            </Button>
            <Button
              danger
              onClick={() => {
                showDeleteConfirm(record?.id ?? null);
              }}
			  icon={<DeleteOutlined />}
            >
            </Button>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <DashboardContainer>
      <Card
        title={
          <Title level={3}>
            {PAGE_NAME.MEDICAL_CENTRE.LABEL}
          </Title>
        }
        extra={
          <>
            <div></div>
            <Button
              className="btn-create btn-default"
              type="primary"
              onClick={() => setVisibleCreate(true)}
            >
              Create
            </Button>
          </>
        }
      >
        <Table
          rowKey={(record) => record?.id}
          className="table-custom"
          columns={columns}
          dataSource={siteList}
          loading={loading}
          scroll={{ x: 800 }}
        />
        <CreateModal
          visible={visibleCreate}
          handleCancelModal={() => setVisibleCreate(false)}
          createSite={createSite}
        />
        <UpdateModal
          visible={visibleUpdate}
          selectedSite={selectedSite}
          handleCancelModal={() => setVisibleUpdate(false)}
          updateSite={updateSite}
        />
      </Card>
    </DashboardContainer>
  );
}

export default SiteManagement;
