import React, { useEffect, useState } from "react";
import { Space, Table, Button } from "antd";
import moment from "moment";
import { ColumnsType } from "antd/es/table/interface";
import { DATE_FORMAT_VC_ADMIN, PAGE_NAME } from "../../../constants";
import { getStatusColor } from "../../../utils/helper";
import { IInjection } from "../../../types/personnel.model";
import { appointmentsColumnWidth } from "../PersonnelManagement";

type PropsType = {
  nric?: string;
  id?: number;
  personnelInfo?: any;
  handleVaccineType: (type: number) => void;
  setVisibleCheckInModal: (state: boolean) => void;
  setVisibleRejectModal: (state: boolean) => void;
  setAppointmentId: (state: string) => void;
};

const PersonnelInfo = ({
  nric,
  id,
  personnelInfo,
  handleVaccineType,
  setVisibleCheckInModal,
  setVisibleRejectModal,
  setAppointmentId,
}: PropsType) => {
  const [injection, setInjection] = useState<IInjection[]>(null!);

  const columns: ColumnsType<any> = [
    {
      title: PAGE_NAME.MEDICAL_CENTRE.LABEL_1,
      dataIndex: "site_name",
      width: appointmentsColumnWidth.mc,
    },
    {
      title: "Date",
      dataIndex: "date",
      width: appointmentsColumnWidth.date,
      render: (value) => {
        return value === null
          ? "-"
          : moment(value).format(DATE_FORMAT_VC_ADMIN).toUpperCase();
      },
    },
    {
      title: "Time",
      width: appointmentsColumnWidth.time,
      render: (value) => `${value.start_time} - ${value.end_time}`,
    },
    {
      title: "Vaccine",
      onCell: () => ({
        colSpan: 4,
        style: { padding: 0 },
      }),
      onHeaderCell: () => ({ width: appointmentsColumnWidth.vaccine }),
      render: (a: any, b: any) => {
        return (
          <Table
            className="no-margin personnel-table"
            pagination={false}
            showHeader={false}
            dataSource={b.vaccines}
            columns={[
              {
                title: "Vaccine",
                dataIndex: "vaccine_name",
                key: "vaccine_name",
                width: appointmentsColumnWidth.vaccine,
                render: (a: any, b: any) => `${b.vaccine_name || "-"} / ${b.brand || "-"}`
              },
              {
                title: "Dose No",
                dataIndex: "order_no",
                key: "order_no",
                align: "center",
                width: appointmentsColumnWidth.dose_no,
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                width: appointmentsColumnWidth.status,
                render: (value) => (
                  <span
                    style={{
                      background: getStatusColor(value),
                      color: "#fff",
                      fontWeight: "bold",
                      padding: "2px 5px",
                    }}
                  >
                    {value}
                  </span>
                ),
              },
              {
                title: "Action",
                key: "action",
                render: (a: any, b: any) => {
                  return (
                    <Space>
                      <Button
                        onClick={() => {
                          handleVaccineType(b.vaccine_type_id);
                          setVisibleCheckInModal(true);
                          setAppointmentId(b.id);
                        }}
                        type="primary"
                      >
                        Vaccinate
                      </Button>
                      <Button
                        onClick={() => {
                          setVisibleRejectModal(true);
                          setAppointmentId(b.id);
                        }}
                        danger
                      >
                        Reject
                      </Button>
                    </Space>
                  );
                },
              },
            ]}
          />
        );
      },
    },
    {
      title: "Dose No",
      onCell: () => ({ colSpan: 0 }),
      onHeaderCell: () => ({ style: { width: appointmentsColumnWidth.dose_no } }),
    },
    {
      title: "Status",
      onCell: () => ({ colSpan: 0 }),
      onHeaderCell: () => ({ style: { width: appointmentsColumnWidth.status } }),
    },
    {
      title: "Action",
      onCell: () => ({ colSpan: 0 }),
    },
  ];

  useEffect(() => {
    if (nric && personnelInfo) {
      setInjection(personnelInfo.appointments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personnelInfo]);

  return (
    <>
      {/* <Text strong style={{ marginBottom: "10px", display: "block" }}>
        Injection log
      </Text> */}
      <Table
        rowKey={(value) => value.order_no}
        columns={columns}
        dataSource={injection ?? []}
        pagination={false}
        scroll={{ x: 800 }}
      />
    </>
  );
};

export default PersonnelInfo;
