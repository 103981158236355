import React, { useEffect, useState } from "react";
import { authContext } from "../../context/auth";
import useAuthRole from "../../hook/useAuthRole";
import { useMsal } from "@azure/msal-react";
import { getCookie, setCookie } from "../../utils/helper";
import jwt_decode from "jwt-decode";
import { AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";

export const ProtectedAuth = ({ children }: any) => {
    const { instance } = useMsal();
    const [token, setToken] = useState(getCookie('idToken'));
    const { data: role, isLoading } = useAuthRole(token);

    const reNewToken = () => {
        const idTokenClaimns: any = instance.getAllAccounts()[0].idTokenClaims;
        const forceRefresh = (new Date(idTokenClaimns.exp + 1000) <= new Date());
        console.log('use force refresh token : ', forceRefresh)
        instance.acquireTokenSilent({ account: instance.getAllAccounts()[0], scopes: ['User.Read'], forceRefresh })
            .then((res: AuthenticationResult) => {
                console.log('----------------start refreshed token------------------')
                // console.log("new ID token", res.idToken);
                const jwtdecoded: any = jwt_decode(res.idToken);
                console.log('current time:', new Date().toUTCString());
                // console.log('expired on: ', new Date(jwtdecoded?.exp * 1000).toUTCString());
                setCookie('idToken', res?.idToken, new Date(jwtdecoded?.exp * 1000));
                setToken(res?.idToken);
                console.log('----------------end refreshed token------------------');
            })
            .catch(err => {
                //Acquire token silent failure, and send an interactive request
                if (err instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenPopup({ account: instance.getAllAccounts()[0], scopes: ['User.Read'] })
                        .then((res: AuthenticationResult) => {
                            const jwtdecoded: any = jwt_decode(res.idToken);
                            setCookie('idToken', res?.idToken, new Date(jwtdecoded?.exp * 1000));
                            setToken(res?.idToken);
                        })
                        .catch(function (error) {
                            // Acquire token interactive failure
                            console.log(error);
                        });
                }
            });
    }

    useEffect(() => {
        const timeCheckToken = setInterval(() => {
            if (instance.getAllAccounts()[0] && !getCookie('idToken')) {
                reNewToken();
            }
        }, 5000);
        return () => clearInterval(timeCheckToken)
    }, []);

    return (
        <authContext.Provider value={{
            instance,
            accessToken: token,
            roles: role,
            isLoading,
            setAccessToken: (access_token: string) => setToken(access_token)
        }}>
            {children}
        </authContext.Provider>
    );
};