import React from "react";
import { Card, Col, message, Row, Typography } from "antd";

// components
import DashboardContainer from "../../../layout/Dashboard";
import RoundChart from "../../../components/Chart/RoundChart";
import QuantityBox from "../../../components/Chart/QuantityBox";
// import AppointmentTimeSlotOverview from "../../../components/Tables/AppointmentTimeslotOverview";
import ChartOverall from "../../../components/Chart/ChartOverall";
import { IInjectionSum } from "../../../types/summary.model";
import { useRequest } from "../../../swr";
import ScheduledAdminAppointmentTimeSlotOverview from "../../../components/Tables/ScheduledAdminAppointmentTimeSlotOverview";
import ScheduledAdminScheduledTimeSlotOverview from "../../../components/Tables/ScheduledAdminScheduledTimeSlotOverview";

const { Title } = Typography;

export default function Dashboard() {
  const { data: summaries, error } = useRequest(
    "GET",
    `/vaccination/summaries/injection_status`
  );

  const summariesData: IInjectionSum[] = summaries?.data ?? [];
  if (error?.message) {
    message.error(error?.message ?? "Can not get summaries");
  }

  const totalOfProcess = summariesData.length
    ? summariesData.reduce((a, b) => {
        return {
          process: {
            no_completed: a.process?.no_completed + b.process?.no_completed,
            no_personnels: a.process?.no_personnels + b.process?.no_personnels,
          },
        };
      })
    : {
        process: {
          no_completed: 0,
          no_personnels: 0,
        },
      };

  const totalOfStandard = summariesData.length
    ? summariesData
        .map((e) => e.summary.not_scheduled.standard)
        .reduce((a, b) => a + b)
    : 0;

  const totalOfReserve = summariesData.length
    ? summariesData
        .map((e) => e.summary.not_scheduled.reserve)
        .reduce((a, b) => a + b)
    : 0;

  return (
    <DashboardContainer>
      <Title style={{ color: "#6b6b6b" }} level={2}>
        Dashboard
      </Title>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Card
            className="card-no-pd"
            title="Scheduled Time-slot Overview"
            style={{
              boxShadow: "1px 2px 6px rgba(0, 0, 0, 0.16)",
              height: "100%",
            }}
          >
            <div
              style={{
                marginTop: -16,
                marginBottom: 16,
              }}
            >
              Total of Scheduled/Total of Allocated
            </div>
            <ScheduledAdminScheduledTimeSlotOverview />
          </Card>
        </Col>
        <Col xs={24}>
          <Card
            className="card-no-pd"
            title="Appointment Time-slot Overview"
            style={{
              boxShadow: "1px 2px 6px rgba(0, 0, 0, 0.16)",
              height: "100%",
            }}
          >
            <div
              style={{
                marginTop: -16,
                marginBottom: 16,
              }}
            >
              Total of Completed/Total of Scheduled
            </div>
            <ScheduledAdminAppointmentTimeSlotOverview />
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={[24, 24]}>
        <Col md={8}>
          <Card
            className="chart-block"
            title="Overall Doses"
            style={{
              boxShadow: "1px 2px 6px rgba(0, 0, 0, 0.16)",
              height: "100%",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <RoundChart
                percent={
                  totalOfProcess.process?.no_personnels !== 0
                    ? Math.round(
                        (totalOfProcess.process?.no_completed /
                          totalOfProcess.process?.no_personnels) *
                          100
                      )
                    : 0
                }
              />
              <div style={{ marginTop: "60px" }}>
                <QuantityBox
                  titleLeft={"Standard"}
                  quantityLeft={totalOfStandard}
                  titleRight={"Reserve"}
                  quantityRight={totalOfReserve}
                />
              </div>
            </div>
          </Card>
        </Col>
        {summariesData?.length !== 0 ? (
          summariesData?.map(
            ({ unit_name, summary, process }: IInjectionSum) => {
              return (
                <Col md={8}>
                  <ChartOverall
                    title={unit_name}
                    status="Not Scheduled"
                    process={process}
                    data={summary}
                  />
                </Col>
              );
            }
          )
        ) : (
          <Col md={8}>
            <ChartOverall
              title="Overview"
              status="Not Scheduled"
              process={{}}
              data={{}}
            />
          </Col>
        )}
      </Row>
    </DashboardContainer>
  );
}
