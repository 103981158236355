import React from "react";
import { Modal } from "antd";
import SingleRegistration from "../../../components/Forms/Personnel/SingleRegistration";

function CreatePersonnelModal({
  visible,
  onCancel,
}: any) {
  return (
    <Modal
      title="Create Personnel"
      visible={visible}
      width="fit-content"
      onCancel={onCancel}
      destroyOnClose
      footer={null}
    >
      <SingleRegistration callback={onCancel}/>
    </Modal>
  );
}

export default CreatePersonnelModal;
