import {
  IGetTimeslotOverviewData,
  IGetTimeslotOverviewDataResponse,
} from "../types/summary.model";

const path = "/vaccination/summaries";

const summariesAPIs = {
  async listAppointmentTimeslots(
    token: string,
    date: string,
    site_id: number | null
  ): Promise<{ data: IGetTimeslotOverviewData[] }> {
    const queryParams = new URLSearchParams({
      date: date.toString(),
    });

    if (site_id) {
      queryParams.append('site_id', site_id.toString())
    }

    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/appointment_timeslots?${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { status, message, data }: IGetTimeslotOverviewDataResponse = await res.json();
    if (status) {
      return { data };
    }
    else {
      throw new Error(message || "Get summaries of Appointment Time-slot Overview failed")
    }
  },
  async listScheduledTimeslots(
    token: string,
    date: string,
    site_id: number | null
  ): Promise<{ data: IGetTimeslotOverviewData[] }> {
    const queryParams = new URLSearchParams({
      date: date.toString(),
    });

    if (site_id) {
      queryParams.append("site_id", site_id.toString());
    }

    //if (vaccine_id) {
      //queryParams.append("vaccine_id", vaccine_id.toString());
    //}

    const res = await fetch(
      // `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/scheduled_timeslots?${queryParams}`, // Old api, maybe use later
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/scheduled_summary?${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { status, message, data }: IGetTimeslotOverviewDataResponse = await res.json();
    if (status) {
      return { data };
    }
    else {
      throw new Error(message || "Get summaries of Scheduled Time-slot Overview failed")
    }
  },
};

export default summariesAPIs;