const path = "/vaccination/notifications";

const notificationAPIs = {
  async resendNotification(token: string, id: number): Promise<any> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${id}/trigger`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { status, data, message } = await res.json();

    if (!status) {
      throw new Error(message);
    }

    return data;
  },
};

export default notificationAPIs;