import {
  ICommand,
  ICreateCommandPayload,
  IGetCommandListResponse,
} from "../types/command.model";

import { IBaseResponse, PayloadGetCommandList } from "../types/base.model";
const path = "/vaccination/commands";

const commandAPIs = {
  async list(
    token: string,
    padload: PayloadGetCommandList
  ): Promise<{ data: ICommand[]; total: number }> {
    const searchParams = new URLSearchParams({});
    if (padload.pagination) {
      searchParams.append(
        "items_per_page",
        padload.pagination.items_per_page.toString()
      );
      searchParams.append("page", padload.pagination.page.toString());
    }
    if (padload.site_id) {
      searchParams.append("site_id", padload.site_id.toString());
    }

    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}?${searchParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data, status, message }: IGetCommandListResponse = await res.json();
    const total: number = data?.length;
    if (status === false) {
      throw new Error(message);
    }
    return { data, total };
  },
  async create(
    token: string,
    payload: ICreateCommandPayload
  ): Promise<IBaseResponse> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
  async update(
    token: string,
    payload: ICreateCommandPayload,
    id: number
  ): Promise<IBaseResponse> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
  async delete(id: number, token: string): Promise<IBaseResponse> {
   const res = await fetch(
    `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${id}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }
   );
    const { status, message }: IBaseResponse = await res.json();
    return { status, message };
  }
};

export default commandAPIs
