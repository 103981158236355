/**
 * @author ming.leng
 */
import React, { useEffect } from "react";
import { Card, Col, message, Result, Row, Typography } from "antd";

import { useRequest } from "../../../swr";

// components
import DashboardContainer from "../../../layout/Dashboard";
import ChartOverall from "../../../components/Chart/ChartOverall";
import { IInjectionSum } from "../../../types/summary.model";
import { IUnit } from '../../../types/unit.model';
import { DatePicker } from 'antd';
import { Cascader } from 'antd';
import { Select } from 'antd';
import { useState } from 'react';
import { getCookie, removeEmptyUrlParams } from '../../../utils/helper';
import { DATE_FORMAT } from '../../../constants/index';
import useAuthRole from '../../../hook/useAuthRole';
import moment from 'moment';
import vaccine from "../../../api/vaccine";
import { useAuth } from "../../../context/auth";

const { Title } = Typography;
const DEFAULT_FILTER = {
  date: null,
  vaccine_id: null,
  site_id: null
}

export default function IndexPage() {
  const { data: role } = useAuthRole(getCookie("idToken"));
  const { accessToken } = useAuth();
  const [filter, setFilter] = useState<{ [key: string]: any }>(DEFAULT_FILTER)
  const [summariesData, setSummaryData] = useState<any>([])

  function handleGetInjectionStatus(vaccine_id: string, site_id: string, date: string = "") {
    vaccine.injectionStatus(accessToken, { vaccine_id, site_id, date })
      .then(d => {
        setSummaryData(d.data);
      })
      .catch(e =>{
        message.error(e.message ?? "Get injection status failed!");
      })
  }

  const { data: sitesData, error: sitesError } = useRequest("GET", "/vaccination/sites");
  const sites: IUnit[] = sitesData?.data ?? [];
  if (sitesError) {
    message.error(sitesError?.message ?? "Can not get medical centre");
  }

  const { data: vaccineData, error: vaccineError } = useRequest("GET", "/vaccination/vaccines/options");
  const vaccineOption: any[] = vaccineData?.data?.map((item: any) => {
    return {
      label: item?.name,
      value: item?.name,
      children: item?.brands?.map((item: any) => {
        return {
          value: item?.id,
          label: item?.name
        }
      }),
    }
  })

  if (vaccineError) {
    message.error(vaccineError?.message ?? "Can not get vaccine");
  }

  const handleDatePickerChange = (value: any) => {
    setFilter({ ...filter, date: value ? moment(value)?.format(DATE_FORMAT) : null })
  }

  const handleVaccineChange=(value:any[]) => {
    setFilter({...filter,  vaccine_id:value? value : null})
   }

  const handleMedicalCentreChange = (value: number) => {
    setFilter({ ...filter, site_id: value ? value : null })
  }

  useEffect(() => {
    setFilter({
      ...filter,
      site_id: role?.data?.assignedSite ? role?.data?.assignedSite : null,
      vaccine_id:[vaccineData?.data?.[0]?.name, vaccineData?.data?.[0]?.brands?.[0]?.id],
      date: moment().format(DATE_FORMAT)
    });
  }, [role,vaccineData]);

  useEffect(() => {
    const {site_id, vaccine_id, date} = filter;
    if (vaccine_id?.[1]) {
      handleGetInjectionStatus(vaccine_id[1], site_id, date);
    }
  }, [filter])

  return (
    <DashboardContainer>
      <Title style={{ color: "#6b6b6b", marginBottom: "0" }} level={2}>
        Dashboard
      </Title>
      <Row gutter={[12, 12]} style={{ marginTop: 24 }} >
          <Card
            className="card-no-pd"
            title="Unit / Department Overview"
            style={{
              boxShadow: "1px 2px 6px rgba(0, 0, 0, 0.16)",
              height: "100%",
              width:"100%"
            }}
          >
            <Row  gutter={[24, 24]} >
              <Col className="gutter-row" lg={{span: 8}} span={24}>
                <DatePicker style={{width:"100%"}} onChange={handleDatePickerChange} defaultValue={filter?.date ? filter?.date : moment()} placeholder="Please select date" />
              </Col>
              <Col className="gutter-row" lg={{span: 8}} span={24}>
                <Cascader style={{width:"100%"}} allowClear={false} options={vaccineOption} value={filter?.vaccine_id} onChange={handleVaccineChange} placeholder="Please select vaccine" />
              </Col>
              <Col className="gutter-row" lg={{span: 8}} span={24}>
                <Select defaultValue={role?.data?.assignedSite} disabled={!!role?.data?.assignedSite} style={{width:"100%"}}  allowClear onChange={handleMedicalCentreChange} placeholder="Please select medical centre">
                  {sites?.length > 0 && sites.map((site) => {
                    return <Select.Option key={site?.id} value={site?.id}>
                      {site?.name}
                    </Select.Option>
                  })}
                </Select>
              </Col>
            </Row>
          </Card>
      </Row>

      <Row gutter={[15, 20]} style={{ marginTop: 24 }}>

          {summariesData?.length !== 0 ? (
            summariesData?.map(
              ({ unit_name, summary, overall, schedule }: IInjectionSum) => {
                return (
                    <Col xs={24} sm={24} lg={8}>
                      <ChartOverall
                        title={unit_name}
                        overall={overall}
                        schedule={schedule}
                        data={summary}
                        vaccineSelected={filter?.vaccine_id}
                        vaccineList={vaccineData?.data}
                      />
                    </Col>
                );
              }
            )
          ) : (<Col xs={24} sm={24} lg={24}>
            <Result
            status="warning"
            subTitle="No data"
          />
        </Col>
        )}
      </Row>


    </DashboardContainer>
  );
}
