/**
 * @author ming.leng
 */
import React, { useEffect } from "react";
import { Modal, Button, Form, Select } from "antd";
import { useAuth } from "../../../../context/auth";
import { ICheckInPayload } from "../../../../types/personnel.model";
import { useRequest } from "../../../../swr";
import { IAvailableVax } from "../../../../types/vaccine.model";
import moment from "moment";
import { DATE_FORMAT_VC_ADMIN } from "../../../../constants";
import { ROLE_USER } from "../../../../constants/index";

export type PropsType = {
  visible: boolean;
  site_id: string;
  vaccine_type_id: number;
  handleCancelModal: () => void;
  checkIn: Function;
};

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailFormItemLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function CheckInModal({
  visible,
  handleCancelModal,
  checkIn,
  site_id,
  vaccine_type_id,
}: PropsType) {
  const { accessToken, roles } = useAuth();
  const [form] = Form.useForm();

  const { data: vaccinesData, mutate: mutateVaccines } = useRequest(
    "GET",
    `/vaccination/vaccines/available?site_id=${site_id}&vaccine_type_id=${vaccine_type_id}`
  );
  const vaccinesList: IAvailableVax[] = vaccinesData?.data ?? [];

  useEffect(() => {
    mutateVaccines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onCancel = () => {
    handleCancelModal();
    form.resetFields();
  };
  const onFinish = (fieldsValue: any) => {
    const payload: ICheckInPayload = {
      ...fieldsValue,
      action: roles?.data?.role_id === ROLE_USER.SUPER_ADMIN_ID ? "check-in-admin" : "check-in",
    };
    checkIn(accessToken, payload);
    onCancel();
  };

  return (
    <Modal title="Vaccinate" visible={visible} onCancel={onCancel} footer={null}>
      <Form
        {...formItemLayout}
        form={form}
        layout="horizontal"
        name="check-in"
        onFinish={onFinish}
      >
        <p style={{ marginLeft: 40 }}>
          You need to select batch number of vaccine before continue
        </p>
        <Form.Item
          label="Batch Number"
          name="vaccine_inventory_id"
          rules={[{ required: true, message: "Please Select Batch Number!" }]}
        >
          <Select placeholder="Batch Number">
            {vaccinesList &&
              vaccinesList?.map((vaccine: IAvailableVax) => {
                return (
                  <Select.Option value={vaccine?.id} key={vaccine?.id}>
                    {vaccine?.batch_number} | {vaccine?.brand} |{" "}
                    {
                      moment(vaccine?.expiry_date)
                        .format(DATE_FORMAT_VC_ADMIN)
                        .toUpperCase()
                    }
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Submit
          </Button>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CheckInModal;
