/**
 * @author ming.leng, trent.tan
 */
import React, { useEffect, useState } from "react";
import { Button, Card, message, Typography, Select, Space } from "antd";

// Component
import DashboardContainer from "../../layout/Dashboard";
import PersonnelManagementTable from "../../components/Tables/PersonnelManagement";
import FilterPersonal from "../../components/Filter/Personal";
import BulkModal from "../../components/Modal/Bulk";

// hook
import { useAuth } from "../../context/auth";
import { useRequest } from "../../swr";

// Modal
import { TablePaginationType } from "../../types/table.model";
import CreatePersonnelModal from "./Modals/createPersonnel";

// fetch API
import PersonnelAPI from "../../api/personnel";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { DATE_FORMAT, ROLE_USER } from "../../constants";
import AssignVaccineModal from "../../components/Modal/AssignVaccineModal";
import PersonnelManagementContext from "./PersonnelManagementContext";

const { Title } = Typography;

const { Option } = Select;

const initPagination: TablePaginationType = {
  pageSize: 10,
  current: 1,
  total: 0,
  orderBy: undefined,
  orderType: undefined,
};

interface IFilter {
  injection_date?: string;
  injection_order?: string[];
  personnel_type?: string;
  unit_id?: string;
  site_id: number;
  unit_timeslot_id: any;
  filter_records: number;
}

export default function PersonnelManagement() {
  /**
   * @description auth hook
   */
  const { accessToken, roles } = useAuth();

  /**
   * @description management state key down or select in table
   */
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  /**
   * @description management state
   */
  const [personnelList, setPersonnelList] = useState<any[]>([]);

  /**
   * @description management state
   */
  const [pagination, setPagination] =
    useState<TablePaginationType>(initPagination);

  /**
   * @description management state loading
   * @function useState
   */
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * @description management state for hidden/show swap modal
   * @function useState
   * @param true/false
   */
  const [visibleBulk, setVisibleBulk] = useState<boolean>(false);
  const [visibleAssignVaccine, setVisibleAssignVaccine] =
    useState<boolean>(false);

  /**
   * @description management state for hidden/show swap modal
   * @function useState
   * @param true/false
   */
  const [visibleCreate, setVisibleCreate] = useState<boolean>(false);

  /**
   * @description management state filter data
   * @function useState
   */
  //   const [unitList, setUnitList] = useState([]);

  /**
   * @description management state filter data
   * @function useState
   */
  const [filter, setFilter] = useState<IFilter>({});
  const [timeSlot, setTimeSlot] = useState<any>([]);
  const [selectedTime, setSelectedTime] = useState<any>([]);

  const [vaccineBrandDefault, setVaccineBrandDefault] = useState<any>({});

  const [siteId, setSiteId] = useState(roles?.data?.assignedSite);

  const [eligibleApptChecked, setEligibleApptChecked] = useState(false);
  const [expiredApptChecked, setExpiredApptChecked] = useState(false);

   const [aptRecords, setAptRecords] = useState(6);

   const [statusId, setStatusId] = useState(0);


  /**
   *
   * @param token
   * @param pagination
   * @returns void
   * @description call API get list personal
   */
  const fetchPersonnelInfo = (
    token: string,
    pagination: TablePaginationType,
    filters?: any
  ) => {
    setSelectedRowKeys([]);
    setLoading(true);

    if (eligibleApptChecked === true) {
      filters = { ...filters, eligible_appt: eligibleApptChecked };
    }
    if (expiredApptChecked === true) {
      filters = { ...filters, expired_appt: expiredApptChecked };
    }
    if(aptRecords) {
		filters = {...filters, apt_records_months:aptRecords};
	}
  if(statusId) {
  filters = {...filters, status_id:statusId};
}
    if (filters?.vaccine_brand) {
      filters = { ...filters, vaccine_type_id: filters.vaccine_brand[1] };
    }
    if (filters?.injection_date) {
      filters = {
        ...filters,
        injection_date: filters.injection_date?.format(DATE_FORMAT),
      };
    }
    if (filters?.time) {
      filters = { ...filters, unit_timeslot_id: filters.time };
    }
    if (siteId) {
      filters = { ...filters, site_id: siteId };
    }
    setFilter({
      ...filters,
    });

    return PersonnelAPI.list(
      token,
      {
        items_per_page: pagination.pageSize,
        page: pagination.current,
        order_by: pagination.orderBy,
        order_type: pagination.orderType,
      },
      filters
    )
      .then(({ items, total }) => {
        setLoading(false);
        setPersonnelList(items);
        setPagination({ ...pagination, total });
        return items;
      })
      .catch((error) => {
        setLoading(false);
        setPersonnelList(null!);
        setPagination(initPagination);
        message.error(error.message ?? "Get personnel failed!");
        return false;
      });
  };

  const refreshPersonnelInfo = (
    token: string,
    pagination: TablePaginationType,
    filters?: any
  ) => {
    setSelectedRowKeys([]);
    setLoading(true);

    return PersonnelAPI.list(
      token,
      {
        items_per_page: pagination.pageSize,
        page: pagination.current,
        order_by: pagination.orderBy,
        order_type: pagination.orderType,
      },
      filters
    )
      .then(({ items, total }) => {
        setLoading(false);
        setPersonnelList(items);
        setPagination({ ...pagination, total });
        return items;
      })
      .catch((error) => {
        setLoading(false);
        setPersonnelList(null!);
        setPagination(initPagination);
        message.error(error.message ?? "Get personnel failed!");
        return false;
      });
  };

  /**
   *
   * @param token
   * @param pagination
   * @returns void
   * @description call API get a specific list of personnel be follow the list of personnel_id
   */
  const fetchSpecificPersonnels = (personnelIds: string[]) => {
    setLoading(true);
    PersonnelAPI.list(
      accessToken,
      {
        items_per_page: 10,
        page: 1,
      },
      {
        ids: personnelIds.join(","),
      }
    )
      .then(({ items }) => {
        setLoading(false);
        setPersonnelList(items);
        if (items && items.length !== 0) {
          setPersonnelList(items);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  /**
   *
   * @param
   * @param
   * @returns void
   * @description call API export list
   */
  const exportListPersonalVaccination = (token: string, filters?: any) => {
    setSelectedRowKeys([]);
    PersonnelAPI.export(token, filters)
      .then((data) => {
        const blob = new Blob([data as BlobPart], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `ST_Personnels_Exported_${moment().format(
          "YYYYMMDD_HHmmss"
        )}.csv`;
        a.click();
      })
      .catch((error) => {
        message.error(error.message ?? "Export personnel failed!");
        return false;
      });
  };

  // fetch all units
  const { data, error } = useRequest(
    "GET",
    `/vaccination/units?sort=name&order=asc${
      roles?.data?.role_id === ROLE_USER.DEPARTMENT_ADMIN_ID ? "?type_id=2" : ""
    }`
  );

  // fetch all vaccine brand
  const { data: vacBrands, error: vacBrandsErr } = useRequest(
    "GET",
    `/vaccination/vaccine-types?items_per_page=&page=`
  );

  // fetch all appontment's site
  const { data: sites, error: siteErr } = useRequest(
    "GET",
    `/vaccination/sites?assigned_site_id=${
      roles?.data?.assignedSite ? roles.data.assignedSite : 0
    }`
  );

  const vacBrandsData = vacBrands?.data ?? [];

  const unitList = data?.data ?? [];

  const siteList = sites?.data ?? [];

  if (error?.message) {
    message.error(error?.message ?? "error");
  }
  if (vacBrandsErr?.message) {
    message.error(vacBrandsErr?.message ?? "error");
  }
  if (siteErr?.message) {
    message.error(siteErr?.message ?? "error");
  }

  const handleSearchPersonnel = (filter: any) => {
    fetchPersonnelInfo(
      accessToken,
      {
        ...initPagination,
        orderBy: pagination.orderBy,
        orderType: pagination.orderType,
      },
      filter
    );
}


  const handleSwitchPage = (pagination: TablePaginationType) => {
    refreshPersonnelInfo(accessToken, pagination, filter);
  };

  const handelFilter = (filter: any) => {
    updateVaccineDefault(filter);
    setFilter({
      ...filter,
    });
  };

  const updateVaccineDefault = (filter: any) => {
    let IVaccine = vacBrandsData.find((el: any) => {
      return el.id === filter.vaccine_type_id;
    });
    setVaccineBrandDefault(IVaccine);
  };

  const reloadPage = async () => {
    return await Promise.resolve(
      refreshPersonnelInfo(accessToken, pagination, filter)
    );
  };

  return (
    <PersonnelManagementContext>
      <DashboardContainer>
        <Card>
          <Title level={3} style={{ margin: 0 }}>
            Personnel Management
          </Title>
        </Card>
        <br />
        <FilterPersonal
          vacBrandsData={vacBrandsData}
          siteList={siteList}
          siteID={siteId}
          setSiteId={(site_id: number) => setSiteId(site_id)}
          unitList={unitList}
          handelFilter={(e: any) => handelFilter(e)}
          filter={filter}
          getTimeslot={(timeslot: any) => setTimeSlot(timeslot)}
          getSelectedTime={(time: any) => {
            setSelectedTime(time);
          }}
          initPagination={initPagination}
          handleSearchPersonnel={handleSearchPersonnel}
          setEligibleApptChecked={(eligibleApptChecked: any) =>
            setEligibleApptChecked(eligibleApptChecked)
          }
          setExpiredApptChecked={(expiredApptChecked: any) =>
            setExpiredApptChecked(expiredApptChecked)
          }
		  setAptRecords={(aptRecords: any) =>
            setAptRecords(aptRecords)
          }
          setStatusId={(statusId: any) =>
                setStatusId(statusId)
              }
        />
        <br />
        <Card
          title={
            <>
			<Space>
              <div style={{ display: "flex", alignItems: "center" }}>
                {(roles?.data?.role_id === ROLE_USER.DEPARTMENT_ADMIN_ID ||
                  roles?.data?.role_id === ROLE_USER.UNIT_ADMIN_ID ||
                  roles?.data?.role_id === ROLE_USER.COMMAND_ID ||
                  roles?.data?.role_id === ROLE_USER.SUPER_ADMIN_ID) && (
                  <Button
                    type="primary"
                    disabled={selectedRowKeys && selectedRowKeys.length <= 0}
                    onClick={() => {
                      setVisibleBulk(true);
                      updateVaccineDefault(filter);
                    }}
                  >
                    Assign Appointment
                  </Button>
                )}
                {(roles?.data?.role_id === ROLE_USER.MED_ADMIN_ID
                  || roles?.data?.role_id === ROLE_USER.SUPER_ADMIN_ID) && (
                  <Button style={{margin: "0px 8px"}}
                    type="primary"
                    disabled={
                      (selectedRowKeys && selectedRowKeys.length <= 0) ||
                      !filter?.site_id
                      // ||
                      // !filter?.injection_date ||
                      // !filter?.unit_timeslot_id
                      //||!selectedTime
                    }
                    onClick={() => {
                      setVisibleAssignVaccine(true);
                      updateVaccineDefault(filter);
                    }}
                  >
                    Assign Vaccine
                  </Button>
                )}
              </div>
			  <div style={{ display: "flex", alignItems: "center" }}>
			  <span style={{ padding: "0 10px 0 10px" }}>
                  Total records found: {pagination?.total}
                </span>
			  </div>
			  </Space>
            </>
          }
          extra={
            (roles?.data?.role_id === ROLE_USER.MED_ADMIN_ID ||
              roles?.data?.role_id === ROLE_USER.SUPER_ADMIN_ID) && (
              <Button
                type="primary"
                onClick={() =>
                  exportListPersonalVaccination(accessToken, filter)
                }
                disabled={personnelList && personnelList?.length <= 0}
              >
                <DownloadOutlined /> Export
              </Button>
            )
          }
        >
          <PersonnelManagementTable
            token={accessToken}
            data={personnelList}
            loading={loading}
            pagination={pagination}
            switchPage={handleSwitchPage}
            reloadPage={reloadPage}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            unitList={unitList}
          />
        </Card>
        {visibleBulk && (
          <BulkModal
            token={accessToken}
            visible={visibleBulk}
            onClose={() => {
              setVisibleBulk(false);
              setVaccineBrandDefault({});
              fetchSpecificPersonnels(personnelList.map((e) => e.id));
            }}
            filter={filter}
            personnel={selectedRowKeys}
            vaccineBrandDefault={vaccineBrandDefault}
          />
        )}
        {visibleAssignVaccine && (
          <AssignVaccineModal
            token={accessToken}
            visible={visibleAssignVaccine}
            onClose={() => {
              setVisibleAssignVaccine(false);
              // setVaccineBrandDefault({});
              fetchSpecificPersonnels(personnelList.map((e) => e.id));
            }}
            filter={filter}
            personnel={selectedRowKeys}
            vaccineBrandDefault={vaccineBrandDefault}
            timeslot={timeSlot}
            personnelList={personnelList}
          />
        )}
        <CreatePersonnelModal
          visible={visibleCreate}
          onCancel={() => {
            setVisibleCreate(false);
          }}
        />
      </DashboardContainer>
    </PersonnelManagementContext>
  );
}
