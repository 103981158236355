/**
 * @author ming.leng
 */
import React, { useEffect, useState } from "react";
import {
  Card,
  message,
  Space,
  Button,
  Tabs,
  Select,
  Modal,
  Row,
  Col,
} from "antd";
import DashboardContainer from "../../layout/Dashboard";
import Title from "antd/lib/typography/Title";
import { useAuth } from "../../context/auth";
import VaccineAPI from "../../api/vaccine";
import {
  IVaccineInventory,
  IVaccinesSummary,
  ICreateVaccineInventoryPayload,
  ICreateVaccinePayload,
  IVaccine,
  IUpdateVaccineInventoryPayload,
  IUpdateVaccinePayload,
  IVaccineItem,
  IVaccineOption,
} from "../../types/vaccine.model";
import CreateVaccineInventoryModal from "./Modal/CreateInventory";
import CreateVaccineModal from "./Modal/CreateVaccine";
import OverallInventory from "./Widget/Inventory";
import { PlusOutlined } from "@ant-design/icons";
import { TablePaginationType } from "../../types/table.model";
import VaccineInventory from "../../components/Tables/VaccineInventory";
import Vaccine from "../../components/Tables/Vaccine";
import UpdateVaccineInventoryModal from "./Modal/UpdateInventory";
import { useRequest } from "./../../swr/index";
import { ISite } from "../../types/site.model";
import UpdateVaccineModal from "./Modal/UpdateVaccine";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { PAGE_NAME } from "../../constants";
const { confirm } = Modal;
const { TabPane } = Tabs;

const initPagination: TablePaginationType = {
  pageSize: 5,
  current: 1,
  total: 0,
};

const defaultVaccinesSummary: IVaccinesSummary = {
  quantity: 0,
  scheduled: 0,
  completed: 0,
  rejected: 0,
  remain: 0,
  one_day_left: 0,
  two_days_left: 0,
  three_days_left: 0,
  // quantity: 0,
  // capacity: 0,
  // book: 0,
  // used: 0,
  // expires: 0,
  // remains: {
  //   1: 0,
  //   2: 0,
  //   3: 0,
  //   others: 0,
  // },
};

const defaultVaccineOptions: IVaccineOption[] = [
  {
    name: "Covid-19",
    brands: [
      { id: 1, name: "sinovac" },
      { id: 2, name: "Plifer" },
      { id: 3, name: "Moderna" },
    ],
  },
  {
    name: "H5N1",
    brands: [
      { id: 1, name: "USA" },
      { id: 2, name: "China" },
      { id: 3, name: "Brazil" },
    ],
  },
];

function VaccineManagement() {
  const { accessToken, roles } = useAuth();
  const [vaccInventory, setVaccInventory] = useState<IVaccineInventory[]>(
    null!
  );
  const [invtLoading, setInvtLoading] = useState<boolean>(false);
  const [invtPagination, setInvtPagination] =
    useState<TablePaginationType>(initPagination);
  const [vaccines, setVaccines] = useState<IVaccine[]>(null!);
  const [vaccLoading, setVaccLoading] = useState<boolean>(false);
  const [vaccPagination, setVaccPagination] =
    useState<TablePaginationType>(initPagination);

  const [visibleAddInvtModal, setVisibleAddInvtModal] =
    useState<boolean>(false);
  const [visibleUpdateInvtModal, setVisibleUpdateInvtModal] =
    useState<boolean>(false);
  const [visibleAddVaccModal, setVisibleAddVaccModal] =
    useState<boolean>(false);

  const [visibleUpdateModal, setVisibleUpdateModal] = useState<boolean>(false);
  const [selectedUnitInventory, setSelectedUnitInventory] =
    useState<IVaccineInventory>(null!);

  const [selectedVaccineItem, setSelectedVaccineItem] = useState<IVaccineItem>(
    null!
  );

  const [query, setQuery] = useState<any>({});

  const { data: sitesData, error } = useRequest("GET", "/vaccination/sites");

  const sites: ISite[] = sitesData?.data ?? [];
  if (error?.message) {
    message.error(error?.message ?? "error");
  }

  const {
    data: vaccinationNames,
    error: err,
    mutate: mutateVaccineName,
  } = useRequest("GET", "/vaccination/vaccines/options");
  const vaccine_names: IVaccineOption[] =
    vaccinationNames?.data ?? defaultVaccineOptions;
  if (err?.message) {
    message.error(err?.message ?? "error");
  }

  const fetchVaccineInventory = (
    token: string,
    pagination: TablePaginationType
  ) => {
    setInvtLoading(true);
    const payload: any = {};
    if (query) {
      if (!!roles.data.assignedSite) {
        payload.site_id = roles.data.assignedSite;
      } else {
        if (query.site_id) {
          payload.site_id = query.site_id;
        }
      }
      if (query.vaccine_type_id) {
        payload.vaccine_type_id = query.vaccine_type_id;
      }
    }

    VaccineAPI.listVaccineInventory(
      token,
      {
        items_per_page: pagination.pageSize,
        page: pagination.current,
      },
      payload
    )
      .then(({ inventory, total }) => {
        setInvtLoading(false);
        setVaccInventory(
          inventory.map((item, index: number) => ({ ...item, no: index + 1 }))
        );
        setInvtPagination({ ...pagination, total });
        if (inventory.length === 0) {
          //message.warning("Don't have any vaccine inventory information!");
        }
        return true;
      })
      .catch((error) => {
        setInvtLoading(false);
        setVaccInventory(null!);
        message.error(error.message ?? "Get list of vaccines faield!");
        return false;
      });
  };

  const fetchVaccines = (token: string, pagination: TablePaginationType) => {
    setVaccLoading(true);
    VaccineAPI.listVaccines(token, {
      items_per_page: pagination.pageSize,
      page: pagination.current,
    })
      .then(({ vaccines, total }) => {
        setVaccLoading(false);
        setVaccines(vaccines);
        setVaccPagination({ ...pagination, total });
        if (vaccines.length === 0) {
          //message.warning("Don't have any vaccines information!");
        }
        return true;
      })
      .catch((error) => {
        setVaccLoading(false);
        setVaccines(null!);
        message.error(error.message ?? "Get vaccine failed!");
        return false;
      });
  };

  const createVaccineInventory = (
    token: string,
    payload: ICreateVaccineInventoryPayload
  ) => {
    VaccineAPI.createVaccineInventory(token, payload)
      .then(() => {
        message.success("Create Vaccine Inventory Successfully!");
        setVisibleAddInvtModal(false);
        fetchVaccineInventory(accessToken, initPagination);
        return true;
      })
      .catch((error) => {
        message.error(error.message ?? "Create vaccine failed!");
        setVisibleAddInvtModal(false);
        return false;
      });
  };
  const updateVaccineInventory = (
    token: string,
    payload: IUpdateVaccineInventoryPayload,
    id: number
  ) => {
    VaccineAPI.updateVaccineInventory(token, payload, id)
      .then((res) => {
        if (res.status) {
          message.success(res.message);
        } else {
          message.error(res.message);
        }
        setVisibleUpdateInvtModal(false);
        fetchVaccineInventory(accessToken, initPagination);
        return true;
      })
      .catch((error) => {
        message.error(error.message ?? "Update vaccine failed!");
        setVisibleUpdateInvtModal(false);
        return false;
      });
  };

  const createVaccine = (token: string, payload: ICreateVaccinePayload) => {
    VaccineAPI.createVaccine(token, payload)
      .then((data) => {
        message.success(data?.message ?? "Create Vaccine Successfully!");
        setVisibleAddVaccModal(false);
        fetchVaccines(accessToken, initPagination);
        mutateVaccineName();
        return true;
      })
      .catch((error) => {
        message.error(error.message ?? "Create vaccine failed!");
        setVisibleAddVaccModal(false);
        return false;
      });
  };

  const updateVaccine = (
    token: string,
    payload: IUpdateVaccinePayload,
    id: number
  ) => {
    VaccineAPI.updateVaccine(token, payload, id)
      .then((data) => {
        message.success(data?.message ?? "Update vaccine successfully!");
        setVisibleUpdateModal(false);
        fetchVaccines(accessToken, initPagination);
        return true;
      })
      .catch((error) => {
        message.error(error.message ?? "Update vaccine failed!");
        setVisibleUpdateModal(false);
        return false;
      });
  };

  const deleteVaccine = (id: number) => {
    VaccineAPI.deleteVaccine(accessToken, id)
      .then((data) => {
        message.success(data?.message ?? "Delete vaccine successfully!");
        fetchVaccines(accessToken, initPagination);
        mutateVaccineName();
        return true;
      })
      .catch((error) => {
        message.error(error.message ?? "Delete vaccine failed!");
        return false;
      });
  };

  const onSwitchInventoryPage = (pagination: any) => {
    fetchVaccineInventory(accessToken, pagination);
  };
  const onSwitchVaccinesPage = (pagination: any) => {
    fetchVaccines(accessToken, pagination);
  };

  const handleCancelInvtModal = () => {
    setVisibleAddInvtModal(false);
  };
  const handleCancelVacctModal = () => {
    setVisibleAddVaccModal(false);
  };

  const handelDelelte = (id: number) => {
    VaccineAPI.delete(id, accessToken).then((res) => {
      if (res.status) {
        message.success(res.message);
      } else {
        message.error(res.message);
      }
      fetchVaccineInventory(accessToken, initPagination);
    });
  };

  const onDeleteVaccineInventory = (id: number) => {
    confirm({
      title: `Are you sure delete this ${PAGE_NAME.VACCINE.TABS[1]}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handelDelelte(id);
      },
    });
  };

  useEffect(() => {
    fetchVaccines(accessToken, initPagination);
  }, []);

  useEffect(() => {
    fetchVaccineInventory(accessToken, initPagination);
  }, [query]);

  return (
    <DashboardContainer>
      <Card>
        <div>
          <Space direction="vertical">
            <Title level={3} style={{ margin: 0 }}>
              Vaccine Management
            </Title>
          </Space>
        </div>
      </Card>
      <br />
      <Card>
        <Tabs defaultActiveKey="1" onChange={(key) => { }}>
          <TabPane tab="Vaccine Type" key="1">
            <Card
              extra={
                <Button
                  className="btn-create btn-default"
                  type="primary"
                  onClick={() => setVisibleAddVaccModal(true)}
                >
                  <PlusOutlined />
                  Create
                </Button>
              }
            >
              <Vaccine
                data={vaccines}
                loading={vaccLoading}
                pagination={vaccPagination}
                switchPage={onSwitchVaccinesPage}
                detailVaccine={(data) => {
                  setVisibleUpdateModal(true);
                  setSelectedVaccineItem(data);
                }}
                deleteVaccine={(id) => {
                  Modal.confirm({
                    title: "Are you sure delete this Vaccine Type?",
                    onOk: () => deleteVaccine(id),
                    okText: "Yes",
                    cancelText: "No",
                  });
                }}
              />

              <CreateVaccineModal
                visibleAddVaccineModal={visibleAddVaccModal}
                handleCancelModal={handleCancelVacctModal}
                createVaccine={createVaccine}
              />
              <UpdateVaccineModal
                visibleUpdateModal={visibleUpdateModal}
                handleCancelModal={() => {
                  setVisibleUpdateModal(false);
                }}
                updateVaccine={updateVaccine}
                vaccineItem={selectedVaccineItem}
              />
            </Card>
          </TabPane>
          <TabPane tab="Vaccine Inventory" key="2">
            <Card
              title={
                <Row>
                  <Col xs={24} md={20}>
                    <Row gutter={[12, 12]}>
                      <Col xs={24} sm={10}>
                        <Select
                          allowClear={false}
                          placeholder="Please Select Medical Centre"
                          value={query.site_id}
                          style={{ width: "100%" }}
                          defaultValue={roles?.data?.assignedSite}
                          disabled={!!roles?.data?.assignedSite}
                          onChange={(value: number) => {
                            setQuery({ ...query, site_id: value });
                          }}
                        >
                          <Select.Option key={"all"} value={null}>
                            All Medical Centre
                          </Select.Option>
                          {sites &&
                            sites.map((site: any) => (
                              <Select.Option key={site?.id} value={site?.id}>
                                {site?.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Col>
                      <Col xs={24} sm={10}>
                        <Select
                          allowClear={false}
                          placeholder="Please Select Vaccine"
                          value={query.vaccine_type_name}
                          style={{ width: "100%" }}
                          defaultValue={null}
                          onChange={(value: string) => {
                            if (!value) {
                              delete query.vaccine_type_id;
                              setQuery({ ...query, vaccine_type_name: value });
                            } else {
                              const selectedVaccine = vaccine_names.filter(
                                (vac) => vac.name === value
                              );
                              const ids = selectedVaccine[0].brands
                                .map((el) => el.id)
                                .join(",");
                              setQuery({
                                ...query,
                                vaccine_type_name: value,
                                vaccine_type_id: ids,
                              });
                            }
                          }}
                        >
                          <Select.Option key={"all"} value={null}>
                            All Vaccines
                          </Select.Option>
                          {vaccine_names &&
                            vaccine_names.map(
                              (item: IVaccineOption, index: number) => (
                                <Select.Option key={index} value={item?.name}>
                                  {item?.name}
                                </Select.Option>
                              )
                            )}
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={4} className="vaccine_inventory_title_right">
                    <Button
                      className="btn-create btn-default"
                      type="primary"
                      onClick={() => setVisibleAddInvtModal(true)}
                    >
                      <PlusOutlined />
                      Create
                    </Button>
                  </Col>
                </Row>
              }
            >
              <VaccineInventory
                data={vaccInventory}
                loading={invtLoading}
                pagination={invtPagination}
                switchPage={onSwitchInventoryPage}
                onUpdateInventory={(data) => {
                  setVisibleUpdateInvtModal(true);
                  setSelectedUnitInventory(data);
                }}
                onDeleteInventory={(data) => {
                  onDeleteVaccineInventory(data.id);
                }}
              />
              <CreateVaccineInventoryModal
                visibleAddInvtModal={visibleAddInvtModal}
                handleCancelModal={handleCancelInvtModal}
                createVaccineInventory={createVaccineInventory}
                vaccineList={vaccine_names}
                sitesList={sites}
                defaultSelectedSite={roles?.data?.assignedSite}
              />
              <UpdateVaccineInventoryModal
                visibleUpdateInvtModal={visibleUpdateInvtModal}
                handleCancelModal={() => {
                  setVisibleUpdateInvtModal(false);
                }}
                updateVaccineInventory={updateVaccineInventory}
                vaccineList={vaccines}
                unit={selectedUnitInventory}
                sitesList={sites}
              />
            </Card>
          </TabPane>
        </Tabs>
      </Card>
    </DashboardContainer>
  );
}

export default VaccineManagement;
