/**
 * @author ming.leng
 */
import React, { useState } from "react";
import { Card, Col, Form, Input, Row, message, Select } from "antd";
import DashboardContainer from "../../layout/Dashboard";
import { useAuth } from "../../context/auth";
import EditPersonnel from "../../components/EditPersonnel";
import PersonnelInfo from "../../components/Tables/PersonelInfo";
import CheckInModal from "./Modal/CheckIn";
import RejectModal from "./Modal/Reject";
import PersonnelAPI from "../../api/personnel";
import {
  ICheckInPayload,
  IGetPersonnelByNRICPayload,
  IPersonnelItem,
  IRejectPayload,
} from "../../types/personnel.model";
import { useRequest } from "../../swr";
import { ISite } from "../../types/site.model";
import { PAGE_NAME, ROLE_USER } from "../../constants";
// import { SelectValue } from "antd/lib/select";

export default function CheckIn() {
  const { accessToken, roles } = useAuth();
  const [searchForm] = Form.useForm();
  const [nric, setNric] = useState<string>(null!);
  const [site, setSite] = useState<string>(roles?.data?.assignedSite);
  const [appointmentId, setAppointmentId] = useState<string>("");
  const [visibleCheckInModal, setVisibleCheckInModal] =
    useState<boolean>(false);
  const [visibleRejectModal, setVisibleRejectModal] = useState<boolean>(false);
  const [showing, setShowing] = useState<boolean>(false);
  const [vaccineType, setVaccineType] = useState<number>(null!);
  const [personnelInfo, setPersonnelInfo] = useState<IPersonnelItem>(null!);

  const { data: sitesData, error } = useRequest("GET", "/vaccination/sites");
  const sitesList: ISite[] = sitesData?.data ?? [];
  if (error?.message) {
    message.error(error?.message ?? "error");
  }

  const { data: unitList, error: unitListError } = useRequest(
    "GET",
    `/vaccination/units?sort=name&order=asc${
      roles?.data?.role_id === ROLE_USER.DEPARTMENT_ADMIN_ID ? "?type_id=2" : ""
    }`
  );

  const { data: allergiesList, error: allergiesLisError } = useRequest(
    "GET",
    `/vaccination/personnels/options/allergies`
  );

  const handleSearch = (event?: React.ChangeEvent<HTMLInputElement>) => {
    const ic: string | undefined = event?.target.value;
    const regex = /^[STFG]\d{7}[A-Z]$/;
    if (ic && ic.length === 9) {
      if (regex.test(ic)) {
        setNric(ic);
        checkNRIC(accessToken, { nric: ic, site_id: site });
      } else {
        setNric(null!);
		   //searchForm.setFields([{ name: "ic", value: "" }]);
		    setShowing(false);
        message.error("NRIC is not right format!");
      }
    } else {
      setNric(null!);
	  //searchForm.setFields([{ name: "ic", value: "" }]);
	  setShowing(false);
    }
  };

  const handleSite = (value: any) => {
    setSite(value);
    if (nric) {
      checkNRIC(accessToken, { nric, site_id: value });
    }
  };

  const onCancel = () => {
    searchForm.resetFields();
    setNric(null!);
    setSite(null!);
    setShowing(false);
  };

  const handleCancelModal = () => {
    setVisibleCheckInModal(false);
    setVisibleRejectModal(false);
  };

  const checkIn = (token: string, payload: ICheckInPayload) => {
    PersonnelAPI.checkIn(token, nric, appointmentId, payload)
      .then(({ message: checkInMsg }) => {
        message.success(checkInMsg ?? "Vaccinate Successfully!");
        //setNric(null!);
        setAppointmentId("");
        //searchForm.setFields([{ name: "ic", value: "" }]);
        setShowing(false);
        setShowing(true);
      })
      .catch((err) => {
        message.error(err?.message ?? "Vaccinate Failed");
        //searchForm.setFields([{ name: "ic", value: "" }]);
      });
  };

  const reject = (token: string, payload: IRejectPayload) => {
    PersonnelAPI.reject(token, nric, appointmentId, payload)
      .then(({ message: checkInMsg }) => {
        message.success(checkInMsg ?? "Reject Successfully!");
        //setNric(null!);
        setAppointmentId("");
        //searchForm.setFields([{ name: "ic", value: "" }]);
        setShowing(false);
        setShowing(true);
      })
      .catch((err) => {
        message.error(err?.message ?? "Reject Failed");
        //searchForm.setFields([{ name: "ic", value: "" }]);
      });
  };

  const checkNRIC = (token: string, payload: IGetPersonnelByNRICPayload) => {
    PersonnelAPI.checkingPersonnelByNRIC(token, payload)
      .then((data: IPersonnelItem) => {
        if (data) {
          setShowing(true);
        } else {
          message.warn("NRIC does not exist. Please try again");
          setShowing(false);
        }
      })
      .catch(() => {
        message.warn("NRIC does not exist. Please try again");
        setShowing(false);
      });
  };

  const handleVaccineType = (typeId: number) => {
    setVaccineType(typeId);
  };

  const fetchInfo = (token: string, payload: IGetPersonnelByNRICPayload) => {
    PersonnelAPI.getPersonnelByNRIC(token, payload)
      .then((data) => {
        if (data) {
          setPersonnelInfo(data);
        } else {
          message.error("Personnel not found, please try another!");
          setPersonnelInfo(null!);
        }
      })
      .catch((err) => {
        message.error(err.message ?? "Get personnel failed!");
        setPersonnelInfo(null!);
      });
  };

  const handleFetchInfoWithIdOrNRIC = (
    token: string,
    id: number,
    nric: string
  ) => {
    const payload: IGetPersonnelByNRICPayload = {};
    payload["page_name"] = "checkin";
    if (nric) {
      payload["nric"] = nric;
      return fetchInfo(token, payload);
    } else if (id) {
      payload["id"] = id;
      return fetchInfo(token, payload);
    }
  };

  return (
    <DashboardContainer>
      <Card
        title={
          <Form form={searchForm}>
            <Row gutter={24} wrap>
              <Col xs={24} md={12}>
                <Form.Item
                  label={PAGE_NAME.MEDICAL_CENTRE.LABEL_1}
                  name="site_id"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please Select " + PAGE_NAME.MEDICAL_CENTRE.LABEL_1,
                    },
                  ]}
                >
                  <Select
                    defaultValue={roles?.data?.assignedSite}
                    disabled={!!roles?.data?.assignedSite}
                    onChange={(e) => handleSite(e)}
                    placeholder={"Choose " + PAGE_NAME.MEDICAL_CENTRE.LABEL_1}
                  >
                    {sitesList?.map((site: ISite) => {
                      return (
                        <Select.Option key={site.id} value={site.id}>
                          {site.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item name="ic" label="NRIC">
                  <Input.Search
                    placeholder="Search Appointment by NRIC"
                    onChange={(e) => handleSearch(e)}
					onInput={e => e.target.value = e.target.value.toUpperCase()}
                    disabled={site ? false : true}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      >
        {showing ? (
          <>
            <Row>
              <Col span={24}>
                <EditPersonnel
                  nric={nric}
                  handleFetchInfoWithIdOrNRIC={handleFetchInfoWithIdOrNRIC}
                  personnelInfo={personnelInfo}
                  unitList={unitList?.data}
                  allergiesList={allergiesList}
				  canEdit={false}
                />
              </Col>
              <Col span={24}>
                <PersonnelInfo
                  nric={nric}
                  personnelInfo={personnelInfo}
                  handleVaccineType={handleVaccineType}
                  setVisibleCheckInModal={setVisibleCheckInModal}
                  setVisibleRejectModal={setVisibleRejectModal}
                  setAppointmentId={setAppointmentId}
                />
              </Col>
            </Row>
            <Row justify="end" style={{ margin: "16px 0" }}>
              <CheckInModal
                visible={visibleCheckInModal}
                handleCancelModal={handleCancelModal}
                checkIn={checkIn}
                site_id={site}
                vaccine_type_id={vaccineType}
              />
              <RejectModal
                visible={visibleRejectModal}
                handleCancelModal={handleCancelModal}
                reject={reject}
              />
            </Row>
          </>
        ) : (
          <></>
        )}
      </Card>
    </DashboardContainer>
  );
}
