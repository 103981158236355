import React from "react";
import { Row, Col, Typography, message } from "antd";

// container
import DashboardContainer from "../../../layout/Dashboard";

// components
import RegistrationWidget from "./Registration";
import Chart from "../../../components/Chart";

import { useRequest } from "../../../swr";

const { Title } = Typography;

export default function IndexPage() {
  // Query
  const { data, error } = useRequest(
    "GET",
    "/test_centers/appointment-statistics"
  );
  const rowData = data?.data ?? {};
  if (error?.message) {
    message.error(error?.message ?? "error");
  }
  return (
    <DashboardContainer>
      <Title style={{ color: "#6b6b6b" }} level={2}>
        Dashboard
      </Title>
      <Row gutter={24}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          style={{ paddingLeft: 12, paddingRight: 12 }}
        >
          <RegistrationWidget
            title="Total appoinments today"
            total={rowData?.total_today}
            //   data={stats}
            loading={false}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          style={{ paddingLeft: 12, paddingRight: 12 }}
        >
          <RegistrationWidget
            title="Total appoinments yesterday"
            total={rowData?.total_yesterday}
            //   data={stats}
            loading={false}
          />
        </Col>
        <Col lg={8}>
          <Chart />
        </Col>
      </Row>
    </DashboardContainer>
  );
}
