import React, { useContext, useMemo, useState } from "react";
import { Modal, Button, Form, DatePicker, Select, message } from "antd";
import moment from "moment";
// hook
import { useAuth } from "../../../context/auth";
import AppointmentsAPI from "../../../api/appointments";
import TimeslotsAPI from "../../../api/timeslots";
import { PayloadGetTimeSlotsByDate } from "../../../types/timeslots.model";
import { DATE_FORMAT } from "../../../constants";
import { IReSchedule } from "../../Tables/PersonnelManagement";
import { PMContext } from "../../../pages/PersonnelManagement/PersonnelManagementContext";

import { ROLE_USER } from "../../../constants/index";

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      flex: 1,
    },
    sm: {
      span: 16,
      offset: 12,
    },
  },
};

const dateConfig = {
  rules: [
    { type: "object" as const, required: true, message: "Please Select Date!" },
  ],
};

type RescheduleModalProps = {
  visible: boolean;
  appointment: any;
  listAppointments: any[];
  onClose: () => void;
  mutate: () => {};
  onSetReSchedulePayload: Function;
  reSchedulePayload: IReSchedule;
};

const RescheduleModal = ({
  visible,
  appointment,
  listAppointments = [],
  onClose,
  mutate,
  reSchedulePayload,
  onSetReSchedulePayload,
}: RescheduleModalProps) => {
  const { accessToken } = useAuth();
  const [form] = Form.useForm();
  const context = useContext(PMContext);

  const [timeSlot, setTimeSlot] = useState<any>([]);
  const { roles } = useAuth();
  const onCancel = () => {
    onClose();
    form.resetFields();
  };

  const getTimeSlot = async (payload: PayloadGetTimeSlotsByDate) => {
    form.setFieldValue("unit_timeslot_id", "");
    setTimeSlot([]);

    return TimeslotsAPI.listTimeSlotByUnit(accessToken, payload)
      .then((req) => {
        if (req.length) {
          setTimeSlot(req[0]?.unit_timeslot || []);
        }
      })
      .catch((error) => {
        setTimeSlot([]);
      });
  };

  const onchangeDate = (date: any) => {
    date = moment(date).format(DATE_FORMAT);
    onSetReSchedulePayload({ date });
    getTimeSlot({
      date,
      unit_id: reSchedulePayload.unit_id,
      site_id: reSchedulePayload.site_id,
    });
  };

  const rescheduleAppointment = async (payload: any) => {
    return await AppointmentsAPI.rescheduleAppointment(accessToken, payload)
      .then((req) => {
        if (req?.message) {
          message.success(
            req.message ?? "Reschedule appointment successfully!"
          );
        }
        onClose();
        form.resetFields();
        mutate();
      })
      .catch((error) => {
        message.error(error?.message ?? "Reschedule appointment failed!");
      });
  };

  useMemo(() => {
    const date = moment().format(DATE_FORMAT);
    onSetReSchedulePayload({
      date,
    });
    getTimeSlot({
      date,
      unit_id: reSchedulePayload.unit_id,
      site_id: reSchedulePayload.site_id,
    });
  }, []);

  const onFinish = (value: any) => {
    rescheduleAppointment(reSchedulePayload);
  };

  return (
    <Modal
      title="Reschedule Appointment"
      visible={visible}
      footer={null}
      destroyOnClose
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
    >
      <Form
        {...formItemLayout}
        form={form}
        layout="horizontal"
        name="re-schedule-appointment"
        onFinish={onFinish}
        initialValues={{
          order_no: appointment?.order_no,
          site_id: reSchedulePayload?.site_id,
          date: moment(appointment?.date),
        }}
      >
        <Form.Item
          label="Medical Centre"
          name="site_id"
          rules={[{ required: true, message: "Please Select Site" }]}
        >
          <Select
            disabled={true}
            options={context.medicalCenter?.map((mc) => ({
              value: mc.id,
              label: mc.name,
            }))}
          ></Select>
        </Form.Item>
        <Form.Item label="Date:" name="date" {...dateConfig}>
          <DatePicker
          disabledDate={(current) => {
            if (roles?.data?.role_id === ROLE_USER.SUPER_ADMIN_ID) {
            return current.isBefore(moment().startOf('day'));
            } else {
            const maxAllowedDate = moment().add(2, 'days').startOf('day');
            return current.isBefore(maxAllowedDate);
            }
          }}
            allowClear={false}
            onChange={onchangeDate}
            format={DATE_FORMAT}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Time:"
          name="unit_timeslot_id"
          rules={[
            { required: true, message: "Please Select Time of Injection" },
          ]}
        >
          <Select
            disabled={!form.getFieldValue("date")}
            onChange={(e) => {
              const split = e?.split("_");
              onSetReSchedulePayload({
                start_time: split[0],
                end_time: split[1],
              });
            }}
            options={timeSlot?.map((ts) => ({
              value: `${ts.start_time}_${ts.end_time}`,
              label: `${ts.start_time || ""} - ${ts.end_time || ""} | ${
                ts?.slots - ts?.uses
              } Slot Available`,
            }))}
          >
          </Select>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Submit
          </Button>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RescheduleModal;
