import useSwr from "swr";
import { useAuth } from "../context/auth";

const baseUrl = process.env.REACT_APP_SWIFTEST_API_URL;


export const useRequest = (methodType: string, path: string) => {
  const { accessToken } = useAuth();
  if (!path) {
    throw new Error("Path is required");
  }
  const url: string = baseUrl + path;
  const fetcher = () => {
    const options = {
      method: methodType ?? "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }
    }
    return fetch(url, { ...options }).then((res) => res.json());
  }
  const { data, error, mutate } = useSwr(accessToken ? path : null, fetcher,{revalidateOnFocus:false});
  return {
    data,
    isLoading: !error && !data,
    error,
    mutate,
  };
};