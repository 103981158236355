import React, { useEffect, useState } from "react";
import { Modal, Button, Form, DatePicker, Select, message } from "antd";
import moment from "moment";

// hook
import AppointmentsAPI from "../../../api/appointments";
import TimeslotsAPI from "../../../api/timeslots";
import { useRequest } from "../../../swr";
import VaccineAPI from "../../../api/vaccine";
import { IVaccine } from "../../../types/vaccine.model";

import { useAuth } from "../../../context/auth";
import { ROLE_USER } from "../../../constants/index";
import { PayloadGetTimeSlotsByDate } from "../../../types/timeslots.model";

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};

const tailFormItemLayout = {
  wrapperCol: {  span: 24 },
};

const dateConfig = {
  rules: [
    { type: "object" as const, required: true, message: "Please Select Date" },
  ],
};

const { Option } = Select;

type BulkModalProps = {
  token: string;
  visible: boolean;
  personnel: Array<string>;
  filter: any;
  vaccineBrandDefault: any;
  onClose: () => void;
};
const BulkModal = ({
  token,
  visible,
  personnel,
  filter,
  vaccineBrandDefault,
  onClose,
}: BulkModalProps) => {
  const [form] = Form.useForm();
  const [timeSlot, setTimeSlot] = useState<any>([]);
  const [siteChange, setSiteChange] = useState<any>(null);
  const [dateChange, setDateChange] = useState<any>(null);
  const { roles } = useAuth();
  const { data: sitesData, error } = useRequest(
    "GET",
    `/vaccination/sites?assigned_site_id=${roles?.data?.assignedSite ? roles.data.assignedSite : 0}`
  );
  let sites: [] = sitesData?.data ?? [];
  if (error?.message) {
    message.error(error?.message ?? "error");
  }
  const [vaccines, setVaccines] = useState<IVaccine[]>([]);

  useEffect(() => {
    getVaccineType();
    form.setFieldsValue({
      vaccine_type_id: vaccineBrandDefault?.brand,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaccineBrandDefault]);

  useEffect(() => {
    form.setFieldsValue({
      site_id: filter?.site_id,
    });
    setSiteChange(filter?.site_id)
  }, [filter,visible])

  const getVaccineType = async () => {
    return VaccineAPI.listVaccines(token, { items_per_page: "", page: "" })
      .then((req) => {
        if (req && req?.vaccines?.length !== 0) {
          setVaccines(req.vaccines);
        } else {
          setVaccines([]);
        }
      })
      .catch((error) => {
        setVaccines([]);
        message.error(error?.message ?? "error");
      });
  };

  const onCancel = () => {
    onClose();
    form.resetFields();
    setTimeSlot([])
    setSiteChange(null)
  };

  const onchangeDate = (date: any) => {
    setDateChange(date);
    if (siteChange) {
      getTimeSlot({
        date: moment(date).format("YYYY-MM-DD"),
        site_id: siteChange,
      });
    }
  };

  const onchangeSites = (site_id: any) => {
    setSiteChange(site_id);
    if (dateChange) {
      getTimeSlot({
        date: moment(dateChange).format("YYYY-MM-DD"),
        site_id: site_id,
      });
    }
  };

  const bulkAppointment = async (payload: any) => {
    return await AppointmentsAPI.bulkAppointment(token, payload)
      .then((req) => {
        if (req?.status === false) {
          message.error(req?.message ?? "Update unsuccessfully!");
        } else {
          message.success(req?.message ?? "Update successfully!");
        }
        onClose();
        form.resetFields();
      })
      .catch((error) => {
        console.log("Error:", error);
        // message.success(error);
      });
  };

  const getTimeSlot = async (payload: PayloadGetTimeSlotsByDate) => {
    return TimeslotsAPI.listTimeSlotByUnit(token, payload)
      .then((req) => {
        if (req && req?.length !== 0) {
          setTimeSlot(req);
        } else {
          // set value of field time to [] if timeSlot is null
          form.setFields([
            {
              name: "time",
              value: [],
            },
          ]);
          setTimeSlot([]);
        }
      })
      .catch((error) => {
        setTimeSlot([]);
      });
  };

  const onFinish = (value: any) => {
    const payload = {
      date: moment(value?.date).format("YYYY-MM-DD"),
      start_time: value?.time?.split("-")[0],
      end_time: value?.time?.split("-")[1],
      personnel_ids: personnel,
      unit_id: filter?.unit_id,
      site_id: value?.site_id,
    };
    bulkAppointment(payload);
  };

  return (
    <Modal
      title="Assign Appointment"
      visible={visible}
      footer={null}
      onCancel={() => {
        onClose();
        form.resetFields();
        setTimeSlot([])
        setSiteChange(null)
      }}
    >
      <Form
        {...formItemLayout}
        form={form}
        layout="horizontal"
        name="bulk-update-appointment"
        onFinish={onFinish}
      >
        <Form.Item label="Total selected personnels" name="selected">
          <span>: {personnel?.length}</span>
        </Form.Item>
        <Form.Item
          label="Medical Centre"
          name="site_id"
          rules={[{ required: true, message: "Please Select Medical Centre" }]}
        >
          <Select onChange={onchangeSites} disabled={!!filter?.site_id}>
            {sites &&
              sites.map((el: any) => {
                return <Option key={el?.id} value={el?.id}>{el?.name}</Option>;
              })}
          </Select>
        </Form.Item>
        <Form.Item label="Appointment Date:" name="date" {...dateConfig}>
          <DatePicker
            onChange={onchangeDate}
            format={"YYYY-MM-DD"}
			disabledDate={(current) => {
			  if (roles?.data?.role_id === ROLE_USER.SUPER_ADMIN_ID) {
				return current.isBefore(moment().startOf('day'));
			  } else {
				const maxAllowedDate = moment().add(2, 'days').startOf('day');
				return current.isBefore(maxAllowedDate);
			  }
			}}
            style={{ width: "100%" }}
          />
          {/* <span className="ant-form-text">{dateSlot?.total_slots ?? 0}</span> */}
        </Form.Item>
        <Form.Item
          label="Appointment Time Slot:"
          name="time"
          rules={[
            { required: true, message: "Please Select Vaccine Time" },
          ]}
        >
          <Select style={{width: "300px"}} disabled={!form.getFieldValue("date")}>
            {form.getFieldValue("date") &&
              timeSlot &&
              timeSlot?.[0]?.unit_timeslot?.map((e: any) => (
                <Option value={`${e?.start_time}-${e?.end_time}`} key={e?.id} disabled={
                  e?.slots-e?.uses > 0 && personnel?.length <= e?.slots-e?.uses ? false : true}>
                  {`${e?.title ? `(${e.title})` : ""} ${e?.start_time ?? ""} - ${e?.end_time ?? ""} | ${
                    e?.slots-e?.uses
                  } Slot Available`}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item style={{textAlign:"center"}} {...tailFormItemLayout}>
          <Button key="back" className="btn-default" onClick={onCancel} >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" style={{ margin: "0 8px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BulkModal;
