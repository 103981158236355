import React, { useEffect, useState } from "react";
import { Card, Cascader, Col, DatePicker, message, Row, Select, Space, Typography ,Result} from "antd";

// components
import DashboardContainer from "../../../layout/Dashboard";
import ChartOverall from "../../../components/Chart/ChartOverall";
import { IInjectionSum } from "../../../types/summary.model";
import { useRequest } from "../../../swr";
import RoundChart from "../../../components/Chart/RoundChart";
import QuantityBox from "../../../components/Chart/QuantityBox";
import ScheduledAdminAppointmentTimeSlotOverview from "../../../components/Tables/ScheduledAdminAppointmentTimeSlotOverview";
import { IUnit } from '../../../types/unit.model';
import moment from 'moment';
import { DATE_FORMAT } from "../../../constants";
import { getCookie, removeEmptyUrlParams } from '../../../utils/helper';
import useAuthRole from '../../../hook/useAuthRole';

const { Title } = Typography;
const DEFAULT_FILTER = {
  date: null,
  vaccine_id: null,
  site_id: null
}

export default function Dashboard() {
  const { data: role } = useAuthRole(getCookie("idToken"));
  const [filter, setFilter] = useState<{ [key: string]: any }>(DEFAULT_FILTER)
  const { data: summaries, error } = useRequest(
    "GET",
    `/vaccination/summaries/injection_status?${removeEmptyUrlParams({...filter, vaccine_id:filter?.vaccine_id ? filter?.vaccine_id?.[1] : null})}`);
  const summariesData: IInjectionSum[] = summaries?.data ?? [];
  if (error?.message) {
    message.error(error?.message ?? "Can not get summaries");
  }

  const { data: sitesData, error: sitesError } = useRequest("GET", "/vaccination/sites");
  const sites: IUnit[] = sitesData?.data ?? [];
  if (sitesError?.message) {
    message.error(sitesError?.message ?? "Can not get medical centre");
  }

  const { data: vaccineData, error: vaccineError } = useRequest("GET", "/vaccination/vaccines/options");
  const vaccineOption: any[] = vaccineData?.data?.map((item: any) => {
    return {
      label: item?.name,
      value: item?.name,
      children: item?.brands?.map((item: any) => {
        return {
          value: item?.id,
          label: item?.name
        }
      }),
    }
  })
  if (vaccineError?.message) {
    message.error(vaccineError?.message ?? "Can not get vaccine");
  }

  const handleDatePickerChange = (value: any) => {
    setFilter({ ...filter, date: value ? moment(value)?.format(DATE_FORMAT) : null })
  }

  const handleVaccineChange=(value:any[]) => {
    setFilter({...filter,  vaccine_id:value? value : null})
   }

  const handleMedicalCentreChange = (value: number) => {
    setFilter({ ...filter, site_id: value ? value : null })
  }

  useEffect(() => {
    setFilter({
      ...filter,
      site_id: role?.data?.assignedSite ? role?.data?.assignedSite : null,
      vaccine_id:[vaccineData?.data?.[0]?.name, vaccineData?.data?.[0]?.brands?.[0]?.id]
    });
  }, [role,vaccineData]);

  return (
    <DashboardContainer>
      <Title style={{ color: "#6b6b6b" }} level={2}>
        Dashboard
      </Title>

      <Row gutter={[12, 12]} style={{ marginTop: 24 }} >
          <Card
            className="card-no-pd"
            title="Unit / Department Overview"
            style={{
              boxShadow: "1px 2px 6px rgba(0, 0, 0, 0.16)",
              height: "100%",
              width:"100%"
            }}
          >
            <Row  gutter={[24, 24]} >
              <Col className="gutter-row" lg={{span: 8}} span={24}>
                <DatePicker style={{width:"100%"}} onChange={handleDatePickerChange} />
              </Col>
              <Col className="gutter-row" lg={{span: 8}} span={24}>
                <Cascader style={{width:"100%"}} allowClear={false} options={vaccineOption} value={filter?.vaccine_id} onChange={handleVaccineChange} placeholder="Select vaccine" />
              </Col>
              <Col className="gutter-row" lg={{span: 8}} span={24}>
                <Select defaultValue={role?.data?.assignedSite} disabled={!!role?.data?.assignedSite} allowClear style={{width:"100%"}} onChange={handleMedicalCentreChange} placeholder="Please select medical centre">
                  {sites?.length > 0 && sites?.map((site) => {
                    return <Select.Option key={site?.id} value={site?.id}>
                      {site?.name}
                    </Select.Option>
                  })}
                </Select>
              </Col>
              </Row>
          </Card>
      </Row>

      <Row gutter={[15, 20]} style={{ marginTop: 24 }}>
        {summariesData?.length !== 0 ? (
          summariesData?.map(
            ({ unit_name, summary, overall, schedule }: IInjectionSum) => {
              return (
                <Col xs={24} sm={24} lg={8}>
                  <ChartOverall
                    title={unit_name}
                    overall={overall}
                    schedule={schedule}
                    data={summary}
                    vaccineSelected={filter?.vaccine_id}
                    vaccineList={vaccineData?.data}
                  />
                </Col>
              );
            }
          )
        ) : (<Col xs={24} sm={24} lg={24}>
            <Result
            status="warning"
            subTitle="No data"
          />
        </Col> 
        )}
      </Row>
    </DashboardContainer>
  );
}
