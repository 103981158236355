/* eslint-disable import/no-anonymous-default-export */
import { IBaseResponse, PaginationQueryType } from "../types/base.model";
import {
  IPersonnelItem,
  IGetPersonnelListResponse,
  IGetPersonnelByNRICPayload,
  ICheckInPayload,
  IRejectPayload,
} from "../types/personnel.model";
// import * as _ from "@types/lodash";

const path = "/vaccination/personnels";

const personnelAPIs = {
  async list(
    token: string,
    pagination: PaginationQueryType,
    filters: any
  ): Promise<{ items: IPersonnelItem[]; total: number }> {
    /**
     * @description remove property undefined
     */
    Object.keys(filters).forEach((key) => {
      if (filters[key] === undefined) {
        delete filters[key];
      }
    });

    /**
     * @description add param to end code url
     */
    const searchParams = new URLSearchParams({
      items_per_page: pagination.items_per_page.toString(),
      page: pagination.page.toString(),
      order_by: pagination.order_by,
      order_type: pagination.order_type,
      ...filters,
    });

    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}?${searchParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      }
    );
    const {
      data,
      total,
      status,
      message,
    }: IGetPersonnelListResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    const personnelList = data as IPersonnelItem[];

    return { items: personnelList, total };
  },

  async delete(token: string,id: string): Promise<any> {
    const res = await fetch(`${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const { status, message }: any = await res.json();
    return { status, message };
  },

  async getPersonnelByNRIC(
    token: string,
    payload: IGetPersonnelByNRICPayload
  ): Promise<IPersonnelItem> {
    const searchParams = new URLSearchParams({});

    if (payload?.id) {
      searchParams.append("id", payload?.id.toString());
    }

    if (payload?.nric) {
      searchParams.append("nric", payload?.nric);
    }

    if (payload?.order_no) {
      searchParams.append("order_no", payload?.order_no.toString());
    }

    if (payload?.page_name) {
      searchParams.append("page_name", payload?.page_name.toString());
    }
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}?${searchParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const {
      data,
      status,
      message,
    }: IGetPersonnelListResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    const personnelData = data[0] as IPersonnelItem;

    if (!personnelData) {
      throw new Error("Personnel not found, please try another!")
    }

    return personnelData;
  },
  async checkingPersonnelByNRIC(
    token: string,
    payload: IGetPersonnelByNRICPayload
  ): Promise<IPersonnelItem> {
    const searchParams = new URLSearchParams({});

    if (payload?.id) {
      searchParams.append("id", payload?.id.toString());
    }

    if (payload?.site_id) {
      searchParams.append("site_id", payload.site_id);
    }

    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${payload?.nric}?${searchParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const {
      data,
      status,
      message,
    }: IGetPersonnelListResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    const personnelData = data[0] as IPersonnelItem;
    return personnelData;
  },
  async updatePersonnel(token: string, id: number, payload: any): Promise<any> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return {
      status,
      message,
    };
  },
  async createPersonnel(token: string, payload: any): Promise<any> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return {
      status,
      message,
    };
  },
  async appointmentsPersonal(token: string, personal_id: number) {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${personal_id}/appointments`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = await res.json();
    return data;
  },
  async checkIn(
    token: string,
    nric: string,
    appointmentId: string,
    payload: ICheckInPayload
  ): Promise<IBaseResponse> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${nric}/${appointmentId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
  async reject(
    token: string,
    nric: string,
    appointmentId: string,
    payload: IRejectPayload
  ): Promise<IBaseResponse> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/${nric}/${appointmentId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const { status, message }: IBaseResponse = await res.json();
    if (status === false) {
      throw new Error(message);
    }
    return { status, message };
  },
  async export(token: string,
    filters: any): Promise<{}> {
    /**
   * @description remove property undefined
   */
    Object.keys(filters).forEach((key) => {
      if (filters[key] === undefined) {
        delete filters[key];
      }
    });

    /**
     * @description add param to end code url
     */
    const searchParams = new URLSearchParams({
      ...filters,
    });
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/export?${searchParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.blob();
    return data;
  },
  async downloadTemplate(token: string): Promise<{}> {
    const res = await fetch(
      `${process.env.REACT_APP_SWIFTEST_API_URL}${path}/download-template`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const url = await res.blob();
    return url;
  }
};

export default personnelAPIs;
