import React, { useState } from "react";
import moment from "moment";

import { Button, Badge, Table, message } from "antd";
import { ColumnsType } from "antd/es/table/interface";

import { DATE_FORMAT_VC_ADMIN } from "../../constants";
import { useRequest } from "../../swr";

import NotificationAPI from "../../api/notification";
import { useAuth } from "../../context/auth";

type PropsType = {
  order: string;
};

export default function Notification({ order }: PropsType) {
  const { accessToken } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const { data, mutate } = useRequest(
    "GET",
    `/vaccination/notifications?appointment_id=${order}&page=${page}`
  );
  const notificationList = data?.data ?? [];

  const handleResendMessage = (token: string, id: number) => {
    setLoading(true);
    NotificationAPI.resendNotification(token, id)
      .then((data) => {
        message.success("Resent SMS successfully");
        setLoading(false);
        mutate();
      })
      .catch((err) => {
        message.error(err?.message ?? "Resent SMS failed");
        setLoading(false);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Notification Date",
      // width: 150,
      dataIndex: "notification_date",
      render: (value) => {
        return value === null
          ? "-"
          : moment(value).format(DATE_FORMAT_VC_ADMIN);
      },
    },
    {
      title: "Updated",
      // width: 150,
      dataIndex: "updated_at",
      render: (value) => {
        return value === null
          ? "-"
          : moment(value).format(DATE_FORMAT_VC_ADMIN);
      },
    },
    {
      title: "Type",
      dataIndex: "action_type",
      // width: 200,
      render: (value) => {
        return <div style={{ textTransform: "capitalize" }}>{value}</div>;
      },
    },
    {
      title: "Status",
      width: 120,
      dataIndex: "status",
      render: (record) => {
        switch (record) {
          case "created":
            return <Badge status="success" text="Created" />;
          case "processing":
            return <Badge status="warning" text="Processing" />;
          case "completed":
            return <Badge status="warning" text="Completed" />;
          case "error":
            return <Badge status="error" text="Failed" />;
          default:
            return;
        }
      },
    },
    {
      fixed: "right",
      render: (record) => {
        return (
          <Button
            type="primary"
            style={{ width: 100 }}
            onClick={() => handleResendMessage(accessToken, record.id)}
            loading={loading}
          >
            Resend
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      scroll={{ x: 800 }}
      rowKey={(record) => record.id}
      className="notification-table"
      columns={columns}
      dataSource={notificationList}
      pagination={{
        pageSize: 10,
        total: data?.total ?? 0,
        onChange: (page) => {
          setPage(page);
        },
      }}
    />
  );
}
