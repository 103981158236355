export enum AuthRoutes { }

export enum NonAuthRoutes { }

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_VC_ADMIN = "YYYY-MMM-DD";
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = "YYYY-MM-DD H:mm";
export const ORDER_TYPE_ARRAY_BE = ["DESC", "ASC", undefined];
export const ORDER_TYPE_ARRAY_FE = ["descend", "ascend", null];
export const PAGE_NAME = {
  MEDICAL_CENTRE: {
    URI: "/medical-center-management",
    LABEL: "Medical Centre Management",
    LABEL_1: "Medical Centre",
  },
  DEPARTMENT: {
    URI: "/department-management",
    LABEL: "Department Management",
    LABEL_1: "Department",
  },
  COMMAND: {
    URI: "/command-management",
    LABEL: "Command Management",
    LABEL_1: "Command",
  },
  VACCINE: {
    URI: "/vaccine-management",
    LABEL: "Vaccine Management",
    LABEL_1: "Vaccine",
    TABS: ["Vaccine Type", "Vaccine Inventory"],
  },
  UNIT_MANAGEMENT: {
    URI: "/unit-management",
    LABEL: "Unit Management",
    LABEL_1: "Unit",
  },
};

export const APPEND_SUFFIX = (num: number = 1) => {
  let suffix = "th";
  if (num === 0) suffix = "";
  if (num % 10 === 1 && num % 100 !== 11) suffix = "st";
  if (num % 10 === 2 && num % 100 !== 12) suffix = "nd";
  if (num % 10 === 3 && num % 100 !== 13) suffix = "rd";

  return num + suffix;
};

export enum ROLE_USER {
  SUPER_ADMIN_ID = 1,
  MED_ADMIN_ID = 3,
  DEPARTMENT_ADMIN_ID = 4,
  VC_ADMIN_ID = 5,
  SCHEDULE_ADMIN_ID = 6,
  COMMAND_ADMIN_ID = 7,
  UNIT_ADMIN_ID = 8
}

export enum VACCINATION_STATUS {
  NOT_SCHEDULED = 'Not Scheduled',
  REQUESTED = 'Requested',
  SCHEDULED = 'Scheduled',
  COMPLETED = 'Completed',
  REJECTED = 'Rejected',
  OUTDATED = 'Outdated',
  INELIGIBLE = 'Ineligible'
}