import React from "react";
import { Table, Button, Space, Row, Col } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table/interface";
import { TablePaginationType } from "../../../types/table.model";
import { IVaccine, IVaccineItem } from "../../../types/vaccine.model";
import { APPEND_SUFFIX } from "../../../constants";
import { ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

export type PropsType = {
  data: IVaccine[];
  loading: boolean;
  pagination: TablePaginationType;
  switchPage: Function;
  detailVaccine: (detail: IVaccineItem) => void;
  deleteVaccine: (id: number) => void;
};

function Vaccine({
  data,
  loading,
  pagination,
  switchPage,
  detailVaccine,
  deleteVaccine,
}: PropsType) {
  const onChangePage = (pagination: TablePaginationConfig): void => {
    switchPage(pagination);
  };

  const DosesRendering = (doses: number, data: number[]) => {
    return new Array(doses === 1 ? +doses : doses - 1).fill("").map((x, i) => {
      return (
        <div key={i}>
          <Row style={{ display: "flex" }}>
            <Col span={12}>
              {APPEND_SUFFIX(i + 1)}-{APPEND_SUFFIX(i + 2)} Dose:
            </Col>
            <Col>{data[i]}</Col>
          </Row>
        </div>
      );
    });
  };

  const columns: ColumnsType<IVaccine> = [
    {
      dataIndex: "id",
      title: "ID",
      width: 50,
      render: (_a, _b, c) => (c + 1) + (pagination?.pageSize * (pagination.current - 1)),
    },
    {
      dataIndex: "name",
      title: "Name",
      width: 120,
    },
    {
      dataIndex: "brand",
      title: "Brand",
      width: 120,
    },
    {
      dataIndex: "total_doses",
      title: "Total Doses Needed",
      width: 80,
    },
    {
      dataIndex: "min_days",
      title: "Minimum Days Until Next Dose",
      width: 220,
      render: (a, b: any) => {
        let durations = !b?.durations.map((x: object) => Object.values(x)[0])?.length 
          ? [b.min_days] 
          : b?.durations.map((x: object) => Object.values(x)[0]);

        return DosesRendering(
          +b.total_doses,
          durations
        );
      },
    },
    {
      dataIndex: "max_days",
      title: "Maximum Days For Next Dose",
      width: 220,
      render: (a, b: any) => {
        let durations = !b?.durations.map((x: object) => Object.values(x)[1])?.length 
          ? [b.max_days] 
          : b?.durations.map((x: object) => Object.values(x)[1]);

        return DosesRendering(
          +b.total_doses,
          durations
        );
      },
    },
    {
      width: 80,
	  title: "Action",
      render: (record, obj: any) => {
        return (
          <Space>
            <Button
              type="primary"
              onClick={() => {
                detailVaccine(record);
              }}
			  icon={<EditOutlined />}
            >
             
            </Button>
            <Button
              danger
              onClick={() => {
                deleteVaccine(obj.id);
              }}
			  icon={<DeleteOutlined />}
            >
              
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      loading={loading}
      columns={columns}
      pagination={pagination}
      onChange={onChangePage}
      dataSource={data}
      scroll={{ x: 800 }}
    />
  );
}

export default Vaccine;
