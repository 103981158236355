import {
  SuperAdminDashboard,
  TestCenterAdminDashboard,
  UnitAdminDashboard,
  ScheduleAdminDashboard,
  CommandAdminDashboard,
} from "../pages/Index";

import Appointment from "../pages/Appointment";
import UserManagement from "../pages/UserManagement";
import CapacityManagement from "../pages/CapacityManagement";
import TestCenterManagement from "../pages/TestCenterManagement";
import UploadTestResult from "../pages/UploadTestResult";
import PersonnelRegistration from "../pages/PersonnelRegistration";
import VaccineManagement from "../pages/VaccineManagement";
import DepartmentManagement from "../pages/DepartmentManagement";
import { TestResultReview } from "../pages/TestResultReview";
import PersonnelManagement from "../pages/PersonnelManagement";
import CheckIn from "../pages/CheckIn";
import TimeSlotManagement from "../pages/TimeSlot";
import CommandManagement from "../pages/CommandManagement";
import UnitManagement from "../pages/UnitManagement";
import SiteManagement from "../pages/SiteManagement";
import FourZeroFour from "../pages/FourZeroFour";

import { ReactComponent as HomeIcon } from "../assets/icon/home.svg";
import { ReactComponent as GroupIcon } from "../assets/icon/groupmanagementicon.svg";
import { ReactComponent as UserIcon } from "../assets/icon/useradminmanagementicon.svg";
import { ReactComponent as HealthCardIcon } from "../assets/icon/updatehealthcardicon.svg";
import { ReactComponent as InstantSearching } from "../assets/icon/instant_search.svg";
import { ReactComponent as GroupInsight } from "../assets/icon/group_insight.svg";
import { ReactComponent as ReviewIcon } from "../assets/icon/swabbed_cases.svg";
import { ReactComponent as RosterManagement } from "../assets/icon/Roster_Management.svg";
import { PAGE_NAME } from "../constants";

interface RouterItem {
  roles: string[];
  path: string;
  exact: boolean;
  page: any;
  text: string;
  icon: any;
}

export const useRootRouter = (role: string = "admin") => {
  const routerList = [
    {
      roles: ["super-admin", "medical-admin"],
      path: "/",
      exact: true,
      page: SuperAdminDashboard,
      text: "Home",
      icon: HomeIcon,
    },
	{
      roles: ["department-admin", "unit-admin"],
      path: "/",
      exact: true,
      page: PersonnelManagement,
      text: "Home",
      icon: HomeIcon,
    },
    {
      roles: ["command-admin"],
      path: "/",
      exact: true,
      page: PersonnelManagement,
      text: "Home",
      icon: HomeIcon,
    },
    {
      roles: ["medical-admin"],
      path: "/check-in",
      exact: true,
      page: CheckIn,
      text: "Vaccinate",
      icon: GroupIcon,
    },
    {
      roles: ["super-admin"],
      path: "/user-management",
      exact: true,
      page: UserManagement,
      text: "User Management",
      icon: UserIcon,
    },
    {
      roles: ["super-admin"],
      path: PAGE_NAME.MEDICAL_CENTRE.URI,
      exact: true,
      page: SiteManagement,
      text: PAGE_NAME.MEDICAL_CENTRE.LABEL,
      icon: InstantSearching,
    },
    {
      roles: ["super-admin"],
      path: PAGE_NAME.DEPARTMENT.URI,
      exact: true,
      page: DepartmentManagement,
      text: PAGE_NAME.DEPARTMENT.LABEL,
      icon: GroupInsight,
    },
    {
      roles: ["super-admin"],
      path: PAGE_NAME.COMMAND.URI,
      exact: true,
      page: CommandManagement,
      text: PAGE_NAME.COMMAND.LABEL,
      icon: HealthCardIcon,
    },
    {
      roles: ["super-admin"],
      path: PAGE_NAME.UNIT_MANAGEMENT.URI,
      exact: true,
      page: UnitManagement,
      text: PAGE_NAME.UNIT_MANAGEMENT.LABEL,
      icon: RosterManagement,
    },
    {
      roles: [
        "super-admin",
        "medical-admin",
        "department-admin",
        "command-admin",
        "unit-admin",
      ],
      path: "/personal-registration",
      exact: true,
      page: PersonnelRegistration,
      text: "Personnel Registration",
      icon: HealthCardIcon,
    },
    {
      roles: [
        "super-admin",
        "schedule-admin",
        "department-admin",
        "command-admin",
        "medical-admin",
        "unit-admin",
      ],
      path: "/personnel-management",
      exact: true,
      page: PersonnelManagement,
      text: "Personnel Management",
      icon: GroupInsight,
    },
	{
      roles: ["super-admin", "medical-admin"],
      path: PAGE_NAME.VACCINE.URI,
      exact: true,
      page: VaccineManagement,
      text: PAGE_NAME.VACCINE.LABEL,
      icon: HealthCardIcon,
    },
    {
      roles: ["super-admin", "medical-admin"],
      path: "/time-slot",
      exact: true,
      page: TimeSlotManagement,
      text: "Time-Slot Management",
      icon: RosterManagement,
    },
	{
      roles: ["super-admin"],
      path: "/check-in",
      exact: true,
      page: CheckIn,
      text: "Vaccinate",
      icon: GroupIcon,
    },
    {
      roles: ["super-admin", "medical-admin", "department-admin","unit-admin","command-admin"],
      path: "*",
      exact: true,
      page: FourZeroFour,
      is404:true,
      text: "",
      icon: RosterManagement,
    },
  ];

  const rootRouterWithRole: RouterItem[] = routerList.filter((path) =>
    path.roles.includes(role)
  );

  return rootRouterWithRole;
};
