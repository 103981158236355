import React, { useEffect } from "react";
import { Modal, Form, Input, Select, Button } from "antd";
import { IMedicalCentre, IRole } from "./index";
const { Option } = Select;

interface Props {
  userGroup?: any;
  visible: boolean;
  onOk: (e: any) => void;
  onCancel: (e: any) => void;
  rolesList: IRole[];
  medicalCentreList: IMedicalCentre[];
}

function CreateUserAdminModal(props: Props) {
  const {
    visible,
    onOk: propsOnOk,
    onCancel: propsOnCancel,
    userGroup,
    rolesList,
    medicalCentreList,
  } = props;

  const [form] = Form.useForm();

  const formatRoleName = (name: string): string => {
    const words = name.split("-");

    return words
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
  };

  useEffect(() => {
    visible === false
      ? form.resetFields()
      : form.setFields([
          { name: "identity", value: userGroup?.identity },
          { name: "role_id", value: userGroup?.role_id },
          { name: "name", value: userGroup?.name },
          { name: "site_id", value: userGroup?.site_id },
        ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onFinish = (value: any) => {
    propsOnOk(value);
    form.resetFields();
  };

  const onCancel = () => {
    propsOnCancel(null);
    form.resetFields();
  };

  return (
    <Modal
      title={userGroup ? "Update User" : `Create User`}
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      destroyOnClose
      getContainer={false}
      footer={[
        <div style={{ textAlign: "center" }}>
          <Button key="back" className="btn-default" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              form.submit();
            }}
          >
            Submit
          </Button>
        </div>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="admin-user-group"
        onFinish={onFinish}
      >
        <Form.Item
          label="Email Address"
          name="identity"
          rules={[{ required: true, message: "Please enter email address" }]}
        >
          {/* @todo: verify user exists */}
          <Input type="text" disabled={userGroup?.identity} />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please enter name" }]}
        >
          <Input type="text"></Input>
        </Form.Item>
        <Form.Item
          label="Role"
          name="role_id"
          rules={[{ required: true, message: "Please select role" }]}
        >
          <Select placeholder="Please select role">
            {rolesList &&
              rolesList.map((role) => {
                if (role.role_name !== "super-admin")
                  return (
                    <Option value={role.role_id}>
                      {formatRoleName(role.role_name)}
                    </Option>
                  );
              })}
          </Select>
        </Form.Item>
        <Form.Item label="Medical Centre" name="site_id">
          <Select placeholder="Please select medical centre">
            {medicalCentreList &&
              [{ name: "All Medical Centre", id: null }].concat(medicalCentreList)
                ?.map((medicalCentre) => {
                  return (
                    <Option value={medicalCentre?.id}>
                      {formatRoleName(medicalCentre?.name)}
                    </Option>
                  );
                })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateUserAdminModal;
