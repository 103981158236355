import React from "react";
import { Row } from "antd";

// Component
import SingleRegistration from "../../../../components/Forms/Personnel/SingleRegistration"

function TabSingleRegistration() {
  return (
    <Row justify="center" align="middle" style={{padding: "20px"}}>
      <SingleRegistration />
    </Row>
  );
}

export default TabSingleRegistration;