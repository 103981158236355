import useSWR from "swr";
import AuthAPI from "../api/auth";

type AuthRole = {
  data: any;
  isLoading: boolean;
  isError: boolean;
  mutate: any;
}

function useAuthRole(accessToken: string): AuthRole {
  const { data, error, mutate } = useSWR(
    accessToken,
    (token) => AuthAPI.getRole(token),
    {revalidateOnFocus : false}
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

export default useAuthRole;
